import { View } from 'react-native'
import Markdown from 'react-native-markdown-renderer'

// APIs
import { contentApi } from '@/slices/contentsSlice'

// Themes
import theme from '@/config/themes'

const Crisisroutine = () => {
  const { isSuccess, data: contentData } = contentApi.endpoints.getCrisisroutine.useQuery(undefined, {
    skip: false,
    refetchOnMountOrArgChange: true,
  })

  return <View>{isSuccess && <Markdown style={theme._.MarkdownStyle}>{contentData}</Markdown>}</View>
}

export default Crisisroutine
