import axios from 'axios'

// Helpers
import isTokenExpired from '@/plugins/helpers/isTokenExpired'
import { xConsole } from '@/plugins/helpers/xConsole'

// Types
import type { IApiResponse } from '@/types'

/**
 * Gets currently logged in user.
 */
async function getCurrentUser(): Promise<IApiResponse> {
  try {
    if (!isTokenExpired()) {
      const res = await axios.get('/api/user/profile')
      return res
    } else {
      throw 'TOKEN_EXPIRED'
    }
  } catch (error: any) {
    xConsole().error(error, 'API: getCurrentUser')
    return error.response as IApiResponse
  }
}

export default getCurrentUser
