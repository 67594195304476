import { useRef, forwardRef, useImperativeHandle } from 'react'
import { Keyboard } from 'react-native'
import { useActionSheet } from '@expo/react-native-action-sheet'

// APIs
import { chatApi } from '@/api/chatApi'

// Components
import LeaveChannelOverlay from '@/components/channels/settings/LeaveChannelOverlay'

// Types
import { ILeaveChannelOverlayRef } from '@/types'
type ChannelSettingsOverlayProps = {}

const ChannelSettingsOverlay = forwardRef(({}: ChannelSettingsOverlayProps, ref) => {
  const { showActionSheetWithOptions } = useActionSheet()
  const [trigger] = chatApi.endpoints.getChannel.useLazyQuery()

  const leaveChannelOverlayRef = useRef<ILeaveChannelOverlayRef>(null)

  useImperativeHandle(ref, () => ({
    async setIsActive(isActive: boolean, id: string) {
      if (isActive && id) {
        Keyboard.dismiss()
        const { data } = await trigger(id, true)
        if (data) {
          showActionSheetWithOptions(
            {
              title: data.topic,
              options: ['Forlate samtalen', 'Avbryt'],
              cancelButtonIndex: 1,
              destructiveButtonIndex: 0,
            },
            (buttonIndex) => {
              if (buttonIndex === 0) {
                leaveChannelOverlayRef.current?.setIsActive(true, data)
              }
            }
          )
        } else {
          toast.show('Beklager, noe gikk galt.', {
            type: 'main',
            data: {
              title: 'Obs!',
              icon: 'error',
            },
          })
        }
      } else {
        toast.show('Beklager, noe gikk galt.', {
          type: 'main',
          data: {
            title: 'Obs!',
            icon: 'error',
          },
        })
      }
    },
  }))

  return (
    <>
      <LeaveChannelOverlay ref={leaveChannelOverlayRef} />
    </>
  )
})

export default ChannelSettingsOverlay
