import { useState, useEffect, useRef, useCallback } from 'react'
import { View, StyleSheet } from 'react-native'
import { Text, Button, Icon } from '@rneui/themed'
import axios from 'axios'
import { uniq } from 'lodash'
import theme from '@/config/themes'
import useExtendedState from '@/plugins/helpers/useExtendedState'

// Store
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/store/rootReducer'
import { fetchUser } from '@/slices/userSlice'
import { fetchInstitutions, fetchCounties } from '@/slices/institutionsSlice'

// Components
import ScreenSubpageLayout from '@/components/settings/ScreenSubpageLayout'
import InstitutionSelector from '@/components/institutions/selector'
import CountySelector from '@/components/institutions/countySelector'
// import SettingsSelectedInstitutions from '@/components/settings/SelectedInstitutions'

// Types
import { IInstitutionSelectorRef, ICountySelectorRef, IInstitution } from '@/types'

const SettingsInstitutionsScreen = () => {
  const dispatch = useDispatch()

  const [selectedCounty, setSelectedCounty, getSelectedCounty] = useExtendedState('')
  const [selectedInstitutions, setSelectedInstitutions, getSelectedInstitutions] = useExtendedState<Array<string>>([])

  const countySelectorRef = useRef<ICountySelectorRef>(null)
  const institutionSelectorRef = useRef<IInstitutionSelectorRef>(null)

  const { user } = useSelector((state: RootState) => state.user)
  const { institutions, counties } = useSelector((state: RootState) => state.institutions)
  if (!user) return <></>

  useEffect(() => {
    dispatch(fetchUser())
    dispatch(fetchInstitutions())
    dispatch(fetchCounties())
  }, [])

  const isAllowMultiple = useCallback(() => {
    return user.roles.includes('superadmin')
  }, [user])

  /* ======================================== County ======================================== */
  useEffect(() => {
    initSelectCounty()
  }, [user.countyId])

  const initSelectCounty = useCallback(() => {
    setSelectedCounty(user.countyId || '')
  }, [user])

  const getCurrentCountyNameById = useCallback(
    (id: string) => {
      let name = counties.find((v) => id === v.countyId)?.countyName || ''
      return name
    },
    [counties]
  )

  const onCountySelect = (active: boolean, id: string) => {
    setSelectedCounty(id)
  }

  const openCountySelector = () => {
    countySelectorRef.current?.setIsActive(true)
  }

  /* ======================================= Institutions ======================================= */
  useEffect(() => {
    initSelectInstitutions()
  }, [user.institutions])

  const initSelectInstitutions = useCallback(() => {
    let institutions = user.institutions.map((v) => v.institutionId)
    if (!isAllowMultiple() && institutions.length > 1) {
      institutions = []
    }
    setSelectedInstitutions(institutions)
  }, [user])

  const onInstitutionSelect = (active: boolean, id: string) => {
    if (!isAllowMultiple()) {
      setSelectedInstitutions([id])
    } else {
      if (active) {
        setSelectedInstitutions((prev) => uniq([...prev, id]))
      } else {
        setSelectedInstitutions((prev) => uniq([...prev].filter((v) => v !== id)))
      }
    }
  }

  const openInstitutionSelector = () => {
    institutionSelectorRef.current?.setIsActive(true)
  }

  /* ======================================== Submit ======================================== */
  const onPressEdit = useCallback(() => {
    // Reset
    initSelectCounty()
    initSelectInstitutions()
    // Open county selector
    openCountySelector()
  }, [user])

  const onCountySubmit = useCallback(async () => {
    // Auto select default institution id
    try {
      const autoSelectDefaultInstitutionId = counties.find((v) => selectedCounty === v.countyId)?.autoSelectDefaultInstitutionId
      if (autoSelectDefaultInstitutionId) {
        onInstitutionSelect(true, autoSelectDefaultInstitutionId)
        countySelectorRef.current?.setIsActive(false)
        await onSubmit()
        return false
      }
    } catch (error) {}

    // Default : Normal steps
    countySelectorRef.current?.setIsActive(false)
    openInstitutionSelector()
  }, [counties, selectedCounty])

  const onSubmit = useCallback(async () => {
    try {
      const selectedCounty = await getSelectedCounty()
      const selectedInstitutions = await getSelectedInstitutions()
      await axios.put(`/api/user/assigncounty?countyId=${selectedCounty}`)
      await axios.put('/api/user/assigninstitutions', selectedInstitutions)
      institutionSelectorRef.current?.setIsActive(false)
      toast.show('Lagret!')

      // Reload user data
      dispatch(fetchUser())
    } catch (error) {
      toast.show('Beklager, noe gikk galt.')
    }
  }, [])

  // Get institutions
  type ICurrentInstitutions = {
    type: string
    icon: { type: string; name: string; color: string; size: number }
    text: string
  }
  const getCurrentInstitutions = useCallback(() => {
    const items: ICurrentInstitutions[] = []
    try {
      // County
      items.push({
        type: 'county',
        icon: { type: 'material-icons', name: 'place', color: theme.colors.primary, size: 41 },
        text: institutions
          .filter((v) => v.isCounty && v.countyId === user.countyId)
          .map((v) => v.institutionName)
          .join(','),
      })

      // School
      items.push({
        type: 'school',
        icon: { type: 'material-community', name: 'notebook-multiple', color: theme.colors.primary, size: 41 },
        text: user.institutions.map((v) => v.institutionName).join(', '),
      })

      // National
      items.push({
        type: 'national',
        icon: { type: 'font-awesome-5', name: 'hand-holding-heart', color: theme.colors.primary, size: 41 },
        text: 'Psykttilgjengelig og andre nasjonale hjelpetjenester', // @HARDCODE
      })
    } catch (error) {}
    return items
  }, [institutions, user])

  return (
    <ScreenSubpageLayout title="Skoler og tjenester">
      <>
        <View style={{ marginTop: 30 }}>
          <Text>Her er tjenestene du kan benytte deg av.</Text>
        </View>
        {getCurrentInstitutions().map(
          (v) =>
            !!v.text.length && (
              <View style={styles.button} key={v.type}>
                <Icon type={v.icon.type} name={v.icon.name} color={v.icon.color} size={v.icon.size} style={styles.buttonIcon}></Icon>
                <Text style={styles.buttonText} numberOfLines={2}>
                  {v.text}
                </Text>
              </View>
            )
        )}

        <View style={{ marginVertical: 10 }}>
          <Button title={'ENDRE'} onPress={onPressEdit} />
        </View>

        <CountySelector ref={countySelectorRef} defaultSelected={selectedCounty} onSelect={onCountySelect} onSubmit={onCountySubmit} />
        <InstitutionSelector
          ref={institutionSelectorRef}
          filterCounty={selectedCounty}
          defaultSelected={selectedInstitutions}
          multiple={isAllowMultiple()}
          onSelect={onInstitutionSelect}
          onSubmit={onSubmit}
        />
      </>
    </ScreenSubpageLayout>
  )
}

export default SettingsInstitutionsScreen

const styles = StyleSheet.create({
  button: {
    marginVertical: 10,
    paddingHorizontal: 20,
    paddingVertical: 20,
    flexDirection: 'row',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    borderRadius: 8,
  },
  buttonText: {
    fontFamily: 'FSJoeyMedium',
    fontSize: 20,
    lineHeight: 22,
    color: '#414141',
    textAlign: 'left',
    flex: 1,
  },
  buttonIcon: {
    marginRight: 15,
  },
})
