import axios, { AxiosResponse } from 'axios'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Types
export type IGetChannelsReturn = Promise<AxiosResponse>

async function getChannels(endpoint = '/api/channel/list3'): IGetChannelsReturn {
  try {
    const res = await axios.get(endpoint)
    return res
  } catch (error: any) {
    xConsole().error(error as Error, 'getChannels')
    return error.response
  }
}

export default getChannels
