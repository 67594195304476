import { useCallback } from 'react'
import { StyleSheet, View, Dimensions, Pressable, ActivityIndicator, Platform } from 'react-native'
import { Text, Image } from '@rneui/themed'

// Navigation
import { useNavigation, NavigationProp } from '@react-navigation/native'

// Themes
import theme from '@/config/themes'

// Types
import type { RootNavigatorParamList } from '@/types'
import { Routes } from '@/config/routes'
type ItemContentProps = {
  data: any
}

const { width } = Dimensions.get('window')

const ItemContent = ({ data }: ItemContentProps) => {
  if (!data.id && !data.data) {
    return <></>
  }

  const navigation = useNavigation<NavigationProp<RootNavigatorParamList>>()

  const onPress = useCallback(() => {
    navigation.navigate(Routes.ContentPage, {
      id: data.id,
    })
  }, [data])

  let containerWidth = data.data.fullWidth ? width - 40 : 'auto'

  if (data.data.fullWidth && Platform.OS === 'web' && width > 750) {
    containerWidth = width - 40 - (width - 750)
  }

  return (
    <View style={[styles.mainContainer, { width: containerWidth as number }]}>
      <Pressable onPress={onPress}>
        {!!data.data.thumbnailImage && !!data.data.thumbnailImage.length && (
          <Image
            source={{ uri: data.data.thumbnailImage[0].url }}
            containerStyle={styles.image}
            PlaceholderContent={<ActivityIndicator />}
            placeholderStyle={styles.imagePlaceholder}
          />
        )}
        <View style={styles.contentContainer}>
          {!!data.data.title && <Text style={styles.title}>{data.data.title}</Text>}
          {!!data.data.excerpt && (
            <Text style={styles.description} numberOfLines={4} ellipsizeMode="tail">
              {data.data.excerpt}
            </Text>
          )}
        </View>
      </Pressable>
    </View>
  )
}

export default ItemContent

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: '#fff',
    justifyContent: 'flex-start',
    borderRadius: 8,
    overflow: 'hidden',
    minHeight: 100,
    ...Platform.select({
      web: {
        maxWidth: '200%',
      },
    }),
  },
  contentContainer: {
    padding: 20,
  },
  image: {
    flex: 1,
    width: '100%',
    aspectRatio: 16 / 6,
  },
  imagePlaceholder: {
    backgroundColor: 'transparent',
    marginTop: 20,
    flex: 1,
  },
  title: {
    fontFamily: 'FSJoeyMedium',
    fontSize: 18,
    marginBottom: 6,
  },
  description: {
    fontSize: 17,
  },
})
