import { memo, useMemo, useEffect } from 'react'
import { TouchableHighlight } from 'react-native'
import { ListItem, Icon, Badge } from '@rneui/themed'
import moment from 'moment'
import { debounce } from 'lodash'

// APIs
import { chatApi } from '@/api/chatApi'

// Components
import Avatar from '@/components/Avatar'

// Themes
import theme from '@/config/themes'

// Types
import type { IChannel } from '@/types'

type ListChannelItemProps = {
  item: IChannel
  onPress: any
  onLongPress: any
}

const formatReadableDate = (v?: string) => {
  if (!v) return ''
  // Format lastMessageReceivedOn to readable
  const nowMoment = moment()
  let format = 'HH:mm'
  const date = moment(v)
  if (nowMoment.format('YYYY') !== nowMoment.format('YYYY')) {
    // Show month name and year : (Diff year
    format = 'MMM YYYY'
  } else if (nowMoment.diff(date, 'hours') >= 24) {
    // Show month day and month : (Diff day)
    format = 'D MMM'
  }
  return date.format(format)
}

const useDebouncedEffect = (effect, delay, deps) => {
  const callback = debounce(() => effect(), delay)
  useEffect(() => {
    callback()
    return () => {
      callback.cancel()
    }
  }, deps)
}

const ChannelItem = ({ item, onPress, onLongPress }: ListChannelItemProps) => {
  const [trigger, result] = chatApi.endpoints.getChannel.useLazyQuery()

  // Trigger cache first
  useMemo(() => {
    trigger(item.id, true)
  }, [item.lastMessageReceivedOn, result?.data?.lastMessageReceivedOn, trigger])

  // Trigger changes with debounce
  useDebouncedEffect(
    () => {
      try {
        if (
          // lastMessageReceivedOn changed
          result?.data?.lastMessageReceivedOn !== undefined &&
          item.id === result?.data.id && // Same id (Flashlist memoize)
          item.lastMessageReceivedOn !== result?.data?.lastMessageReceivedOn
        ) {
          trigger(item.id)
        } else if (result?.data && result.status === 'fulfilled') {
          // Last triggered more than 5 minutes ago
          // @TODO: Keep checking every 5 mins
          const diff = moment().diff(moment(result.startedTimeStamp), 'minutes')
          if (diff >= 5) {
            trigger(item.id)
          }
        }
      } catch (error) {}
    },
    2000,
    [item.lastMessageReceivedOn, result?.data?.lastMessageReceivedOn, trigger]
  )

  return (
    <ListItem
      onPress={onPress}
      onLongPress={onLongPress}
      bottomDivider
      Component={TouchableHighlight}
      containerStyle={{ height: 73, backgroundColor: theme.colors.bodybg, paddingHorizontal: 20 }}
    >
      <Avatar
        avatarId={result?.data?.topicAvatarId}
        availability={result?.data?.availability}
        placeholderTitle={result?.data?.topic}
        isAnonymous={result?.data?.isAnonymous}
        key={result?.data?.id}
      />
      <ListItem.Content>
        <ListItem.Title>
          {result?.data?.topic}{' '}
          {result?.data?.isAnonymous && (
            <Icon name="user-secret" type="font-awesome-5" color={theme.colors.black} size={13} style={{ marginLeft: 3, marginTop: 4 }} />
          )}
        </ListItem.Title>
        <ListItem.Subtitle numberOfLines={1}>{result?.data?.lastMessage || ' '}</ListItem.Subtitle>
      </ListItem.Content>
      <ListItem.Content right style={{ alignItems: 'center', flex: 0, minWidth: 50 }}>
        <ListItem.Title style={{ fontSize: 11 }}>{formatReadableDate(result?.data?.lastMessageReceivedOn)}</ListItem.Title>
        {result?.data?.unread && <Badge containerStyle={{ marginTop: 5 }} badgeStyle={{ width: 14, height: 14, borderRadius: 20 }} />}
      </ListItem.Content>
    </ListItem>
  )
}

export default memo(ChannelItem)
