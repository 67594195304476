/*
  xConsole with Crashlytics and console as fallback for web
  Params: null
  Return: nothing
*/

import { Platform } from 'react-native'
import Constants from 'expo-constants'

const isSupported = () => {
  return !(Platform.OS === 'web' || Constants.appOwnership === 'expo' || Constants.sessionId === 'mock')
}

const xConsole = () => {
  const _isSupported = isSupported()

  return {
    log(s: string) {
      if (_isSupported) {
        // crashlytics().log(s)
      }
      console.log(s)
    },
    setUserId(id: string) {
      if (_isSupported) {
        // crashlytics().setUserId(id)
      }
    },
    error(error: Error, info?: string) {
      if (_isSupported) {
        // crashlytics().recordError(error)
        if (info) {
          // crashlytics().log(`${error && error.message ? error.message + ' :' : ''}${info}`)
        }
      }
      console.log(info)
      console.log(error)
    },
    crash() {
      if (_isSupported) {
        // crashlytics().crash()
      }
    },
  }
}

export { xConsole }
