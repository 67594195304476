import { useCallback, useState, useMemo, useRef, forwardRef, useImperativeHandle } from 'react'
import { Platform, StyleSheet, View, Keyboard } from 'react-native'
import { Text, Button } from '@rneui/themed'
import { BottomSheetModal, BottomSheetBackdrop } from '@gorhom/bottom-sheet'

// Store
import { useSelector } from 'react-redux'
import { RootState } from '@/store/rootReducer'

// APIs
import createMessage from '@/api/messages/createMessage'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Theme
import theme from '@/config/themes'

// Components
import ShareContactsList from '@/components/chat/actions/shareContacts/List'
import ShareContactsOverlay from '@/components/chat/actions/shareContacts/Overlay'

// Types
import type { IChannel, IInstitutionListItem, IShareContactsOverlayRef } from '@/types'
type ChatInputActionShareContactsProps = {
  channel: IChannel
  senderId: string
}

const ChatInputActionShareContacts = forwardRef(({ channel, senderId }: ChatInputActionShareContactsProps, ref) => {
  const { user, device } = useSelector((state: RootState) => state.user)
  const bottomSheetModalRef = useRef<BottomSheetModal>(null)
  const snapPoints = useMemo(() => ['50%', '75%'], [])
  const [item, setItem] = useState<IInstitutionListItem | null>(null)
  const ShareContactsOverlayRef = useRef<IShareContactsOverlayRef>(null)

  useImperativeHandle(ref, () => ({
    setIsActive(isActive: boolean) {
      if (isActive) {
        bottomSheetModalRef.current?.present()
        Keyboard.dismiss()
      }
    },
  }))

  const openContactOverlay = (item: IInstitutionListItem) => {
    setItem(item)
    bottomSheetModalRef.current?.dismiss()
    ShareContactsOverlayRef.current?.setIsActive(true)
  }

  const onShareContact = async (message: String) => {
    try {
      if (!item || item.type !== 'item' || !user) {
        return
      }
      Keyboard.dismiss()
      ShareContactsOverlayRef.current?.setIsLoading(true)
      const res = await createMessage({
        id: channel.id,
        content: `Visittkort av ${item.props.displayName}`,
        metaData: {
          /* Sender */
          // senderUserId: '', // (by backend)
          senderDisplayName: user.displayName,

          /* Target */
          targetUserId: item.props.userId,
          targetDisplayName: item.props.displayName,
          targetAvatarId: item.props.avatarId,
          targetTitle: item.props.title,

          /* Ref content / message*/
          refContent: message,
          customType: 'contactCard',
        },
        /*
        message: JSON.stringify({
          fromId: user?.id,
          targetId: item.id,
          fullName: item.fullName,
          title: item.title,
          photoURL: item.photoURL,
          organizationId: channel.reference.organizationId,
          message: message,
          // Support old structure
          displayName: item.fullName,
          refMessage: message,
          subtitle: item.title,
          uid: item.id,
        }),
        */
      })
      if (res.status !== 200) {
        toast.show('Beklager, noe gikk galt.')
      }
      ShareContactsOverlayRef.current?.setIsActive(false)
      ShareContactsOverlayRef.current?.setIsLoading(false)
    } catch (error) {
      xConsole().error(error as Error, 'chat/actions/shareContacts/index.tsx (onShareContact)')
    }
  }

  const handleDismissModalPress = useCallback(() => {
    bottomSheetModalRef.current?.dismiss()
  }, [])

  const renderBackdrop = useCallback((props) => <BottomSheetBackdrop {...props} disappearsOnIndex={-1} appearsOnIndex={0} />, [])

  return (
    <>
      <BottomSheetModal ref={bottomSheetModalRef} index={1} snapPoints={snapPoints} backdropComponent={renderBackdrop}>
        <View style={styles.bottomSheetHeader}>
          <Button
            title="LUKK"
            onPress={handleDismissModalPress}
            type="clear"
            buttonStyle={[theme._.ButtonStyle.Clear]}
            titleStyle={{ color: theme.colors.primary }}
          />
        </View>
        <Text h4 style={{ paddingHorizontal: 20 }}>
          Overfør kontakt
        </Text>
        <Text style={{ paddingHorizontal: 20, marginVertical: 5, fontSize: 18 }}>
          Er det andre som er bedre egnet til å svare på akkurat dette spørsmålet? Velg en av kollegaene dine og fortell gjerne kort hva henvendelsen
          dreier seg om.
        </Text>
        <View style={styles.listContainer}>
          <ShareContactsList trigger={openContactOverlay} participants={channel.participants} />
        </View>
      </BottomSheetModal>
      <ShareContactsOverlay ref={ShareContactsOverlayRef} item={item} trigger={onShareContact} />
    </>
  )
})

export default ChatInputActionShareContacts

const styles = StyleSheet.create({
  bottomSheetHeader: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  listContainer: {
    flex: 1,
    ...Platform.select({
      web: {
        maxHeight: '60vh', // @TODO: Web flatlist's height
      },
    }),
  },
})
