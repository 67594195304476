import { useRef, useMemo, forwardRef, useImperativeHandle } from 'react'
import { Dimensions, StyleSheet, View } from 'react-native'
import { Button } from '@rneui/themed'

import { BottomSheetModal } from '@gorhom/bottom-sheet'

// Theme
import theme from '@/config/themes'

// Components
import CountyListSelector from '@/components/institutions/countySelector/List'
type InstitutionSelectorProps = {
  bottomSheetTopInset?: number
  onSelect?: Function
  onSubmit?: Function
  defaultSelected?: string
}

// Variables
const { height } = Dimensions.get('window')

const CountySelector = forwardRef(
  ({ bottomSheetTopInset = 0, onSelect = () => {}, onSubmit = () => {}, defaultSelected }: InstitutionSelectorProps, ref) => {
    const bottomSheetModalRef = useRef<BottomSheetModal>(null)
    const snapPoints = useMemo(() => [height * 0.8], [])

    useImperativeHandle(ref, () => ({
      setIsActive,
    }))

    const setIsActive = (isActive: boolean) => {
      if (isActive) {
        bottomSheetModalRef.current?.present()
      } else {
        bottomSheetModalRef.current?.dismiss()
      }
    }

    return (
      <BottomSheetModal ref={bottomSheetModalRef} index={0} snapPoints={snapPoints} topInset={bottomSheetTopInset}>
        <View style={styles.bottomSheetHeader}>
          <Button
            title="LUKK"
            onPress={() => setIsActive(false)}
            type="clear"
            buttonStyle={[theme._.ButtonStyle.Clear, { width: 75, paddingRight: 0, justifyContent: 'flex-end' }]}
            titleStyle={{ color: theme.colors.primary }}
          />
        </View>
        <View style={{ flex: 1 }}>
          <CountyListSelector onSelect={onSelect} onSubmit={onSubmit} defaultSelected={defaultSelected} />
        </View>
      </BottomSheetModal>
    )
  }
)

export default CountySelector

const styles = StyleSheet.create({
  bottomSheetHeader: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
})
