import { Message, isSameUser, isSameDay } from 'react-native-gifted-chat'

// Types
import type { IMessage } from '@/types'
import type { MessageProps } from 'react-native-gifted-chat'
type ChatMessageProps = MessageProps<IMessage>

function ChatMessage(props: ChatMessageProps) {
  const { currentMessage, nextMessage } = props
  const isLastMessage = nextMessage && !nextMessage._id

  const isShowAvatar = !(currentMessage && isSameUser(currentMessage, nextMessage) && isSameDay(currentMessage, nextMessage))

  return (
    <Message
      {...props}
      containerStyle={{
        left: {
          marginBottom: !isLastMessage ? 0 : 20,
          marginLeft: isShowAvatar ? 20 : 12,
        },
        right: {
          marginBottom: !isLastMessage ? 0 : 20,
          marginRight: 20,
        },
      }}
    />
  )
}

export default ChatMessage
