import axios, { AxiosResponse } from 'axios'
import queryString from 'query-string'
import { koplingConfig } from '@/config'

// Helpers
import isTokenExpired from '@/plugins/helpers/isTokenExpired'
import { xConsole } from '@/plugins/helpers/xConsole'

// Types
export type IGetPagesReturn = Promise<AxiosResponse>
export type IGetPagesArgs = {
  targetGroups: Array<string>
  categories?: Array<string>
  ids?: Array<string>
  orderBy?: string
}

/**
 * getPages (Get Squidex contents)
 * @example
 * import getApiContents from '@/api/contents/getPages'
 * // Then use inside a function
 * const res = await getApiContents({ targetGroups: ['string'], categories: ['string'] })
 */

async function getPages(args: IGetPagesArgs): IGetPagesReturn {
  try {
    if (!isTokenExpired()) {
      // Filter (Target groups)
      const filterTargetGroups = args.targetGroups.length ? args.targetGroups.map((v) => `'${v}'`).join(',') : `'all'`
      const filters = [`data/targetGroups/iv in (${filterTargetGroups})`]

      // Filter (Categories)
      const filterCategories = args.categories?.length ? args.categories.map((v) => `'${v}'`).join(', ') : ''
      if (filterCategories) {
        filters.push(`data/categories/iv in (${filterCategories})`)
      }

      // Filter (Ids)
      const filterIds = args.ids?.length ? args.ids.map((v) => `'${v}'`).join(', ') : ''
      if (filterIds) {
        filters.push(`id in (${filterIds})`)
      }

      // Filters to string
      const filterString = filters.join(' and ')

      // OrderBy
      const orderByString = args.orderBy !== undefined ? args.orderBy : 'data/priority/iv desc,created desc'

      const res = await axios({
        url: `${koplingConfig.contentApiUrl}/api/content/app/graphql`,
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        data: queryString.stringify({
          query: `{queryPagesContents(
          filter: "${filterString}",
          orderby: "${orderByString}"
        ) {
          id,
          flatData {
            title,
            excerpt,
            thumbnailImage { url },
            featuredImage { url },
            fullWidth
          }
        }}`,
        }),
      })
      if (!res.data?.data?.queryPagesContents?.length) {
        throw new Error('NOT_FOUND')
      }

      res.data = res.data.data.queryPagesContents

      return res
    } else {
      throw 'TOKEN_EXPIRED'
    }
  } catch (error: any) {
    xConsole().error(error, 'getPages')
    if (error instanceof Error) {
      throw { response: { status: 400 } }
    }
    return error.response as AxiosResponse
  }
}

export default getPages
