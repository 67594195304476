import axios, { AxiosResponse } from 'axios'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

/**
 * Get all counties by Axios with token by default
 */
async function getCounties(): Promise<AxiosResponse> {
  try {
    const res = await axios.get('/api/county/list?per_page=1000&page=1&isActive=true')
    try {
      // @HARDCODE : Set Auto select default institution id for Troms og Finnmark (county)
      const index = res.data.findIndex((v: any) => v.countyId === '0970731e51074f4fbbf4d033d58c7a43')
      if (index !== -1) {
        res.data[index].autoSelectDefaultInstitutionId = 'c7c2c1a217e9428b84729d629910c30b' // Elevtjenesten Troms (institution)
      }
    } catch (error) {}

    return res
  } catch (error: any) {
    xConsole().error(error, 'getCounties')
    return error.response as AxiosResponse
  }
}

export default getCounties
