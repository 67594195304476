import { AnyAction } from 'redux'
import { combineReducers } from '@reduxjs/toolkit'

// Reducers
import userReducer from '../slices/userSlice'
import usersReducer from '../slices/usersSlice'
import channelsReducer from '../slices/channelsSlice'
import messagesReducer from '../slices/messagesSlice'
import institutionsReducer from '../slices/institutionsSlice'
import organizationsReducer from '../slices/organizationsSlice'
import faqsReducer from '../slices/faqsSlice'

// Redux Query
import { chatApi } from '@/api/chatApi'
import { contentApi } from '@/slices/contentsSlice'

const appReducer = combineReducers({
  user: userReducer,
  users: usersReducer,
  channels: channelsReducer,
  messages: messagesReducer,
  institutions: institutionsReducer,
  organizations: organizationsReducer,
  faqs: faqsReducer,
  [chatApi.reducerPath]: chatApi.reducer,
  [contentApi.reducerPath]: contentApi.reducer,
})

const rootReducer = (state: ReturnType<typeof appReducer>, action: AnyAction) => {
  if (action.type === 'CLEAR_ALL') {
    return appReducer(undefined, action) // Clear all data
  }
  return appReducer(state, action)
}

export type RootState = ReturnType<typeof rootReducer>
export default rootReducer
