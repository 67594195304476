import { Platform } from 'react-native'
import * as Updates from 'expo-updates'
import * as Sentry from '@sentry/react-native'

/**
 * Check the updates from Expo
 *
 * @example
 * import checkExpoUpdates from '@/plugins/helpers/checkExpoUpdates'
 * // Then use inside a function
 * checkExpoUpdates()
 */

function checkExpoUpdates() {
  const mainFunction = async () => {
    try {
      const { isAvailable } = await Updates.checkForUpdateAsync()
      if (isAvailable) {
        // Update
        const updateFetchPromise = Updates.fetchUpdateAsync()
        const timeoutInMillis = 10000 // 10 seconds
        const timeoutPromise = new Promise((_, reject) => setTimeout(() => reject('timedout'), timeoutInMillis))

        Promise.race([updateFetchPromise, timeoutPromise])
          .then(() => {
            toast.show('Vennligst vent, vi oppdaterer til den nyeste versjonen :)', {
              type: 'center',
              swipeEnabled: false,
              duration: 5000,
              data: {
                title: 'Ny oppdatering!',
                iconType: 'octicon',
                iconName: 'download',
                isAnimate: false,
              },
            })
            setTimeout(async () => {
              Updates.reloadAsync()
            }, 3000)
          })
          .catch((error) => {
            if (error === 'timedout') {
              console.log('Updates were not cancelled but reload is stopped.')
            } else {
              Sentry.captureException(error)
            }
          })
      }
    } catch (error: any) {}
  }

  // Runner
  setTimeout(() => {
    if (!__DEV__ && Platform.OS !== 'web') {
      mainFunction()
    }
  }, 3000)
}

export default checkExpoUpdates
