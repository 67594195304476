import { StyleSheet, View, Dimensions } from 'react-native'
import Loading from '@/components/Loading'
import { Button } from '@rneui/themed'

// Themes
import theme from '@/config/themes'

// Types
type ToastMainProps = {
  toast: any
}

const { width, height } = Dimensions.get('window')

const toastCenter = ({ toast: _toast }: ToastMainProps) => {
  const isDark = _toast.data?.isDark || false

  return (
    <View style={styles.outerContainer}>
      <View style={[styles.mainContainer, { backgroundColor: !isDark ? theme.colors.white : theme.colors.black }]}>
        <Loading
          text={_toast.message}
          title={_toast.data?.title}
          iconColor={!isDark ? theme.colors.black : theme.colors.white}
          textColor={!isDark ? '#666666' : theme.colors.white}
          titleColor={!isDark ? theme.colors.black : theme.colors.white}
          iconType={_toast.data?.iconType}
          iconName={_toast.data?.iconName}
          isAnimate={_toast.data?.isAnimate}
          containerStyle={{ flex: 0 }}
        />
        <View style={{ flex: 0, alignItems: 'flex-end', marginBottom: -20 }}>
          {_toast.data?.closeButton && (
            <Button
              title="LUKK"
              type="clear"
              titleStyle={{ color: theme.colors.primary, fontFamily: 'FSJoeyMedium' }}
              onPress={() => toast.hideAll()}
            />
          )}
        </View>
      </View>
    </View>
  )
}

export default toastCenter

const styles = StyleSheet.create({
  outerContainer: {
    width: 750,
    maxWidth: '100%',
    height: height,
    justifyContent: 'center',
    paddingHorizontal: '4%',
  },
  mainContainer: {
    flexDirection: 'column',
    marginTop: -40,
    padding: 30,
    borderRadius: 25,
    shadowColor: theme.colors.black,
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 5,
  },
  text: {
    textAlign: 'center',
    fontSize: 18,
    marginTop: 10,
  },
})
