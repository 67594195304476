// Navigation & drawer
import { createStackNavigator } from '@react-navigation/stack'
import { createNavigationContainerRef, NavigationContainerRefWithCurrent } from '@react-navigation/native'
import RootNavigator from '@/navigation/RootNavigator'

// Store
import { useSelector } from 'react-redux'
import { RootState } from '@/store/rootReducer'

// Screens
import IntroScreen from '@/screens/IntroScreen'
import LoginScreen from '@/screens/LoginScreen'
import ChatScreen from '@/screens/ChatScreen'
import FAQScreen from '@/screens/FAQScreen'
import BullyScreen from '@/screens/BullyScreen'
import BullyPageScreen from '@/screens/BullyPageScreen'
import ContactProfileScreen from '@/screens/ContactProfileScreen'
import OrganizationContactsPageScreen from '@/screens/contacts/OrganizationContactsPageScreen'

import SettingsProfileScreen from '@/screens/settings/SettingsProfileScreen'
import SettingsSecurityScreen from '@/screens/settings/SettingsSecurityScreen'
import SettingsInstitutionsScreen from '@/screens/settings/SettingsInstitutionsScreen'
import SettingsAvailabilityScreen from '@/screens/settings/SettingsAvailabilityScreen'
import SettingsDeleteAccountScreen from '@/screens/settings/SettingsDeleteAccountScreen'
import SettingsPrivacyScreen from '@/screens/settings/SettingsPrivacyScreen'
import SettingsCrisisroutineScreen from '@/screens/settings/SettingsCrisisroutineScreen'
import SettingsAdminScreen from '@/screens/settings/SettingsAdminScreen'

import ContentPageScreen from '@/screens/contents/ContentPageScreen'
import ContentCategoryScreen from '@/screens/contents/ContentCategoryScreen'

/*
const storage = Platform.select({
  native: () => require('@react-native-async-storage/async-storage').default,
  default: () => require('redux-persist/lib/storage').default,
})()
*/

// Themes
import theme from '@/config/themes'

// Types
import { Routes } from '@/config/routes'
import type { RootNavigatorParamList } from '@/types'

// Variables
const Stack = createStackNavigator()

const AppNavigator = () => {
  const { user } = useSelector((state: RootState) => state.user)

  return (
    <Stack.Navigator
      screenOptions={{
        headerTintColor: 'white',
        headerStyle: { backgroundColor: theme.colors.primary },
        headerBackTitleVisible: false,
      }}
    >
      {!!user ? (
        // Screens for logged in users
        <Stack.Group>
          <Stack.Screen name="Root" component={RootNavigator} options={{ headerShown: false }} />
          <Stack.Screen name={Routes.Chat} component={ChatScreen} options={{ headerShown: false }} />
          <Stack.Screen name={Routes.ContactProfile} component={ContactProfileScreen} options={{ headerShown: false }} />
          <Stack.Screen name={Routes.OrganizationContactsPage} component={OrganizationContactsPageScreen} options={{ headerShown: false }} />

          {/* ====== Setting pages ====== */}
          <Stack.Screen name={Routes.SettingsProfile} component={SettingsProfileScreen} options={{ headerShown: false }} />
          <Stack.Screen name={Routes.SettingsSecurity} component={SettingsSecurityScreen} options={{ headerShown: false }} />
          <Stack.Screen name={Routes.SettingsInstitutions} component={SettingsInstitutionsScreen} options={{ headerShown: false }} />
          <Stack.Screen name={Routes.SettingsAvailability} component={SettingsAvailabilityScreen} options={{ headerShown: false }} />
          <Stack.Screen name={Routes.SettingsDeleteAccount} component={SettingsDeleteAccountScreen} options={{ headerShown: false }} />
          <Stack.Screen name={Routes.SettingsPrivacy} component={SettingsPrivacyScreen} options={{ headerShown: false }} />
          <Stack.Screen name={Routes.SettingsCrisisroutine} component={SettingsCrisisroutineScreen} options={{ headerShown: false }} />
          <Stack.Screen name={Routes.SettingsAdmin} component={SettingsAdminScreen} options={{ headerShown: false }} />

          {/* ====== Content pages ====== */}
          <Stack.Screen name={Routes.ContentPage} component={ContentPageScreen} options={{ headerShown: false }} />
          <Stack.Screen name={Routes.ContentCategory} component={ContentCategoryScreen} options={{ headerShown: false }} />
          <Stack.Screen name={Routes.FAQ} component={FAQScreen} options={{ headerShown: false }} />
          <Stack.Screen name={Routes.Bully} component={BullyScreen} options={{ headerShown: false }} />
          <Stack.Screen name={Routes.BullyPage} component={BullyPageScreen} options={{ headerShown: false }} />
        </Stack.Group>
      ) : (
        // Auth screens
        <Stack.Group screenOptions={{ headerShown: false }}>
          <Stack.Screen name={Routes.Login} component={LoginScreen} />
          <Stack.Screen name={Routes.Intro} component={IntroScreen} options={{ animationEnabled: false }} />
        </Stack.Group>
      )}
    </Stack.Navigator>
  )
}

export default AppNavigator
export const navigationRef = createNavigationContainerRef() as NavigationContainerRefWithCurrent<RootNavigatorParamList>
