/*
  Get Hex color (theme) from availability name
  Params: IAvailabilityName (required)
  Return: string
*/

import theme from '@/config/themes'

import type { IAvailabilityName } from '@/types'

const getHexColorFromAvailabilityName = (availabilityName: IAvailabilityName | string) => {
  return (
    {
      online: '#10ce00',
      busy: theme.colors.error,
      away: 'orange',
      offline: 'transparent',
      invisible: 'transparent',
    }[availabilityName] || ''
  )
}

export default getHexColorFromAvailabilityName
