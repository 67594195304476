import axios, { AxiosResponse } from 'axios'
import queryString from 'query-string'

// Configs
import { koplingConfig } from '@/config'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Types
export type IGetTokenArgs = {
  phoneNumber: string
  confirmCode: string
  requestId: string
  password?: string
}
export type IGetTokenReturn = Promise<AxiosResponse>

/*
  Get token from the API, using axios with x-www-form-urlencoded and queryString
  Example usage: Auth / login after user confirmed OTP or password
*/
async function getToken(args: IGetTokenArgs): IGetTokenReturn {
  try {
    const res = await axios({
      method: 'POST',
      url: '/connect/token',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      data: queryString.stringify({
        grant_type: 'urn:ietf:params:oauth:grant-type:passwordless-otp',
        scope: 'offline_access mobile',
        client_id: koplingConfig.clientId,
        username: args.phoneNumber,
        otp: args.confirmCode,
        request_id: args.requestId,
        password: args.password || '',
      }),
    })
    return res
  } catch (error: any) {
    xConsole().error(error, 'getToken')
    return error.response as AxiosResponse
  }
}

export default getToken
