import React from 'react'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { NativeStackScreenProps } from '@react-navigation/native-stack'

// Components
import ScreenTitle from '@/components/ScreenTitle'
import ContactProfile from '@/components/contacts/institutions/Profile'
import TabMenus from '@/components/TabMenus'

// Theme
import theme from '@/config/themes'

// Types
import { RootNavigatorParamList } from '@/types'

type ContactProfileProps = NativeStackScreenProps<RootNavigatorParamList, 'ContactProfileScreen'>

const ContactProfileScreen = ({ route }: ContactProfileProps) => {
  const { item } = route.params
  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: theme.colors.white }}>
      <ScreenTitle goBack />
      {item && (
        <View style={{ flex: 1, paddingHorizontal: 30 }}>
          <ContactProfile item={item} />
        </View>
      )}
      <TabMenus />
    </SafeAreaView>
  )
}

export default ContactProfileScreen
