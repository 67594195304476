import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '@/store'

// APIs
import getApiOrganizations from '@/api/organizations/getOrganizations'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Types
import type { IOrganizationsState, IOrganization } from '@/types'
const initialState: IOrganizationsState = {
  organizations: [],
  isLoading: false,
  error: false,
}

const organizations = createSlice({
  name: 'organizations',
  initialState,
  reducers: {
    getOrganizations(state) {
      state.isLoading = true
      state.error = false
    },
    getOrganizationsSuccess(state, action: PayloadAction<{ organizations: Array<IOrganization> }>) {
      const payload = action.payload
      state.isLoading = false
      state.organizations = payload.organizations
    },
    getOrganizationsFailure(state) {
      state.isLoading = false
      state.error = true
    },
  },
})

export const { getOrganizations, getOrganizationsSuccess, getOrganizationsFailure } = organizations.actions

export default organizations.reducer

export const fetchOrganizations = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getOrganizations())
    const res = await getApiOrganizations()
    dispatch(getOrganizationsSuccess({ organizations: res.data }))
  } catch (error: any) {
    dispatch(getOrganizationsFailure())
    xConsole().error(error, 'fetchOrganizations')
  }
}
