import { View, StyleSheet } from 'react-native'
import { ListItem, Image } from '@rneui/themed'
import * as WebBrowser from 'expo-web-browser'

// Store
import theme from '@/config/themes'

function UngWidget() {
  const onPress = () => {
    WebBrowser.openBrowserAsync('https://bit.ly/3OO0okR', {
      presentationStyle: WebBrowser.WebBrowserPresentationStyle.PAGE_SHEET,
      enableDefaultShareMenuItem: false,
      controlsColor: theme.colors.primary,
    })
  }
  return (
    <View style={styles.mainContainer}>
      <ListItem onPress={onPress} containerStyle={styles.itemContainer}>
        <Image source={require('@/assets/images/ung_no.png')} style={styles.image} containerStyle={styles.imageContainer} />
        <ListItem.Content>
          <ListItem.Title numberOfLines={1} style={styles.headerText}>
            Ung.no
          </ListItem.Title>
          <ListItem.Subtitle numberOfLines={1} style={styles.contentText}>
            Mange gode spørsmål og svar for unge!
          </ListItem.Subtitle>
        </ListItem.Content>
      </ListItem>
    </View>
  )
}

export default UngWidget

const styles = StyleSheet.create({
  mainContainer: {
    paddingHorizontal: 20,
    marginTop: -20,
    marginBottom: 20,
  },
  itemContainer: {
    paddingHorizontal: 20,
    paddingVertical: 20,
    borderRadius: 8,
    backgroundColor: theme.colors.black,
  },
  imageContainer: {
    width: 50,
    aspectRatio: 1,
    borderRadius: 100,
  },
  image: {
    width: '100%',
    height: 'auto',
  },
  headerText: {
    fontFamily: 'FSJoeyMedium',
    fontSize: 20,
    color: theme.colors.white,
  },
  contentText: {
    fontFamily: 'FSJoey',
    fontSize: 16,
    color: theme.colors.white,
  },
})
