import React from 'react'
import { View, ScrollView } from 'react-native'
import { Text } from '@rneui/themed'

// Components
import ScreenTitle from '../../components/ScreenTitle'
import ListEmergencies from '../../components/contacts/emergency/List'

const EmergencyContactsScreen = () => {
  return (
    <View style={{ flex: 1 }}>
      <ScrollView>
        <ScreenTitle title="Døgnbemannede tjenster" />
        <Text style={{ marginHorizontal: 20, fontSize: 20 }}>
          Her finner du døgnbemannede tjenester. Ta kontakt for umiddelbar hjelp.
        </Text>
        <ListEmergencies />
      </ScrollView>
    </View>
  )
}

export default EmergencyContactsScreen
