import { useCallback, useEffect, useState } from 'react'
import { View, StyleSheet, Keyboard, TouchableWithoutFeedback, KeyboardAvoidingView, Platform } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import asyncStorage from '@react-native-async-storage/async-storage'
import axios from 'axios'

// Store
import { useDispatch } from 'react-redux'
import { setUserToken } from '@/slices/userSlice'

// Themes
import theme from '@/config/themes'

// Components
import Title from '@/components/login/Title'
import Description from '@/components/login/Description'
import StatusMessage from '@/components/login/StatusMessage'
import BackButton from '@/components/login/BackButton'
import PhoneForm from '@/components/login/PhoneForm'
import CodeForm from '@/components/login/CodeForm'
import PasswordForm from '@/components/login/PasswordForm'
import NameForm from '@/components/login/NameForm'
import NewPasswordForm from '@/components/login/NewPasswordForm'
import CountyForm from '@/components/login/CountyForm'
import InstitutionsForm from '@/components/login/InstitutionsForm'
import ReadyForm from '@/components/login/ReadyForm'
import ForgotPasswordButton from '@/components/login/ForgotPasswordButton'

// Types
import type { ILoginScreenAuth, RootNavigatorParamList } from '@/types'
import type { NativeStackScreenProps } from '@react-navigation/native-stack'
import { Routes } from '@/config/routes'

type LoginViewProps = NativeStackScreenProps<RootNavigatorParamList, Routes.Login>

const headerTexts = [
  {
    title: '',
    subtitle: '',
    description: '',
  },
  {
    title: 'Velkommen til Elevtjenesten!',
    subtitle: 'Logg inn eller lag en bruker',
    description: '',
  },
  {
    title: 'Bekreft kode',
    subtitle: '',
    description: '',
  },
  {
    title: 'Passord',
    subtitle: 'Fyll inn passordet ditt',
    description: 'Passordet må være minst 8 tegn, inneholde både store og små bokstaver, samt minst et tall.',
  },
  {
    title: 'Hva heter du?',
    subtitle: 'Fyll inn navnet ditt',
    description: '',
  },
  {
    title: 'Passord',
    subtitle: 'Lag deg et pasord',
    description: 'Passordet må være minst 8 tegn, inneholde både store og små bokstaver, samt minst et tall.',
  },
  {
    title: 'Ditt fylke',
    subtitle: 'Velg hvilket fylke du tilhører.',
    description: '',
  },
  {
    title: 'Din skole',
    subtitle: 'Velg hvilken skole du går på. Hvis du ikke finner skolen din kan du fortsette uten.',
    description: '',
  },
  {
    title: 'Du er klar!',
    subtitle: 'Elevtjenesten er den korteste veien fra spørsmål til svar, du kan spørre om alt og innboksen har ikke kontortid.',
    description: '',
  },
]

const LoginScreen = (props: LoginViewProps) => {
  const dispatch = useDispatch()
  const [step, setStep] = useState(1)
  const [auth, setAuth] = useState<ILoginScreenAuth>(null)
  const [title, setTitle] = useState(headerTexts[1].title)
  const [description, setDescription] = useState('')
  const [subtitle, setSubtitle] = useState(headerTexts[1].subtitle)

  const goBack = useCallback(() => {
    let prevStep = step - 1
    if (prevStep === 2 || step === 4) {
      /*
       prevStep === 2 // Not allow to send confirmation code
       step === 4 // When "new user" on "NameForm" going back go to "PhoneForm"
      */
      prevStep = 1
    }
    if (prevStep === 7 && auth?.reference === 'no-institution-in-county') {
      // Skip to county selector if no institution in that county
      prevStep = 6
    }
    if (prevStep === 7 && auth?.reference === 'already-selected-institution') {
      // Skip to county selector if already selected a institution
      prevStep = 6
    }
    setStep(prevStep)
    setTitle(headerTexts[prevStep].title)
    setSubtitle(headerTexts[prevStep].subtitle)
    setDescription(headerTexts[prevStep].description)

    // Clear: reference
    if (auth?.reference) {
      auth.reference = null
      setAuth(auth)
    }
  }, [step, auth])

  useEffect(() => {
    // asyncStorage.removeItem('alreadyShownIntro')
    initIsShowIntro()
  }, [])

  const initIsShowIntro = async () => {
    if (!(await asyncStorage.getItem('alreadyShownIntro'))) {
      props.navigation.replace(Routes.Intro, {})
    }
  }

  return (
    <KeyboardAvoidingView behavior={Platform.OS === 'ios' ? 'padding' : 'height'} style={{ flex: 1 }}>
      <TouchableWithoutFeedback
        onPress={() => {
          Platform.OS !== 'web' ? Keyboard.dismiss : null
        }}
      >
        <View style={styles.mainContainer}>
          <View style={styles.contentContainer}>
            <SafeAreaView style={{ flex: 0.4, paddingHorizontal: 20 }}>
              <View style={{ flex: 0.3 }}>{step > 1 && <BackButton onGoBack={goBack} />}</View>
              <View style={{ flex: 0.7, justifyContent: 'flex-end' }}>
                <Title>{title}</Title>
                <View style={{ minHeight: 80 }}>
                  <StatusMessage message={subtitle} />
                  <Description>{description}</Description>
                  {step === 3 && auth && auth.phoneNumber && (
                    <ForgotPasswordButton
                      phoneNumber={auth.phoneNumber}
                      buttonProps={{
                        type: 'clear',
                        containerStyle: { width: 200 },
                        buttonStyle: { paddingLeft: 0, justifyContent: 'flex-start' },
                        titleStyle: { color: theme.colors.black, fontSize: 20 },
                        disabledTitleStyle: { color: theme.colors.teal, opacity: 0.25 },
                      }}
                    />
                  )}
                </View>
              </View>
            </SafeAreaView>
            <View style={{ flex: 0.6, paddingHorizontal: 20 }}>
              {step === 1 && (
                <PhoneForm
                  onSuccess={(auth) => {
                    setStep(2)
                    setTitle(headerTexts[2].title)
                    setSubtitle(`Oppgi koden vi sendte til ${auth?.phoneNumber}`)
                    setDescription(headerTexts[2].description)
                    setAuth(auth)
                  }}
                />
              )}
              {step === 2 && (
                <CodeForm
                  onSuccess={(auth) => {
                    let nextStep = !auth?.isNewUser ? 3 : 4
                    if (!auth?.hasPassword && !auth?.isNewUser) {
                      nextStep = 5 // Go to set new password
                    }
                    setStep(nextStep)
                    setTitle(headerTexts[nextStep].title)
                    if (!auth?.hasPassword && !auth?.isNewUser) {
                      setTitle('Passord')
                      toast.show(`Vi har oppdatert plattformen og må derfor be deg om å lage et nytt passord.`, {
                        type: 'center',
                        duration: 10000,
                        swipeEnabled: false,
                        data: { iconType: 'antdesign', iconName: 'exclamationcircle', isAnimate: false, closeButton: true },
                      })
                    }
                    setSubtitle(headerTexts[nextStep].subtitle)
                    setDescription(headerTexts[nextStep].description)
                    setAuth(auth)
                  }}
                  auth={auth}
                />
              )}
              {step === 3 && <PasswordForm auth={auth} />}
              {step === 4 && (
                <NameForm
                  onSuccess={async (auth) => {
                    if (auth && !auth.hasPassword && !auth.isNewUser && auth.token) {
                      dispatch(setUserToken(auth.token))
                      axios.defaults.headers.common['Authorization'] = auth?.token?.access_token ? `Bearer ${auth.token.access_token}` : ''
                      await axios.put('/api/user/details', { firstName: auth.firstName, lastName: auth.lastName })
                      return
                    }
                    setStep(5)
                    setTitle(headerTexts[5].title)
                    setSubtitle(headerTexts[5].subtitle)
                    setDescription(headerTexts[5].description)
                    setAuth(auth)
                  }}
                  auth={auth}
                />
              )}
              {step === 5 && (
                <NewPasswordForm
                  onSuccess={(auth) => {
                    if (auth && !auth.hasPassword && !auth.isNewUser && auth.token) {
                      setStep(4)
                      setTitle('Legg inn navnet ditt')
                      setSubtitle(
                        'Husk at det er lettere å hjelpe deg når man vet hvem du er. Du kan likevel velge å være anonym i chatten senere 😊'
                      )
                      setDescription('')
                      return
                    }
                    setStep(6)
                    setTitle(headerTexts[6].title)
                    setSubtitle(headerTexts[6].subtitle)
                    setDescription(headerTexts[6].description)
                    setAuth(auth)
                  }}
                  auth={auth}
                />
              )}
              {step === 6 && (
                <CountyForm
                  onSuccess={(auth) => {
                    if (auth && auth.reference === 'no-institution-in-county') {
                      setStep(8)
                      setTitle(headerTexts[8].title)
                      setSubtitle(
                        'Ditt fylke har ingen egen tjeneste, men du kan bruke Psykttilgjengelig og alle de andre nasjonale hjelpetjenestene!'
                      )
                      setDescription(headerTexts[8].description)
                      return
                    }
                    if (auth && auth.reference === 'already-selected-institution') {
                      setStep(8)
                      setTitle(headerTexts[8].title)
                      setSubtitle(headerTexts[8].subtitle)
                      setDescription(headerTexts[8].description)
                      return
                    }
                    setStep(7)
                    setTitle(headerTexts[7].title)
                    setSubtitle(headerTexts[7].subtitle)
                    setDescription(headerTexts[7].description)
                    setAuth(auth)
                  }}
                  auth={auth}
                  setSubtitle={setSubtitle}
                  resetSubtitle={() => {
                    setSubtitle(headerTexts[6].subtitle)
                  }}
                />
              )}
              {step === 7 && (
                <InstitutionsForm
                  onSuccess={(auth) => {
                    setStep(8)
                    setTitle(headerTexts[8].title)
                    setSubtitle(headerTexts[8].subtitle)
                    setDescription(headerTexts[8].description)
                    setAuth(auth)
                  }}
                  auth={auth}
                  setSubtitle={setSubtitle}
                  resetSubtitle={() => {
                    setSubtitle(headerTexts[7].subtitle)
                  }}
                />
              )}
              {step === 8 && <ReadyForm auth={auth} />}
            </View>
          </View>
        </View>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
  )
}

export default LoginScreen

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: theme.colors.bluelight,
    justifyContent: 'center',
  },
  contentContainer: {
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    maxWidth: 500,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
})
