import { useState, useCallback } from 'react'
import * as Updates from 'expo-updates'
import { Button } from '@rneui/themed'
import * as Application from 'expo-application'

// APIs
import refreshToken from '@/api/auth/refreshToken'

// Themes
import theme from '@/config/themes'

// Store
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/store/rootReducer'
import { toggleDevMode, fetchUser, setUserToken } from '@/slices/userSlice'

const SettingsDevmode = () => {
  const dispatch = useDispatch()
  const { isDevMode, token } = useSelector((state: RootState) => state.user)
  const version = Application && Application.nativeApplicationVersion ? `v${Application.nativeApplicationVersion}` : 'Kopling'

  const [lastEnableDevmodePressed, setLastEnableDevmodePressed] = useState(0)
  const [enableDevmodePressedTotal, setEnableDevmodePressedTotal] = useState(0)
  const onPressVersion = useCallback(() => {
    const time = new Date().getTime()
    if (time - lastEnableDevmodePressed > 1000) {
      setEnableDevmodePressedTotal(0)
    } else {
      setEnableDevmodePressedTotal(enableDevmodePressedTotal + 1)
    }
    if (enableDevmodePressedTotal >= 10) {
      dispatch(toggleDevMode())
      setEnableDevmodePressedTotal(0)
    }
    setLastEnableDevmodePressed(time)
  }, [lastEnableDevmodePressed])

  const clearData = async () => {
    dispatch({ type: 'CLEAR_ALL' })
    if (token) {
      const res = await refreshToken(token.refresh_token)
      dispatch(setUserToken(res.data))
      dispatch(fetchUser())
      setTimeout(() => {
        Updates.reloadAsync()
      }, 3000)
    }
  }

  return (
    <>
      {isDevMode && (
        <Button
          containerStyle={{
            width: 110,
          }}
          titleStyle={{ color: theme.colors.primary }}
          title={'Tøm bufferen'}
          type="clear"
          onPress={clearData}
        />
      )}
      <Button
        containerStyle={{
          width: 150,
          marginLeft: 'auto',
          alignItems: 'flex-end',
        }}
        titleStyle={{ color: theme.colors.grey1 }}
        title={version}
        type="clear"
        onPress={onPressVersion}
        activeOpacity={0.8}
      />
    </>
  )
}

export default SettingsDevmode
