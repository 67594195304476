import axios, { AxiosResponse } from 'axios'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

/**
 * Get all FAQs (assigned institutions) by Axios with token by default
 */
async function getFAQs(): Promise<AxiosResponse> {
  try {
    const res = await axios.get('/api/faq/all')
    try {
      if (Array.isArray(res.data)) {
        res.data.forEach((a) => {
          a.faqs.forEach((b) => {
            if (b?.content?.content) {
              b.content.content = b.content.content.replace(/\(www./g, '(https://')
            }
            b.subFaqs.forEach((c) => {
              if (c?.subContent?.content) {
                c.subContent.content = c.subContent.content.replace(/\(www./g, '(https://')
              }
            })
          })
        })
      }
    } catch (e) {
      console.log(e)
    }
    return res
  } catch (error: any) {
    xConsole().error(error, 'getFAQs')
    return error.response as AxiosResponse
  }
}

export default getFAQs
