import { Platform } from 'react-native'
import Constants, { ExecutionEnvironment } from 'expo-constants'

let isExpoGo = Constants.executionEnvironment === ExecutionEnvironment.StoreClient
// isExpoGo = true

let apiUrl = __DEV__ && Platform.OS === 'web' ? '/koplingapi' : 'https://api.platform.kopling.no'
if (isExpoGo || (Platform.OS === 'web' && window.location.hostname === 'web-staging.kopling.no')) {
  apiUrl = 'https://test-api.platform.kopling.no'
}

let ACSApiUrl = __DEV__ && Platform.OS === 'web' ? '/koplingapi' : 'https://cs-kopling-comm-prod.communication.azure.com'
if (isExpoGo || (Platform.OS === 'web' && window.location.hostname === 'web-staging.kopling.no')) {
  ACSApiUrl = 'https://cs-kopling-comm-stag.communication.azure.com'
}

export const koplingConfig = {
  clientId: 'kopling_app',
  apiUrl: apiUrl,
  ACSApiUrl: ACSApiUrl,
  contentApiUrl: 'https://kopling-squidex-production.azurewebsites.net',
  pejApiUrl: 'https://api.pej.se/v1.1',
  pejUrl: 'https://pej.se',
}

export const reCaptchaApiKey = '6LenNCEkAAAAAPYk_zmBnojmf9l-vSJMoSsJAXeT'

// Inject store
let store
export const injectStore = (_store) => {
  store = _store
}
export const getStore = () => {
  return store
}
