import { useCallback } from 'react'
import { View, Linking, StyleSheet, StyleProp, ViewStyle } from 'react-native'
import { Icon, Button } from '@rneui/themed'
import axios from 'axios'
import * as WebBrowser from 'expo-web-browser'

import theme from '@/config/themes'

// Types
type ContactIconsArgs = {
  chatUrl?: string
  phoneNumber?: string
  organizationId: any
  containerStyle?: StyleProp<ViewStyle>
  type?: 'large' | 'default'
}
const ContactIcons = ({ chatUrl, phoneNumber, organizationId, containerStyle, type }: ContactIconsArgs) => {
  if (!chatUrl && !phoneNumber) return <></>

  const onPressChatUrl = useCallback(async () => {
    if (!chatUrl) return
    WebBrowser.openBrowserAsync(chatUrl, {
      presentationStyle: WebBrowser.WebBrowserPresentationStyle.PAGE_SHEET,
      enableDefaultShareMenuItem: false,
      controlsColor: theme.colors.primary,
    })
    if (organizationId) {
      axios.put(`/api/organization/${organizationId}/link?url=${chatUrl}`)
    }
  }, [chatUrl, organizationId])
  const onPressPhoneNumber = useCallback(() => {
    if (!phoneNumber) return
    Linking.openURL(`tel:${phoneNumber}`)
    if (organizationId) {
      axios.put(`/api/organization/${organizationId}/link?url=tel:${phoneNumber}`)
    }
  }, [phoneNumber, organizationId])

  /* ======================== Mini ======================== */
  if (type === 'large') {
    return (
      <View style={[styles.typeLargeMainContainer, containerStyle]}>
        {chatUrl && (
          <Button icon={{ type: 'entypo', name: 'chat', size: 29, color: '#fff' }} buttonStyle={styles.typeLargeButton} onPress={onPressChatUrl} />
        )}
        {phoneNumber && (
          <Button
            icon={{ type: 'fontisto', name: 'phone', size: 31, color: '#fff' }}
            buttonStyle={styles.typeLargeButton}
            radius={100}
            onPress={onPressPhoneNumber}
          />
        )}
      </View>
    )
  }

  /* ======================== Mini ======================== */
  return (
    <View style={[styles.typeDefaultMainContainer, containerStyle]}>
      {chatUrl && <Icon type="entypo" name="chat" size={17} color={'#959595'} />}
      {phoneNumber && <Icon type="fontisto" name="phone" size={16} color={'#959595'} />}
    </View>
  )
}

export default ContactIcons

const styles = StyleSheet.create({
  // Type: Default
  typeDefaultMainContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    gap: 10,
  },

  // Type: Large
  typeLargeMainContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    gap: 20,
  },
  typeLargeButton: {
    width: 60,
    height: 60,
    borderRadius: 60,
    backgroundColor: theme.colors.primary,
  },
})
