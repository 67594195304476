import { useState, forwardRef, useImperativeHandle } from 'react'
import { StyleSheet, View, TextInput, TouchableWithoutFeedback, Keyboard, Platform } from 'react-native'
import { Text, Button, Overlay } from '@rneui/themed'

// Components
import Avatar from '@/components/Avatar'

// Theme
import theme from '@/config/themes'

//Types
import type { IInstitutionListItem } from '@/types'
type ShareContactsOverlayProps = {
  item: IInstitutionListItem | null
  trigger: Function
}

const ShareContactsOverlay = forwardRef(({ item, trigger }: ShareContactsOverlayProps, ref) => {
  const [isActive, setIsActive] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [text, setText] = useState('')

  useImperativeHandle(ref, () => ({
    setIsActive,
    setIsLoading,
  }))

  const onChangeText = (text: string) => {
    setText(text)
  }

  return (
    <Overlay
      isVisible={isActive}
      onBackdropPress={() => {
        if (!isLoading) {
          if (!text) {
            setIsActive(false)
          } else {
            Keyboard.dismiss()
          }
        }
      }}
      overlayStyle={styles.overlay}
    >
      {item && item.type === 'item' && (
        <TouchableWithoutFeedback
          onPress={() => {
            if (Platform.OS !== 'web') Keyboard.dismiss()
          }}
        >
          <View style={styles.mainContainer}>
            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
              <Avatar
                avatarId={item.props.avatarId}
                placeholderTitle={item.props.displayName}
                availability={item.props.availability}
                props={{
                  size: 45,
                  containerStyle: { marginRight: 15 },
                }}
              />
              <View>
                <Text h4 h4Style={{ fontSize: 18, lineHeight: 20 }}>
                  {item.props.displayName}
                </Text>
                <Text>{item.props.title}</Text>
              </View>
            </View>
            <TextInput
              placeholder={`Fortell gjerne kort hva hen trenger hjelp til eller lurer på. Dette vil kun vises til ${item.props.displayName}.`}
              multiline={true}
              onChangeText={onChangeText}
              style={styles.textInput}
              placeholderTextColor={'#666666'}
              editable={!isLoading}
            />
            <Button
              title="DEL KONTAKT"
              buttonStyle={styles.button}
              titleStyle={styles.buttonTitle}
              loading={isLoading}
              onPress={() => trigger(text)}
            />
            <Button
              title="AVBRYT"
              buttonStyle={styles.button}
              titleStyle={[styles.buttonTitle, { color: theme.colors.black }]}
              type="clear"
              onPress={() => setIsActive(false)}
              disabledStyle={{ opacity: 0.25 }}
              disabled={isLoading}
            />
          </View>
        </TouchableWithoutFeedback>
      )}
    </Overlay>
  )
})

export default ShareContactsOverlay

const styles = StyleSheet.create({
  overlay: {
    backgroundColor: '#f7f7f7',
  },
  mainContainer: {
    marginTop: 20,
    alignItems: 'center',
  },
  textInput: {
    height: 150,
    width: 300,
    marginVertical: 10,
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 10,
    paddingRight: 10,
    textAlignVertical: 'top',
    backgroundColor: '#ffffff',
  },
  button: {
    width: 300,
    paddingVertical: 10,
  },
  buttonTitle: {
    fontFamily: 'FSJoey',
    color: theme.colors.white,
  },
})
