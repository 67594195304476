import { useState } from 'react'
import { StyleSheet } from 'react-native'
import { SearchBar } from '@rneui/themed'

// Types
type SearchCountiesProps = {
  onSearch: (s: string) => void
}

const SearchCounties = ({ onSearch }: SearchCountiesProps) => {
  const [search, setSearch] = useState('')

  const updateSearch = (text: string): void => {
    setSearch(text)
    onSearch(text)
  }

  return (
    <SearchBar
      placeholder="Søk etter fylke"
      placeholderTextColor="#666666"
      onChangeText={updateSearch}
      value={search}
      containerStyle={styles.container}
      inputContainerStyle={styles.inputContainer}
      searchIcon={{ type: 'antdesign', name: 'search1' }}
      testID="SearchCounties"
    />
  )
}

export default SearchCounties

const styles = StyleSheet.create({
  container: {
    marginTop: 10,
    paddingHorizontal: 15,
  },
  inputContainer: {
    backgroundColor: '#f7f7f7',
  },
})
