import React, { useState, useEffect } from 'react'
import { StyleSheet, View, StyleProp, TextStyle, ViewStyle, Animated, Easing } from 'react-native'
import { Icon, Text } from '@rneui/themed'

// Types
type LoadingProps = {
  text?: string
  textColor?: string
  textStyle?: StyleProp<TextStyle>
  title?: string
  titleColor?: string
  titleStyle?: StyleProp<TextStyle>
  iconType?: string
  iconName?: string
  iconSize?: number
  iconColor?: string
  isAnimate?: boolean
  containerStyle?: StyleProp<ViewStyle>
}
const Loading = ({
  text = '',
  textColor = '#666666',
  textStyle = null,
  title = '',
  titleColor = '#666666',
  titleStyle = null,
  iconType = 'feather',
  iconName = 'loader',
  iconSize = 70,
  iconColor = '#2b2c2b',
  isAnimate = true,
  containerStyle = null,
}: LoadingProps) => {
  const [rotateAnimation, setRotateAnimation] = useState(new Animated.Value(0))

  const startAnimation = () => {
    rotateAnimation.setValue(0)
    Animated.timing(rotateAnimation, {
      toValue: 1,
      duration: 3000,
      easing: Easing.linear,
      useNativeDriver: true,
    }).start(() => {
      startAnimation()
    })
  }

  useEffect(() => {
    if (isAnimate) startAnimation()
  }, [])

  const interpolateRotating = rotateAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: ['0deg', '360deg'],
  })

  return (
    <View style={[styles.mainContainer, containerStyle]}>
      {iconType && iconName && (
        <Animated.View
          style={{
            transform: [
              {
                rotate: interpolateRotating,
              },
            ],
          }}
        >
          <Icon type={iconType} name={iconName} size={iconSize} color={iconColor} />
        </Animated.View>
      )}
      {!!title && <Text style={[styles.title, titleStyle, { color: titleColor }]}>{title}</Text>}
      <Text style={[styles.text, textStyle, { color: textColor }]}>{text}</Text>
    </View>
  )
}

export default Loading

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    textAlign: 'center',
    fontSize: 18,
    marginTop: 10,
    marginBottom: 10,
  },
  title: {
    fontFamily: 'FSJoeyMedium',
    textAlign: 'center',
    fontSize: 20,
    marginBottom: -5,
  },
})
