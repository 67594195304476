import axios, { AxiosResponse } from 'axios'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

const PSYKTTILGJENGELIG_ID = '59f83544c7504627a437ca2cb4f91a4c'

/**
 * Gets currently logged in user's institution helpers (selected institutions)
 */
async function getInstitutionHelpers(): Promise<AxiosResponse> {
  try {
    const res = await axios.get('/api/user/helpers/all')
    try {
      /*
        @HARDCODE: Remove "Psykttilgjengelig" from "Elevtjenesten Troms" (c7c2c1a217e9428b84729d629910c30b) users
      */
      if (
        res.data.filter((v: any) => v.institutionId === 'c7c2c1a217e9428b84729d629910c30b' || v.institutionId === PSYKTTILGJENGELIG_ID).length === 2
      ) {
        const index = res.data.map((v: any) => v.institutionId).indexOf(PSYKTTILGJENGELIG_ID)
        res.data.splice(index, 1)
      }
      /*
        @HARDCODE: Remove "Psykttilgjengelig" from "Elev- og helsetjenesten Viken" (dc0d3a90d02f47d783b1ba006e914c58) users
      */
      if (
        res.data.filter((v: any) => v.institutionId === 'dc0d3a90d02f47d783b1ba006e914c58' || v.institutionId === PSYKTTILGJENGELIG_ID).length === 2
      ) {
        const index = res.data.map((v: any) => v.institutionId).indexOf(PSYKTTILGJENGELIG_ID)
        res.data.splice(index, 1)
      }
    } catch (error) {}
    return res
  } catch (error: any) {
    xConsole().error(error, 'API: getInstitutionHelpers')
    return error.response
  }
}

export default getInstitutionHelpers
