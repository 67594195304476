import { useState, useEffect, useRef, useCallback } from 'react'
import { StyleSheet, View, Pressable } from 'react-native'
import { Text, Icon } from '@rneui/themed'
import * as WebBrowser from 'expo-web-browser'
import { koplingConfig } from '@/config'

// Components
import CafeteriaRegister, { ICafeteriaRegisterRef } from '@/components/cafeterias/Register'
import CafeteriaWebview, { ICafeteriaWebviewRef } from '@/components/cafeterias/Webview'
import OrderButton from '@/components/cafeterias/OrderButton'
import BalanceButton from '@/components/cafeterias/BalanceButton'

// APIs
import { contentApi } from '@/slices/contentsSlice'

// Themes
import theme from '@/config/themes'

// Store
import { useSelector } from 'react-redux'
import { RootState } from '@/store/rootReducer'

// Types
type CafeteriaWidgetsProps = {
  metaData?: { [key: string]: any }
}

const CafeteriaWidgets = ({ metaData }: CafeteriaWidgetsProps) => {
  const { user } = useSelector((state: RootState) => state.user)
  const cafeteriaRegisterRef = useRef<ICafeteriaRegisterRef>(null)
  const cafeteriaWebviewRef = useRef<ICafeteriaWebviewRef>(null)
  const [currentStatus, setCurrentStatus] = useState('not-registered')

  const { data: userCafeteriasData } = contentApi.endpoints.userCafeterias.useQuery(undefined, {
    skip: false,
    refetchOnMountOrArgChange: true,
  })

  const { data: userMetaData } = contentApi.endpoints.userMetaData.useQuery(undefined, {
    skip: false,
    refetchOnMountOrArgChange: true,
  })

  // const [triggerUserMetaData] = contentApi.endpoints.editUserMetaData.useMutation()
  // const [triggerResetUserMetaData] = contentApi.endpoints.resetUserMetaData.useMutation()

  useEffect(() => {
    initCurrentCafeteriaCardStatus()
  }, [userCafeteriasData, userMetaData, user?.institutions])

  const initCurrentCafeteriaCardStatus = useCallback(async () => {
    setCurrentStatus('not-registered')
    try {
      const currentInstitution = user?.institutions[0]
      const shopId = userCafeteriasData.find((x) => x.institutionId === currentInstitution?.institutionId)?.pejShopId ?? null
      const metaDataCafeteriaCard = (userMetaData?.cafeteriaCards ?? {})[shopId ?? null]

      if (metaDataCafeteriaCard) {
        setCurrentStatus('completed')
        return
      }
    } catch {}
  }, [userCafeteriasData, userMetaData, user?.institutions])

  return (
    <View style={styles.mainContainer}>
      <View style={{ flexDirection: 'row', alignItems: 'center', marginBottom: 20, marginHorizontal: 5 }}>
        <Text style={{ fontFamily: 'FSJoeyMedium', fontSize: 25, color: theme.colors.white }}>Kantine</Text>
        <View style={{ height: 20, width: 1, backgroundColor: '#fff', marginHorizontal: 10, marginTop: 4 }}></View>
        <Text style={{ fontSize: 16, color: theme.colors.white, marginTop: 4 }}>{metaData?.institutionName}</Text>
      </View>

      {metaData?.pejShopId && /^\d+$/.test(metaData?.pejShopId) ? (
        <>
          <View style={[styles.itemsContainer]}>
            <View style={{ flex: 1 }}>
              <OrderButton
                isRegistered={currentStatus === 'completed'}
                doRegister={() => cafeteriaRegisterRef.current?.setIsActive(true)}
                openUrl={() => {
                  if (currentStatus === 'completed') {
                    cafeteriaWebviewRef.current?.setUrl(`${koplingConfig.pejUrl}/${metaData?.pejShopId}?scheme=elevtjenesten`)
                    cafeteriaWebviewRef.current?.setIsActive(true)
                  } else {
                    WebBrowser.openBrowserAsync(`${koplingConfig.pejUrl}/${metaData?.pejShopId}?scheme=elevtjenesten`, {
                      presentationStyle: WebBrowser.WebBrowserPresentationStyle.PAGE_SHEET,
                      enableDefaultShareMenuItem: false,
                      controlsColor: theme.colors.primary,
                    })
                  }
                }}
              />
            </View>
            {currentStatus === 'not-registered' && (
              <View style={{ flex: 2 }}>
                <Pressable
                  style={[styles.itemContainer]}
                  onPress={() => {
                    cafeteriaRegisterRef.current?.setIsActive(true)
                  }}
                >
                  <Icon type="octicon" name="credit-card" size={30} color={theme.colors.primary} style={styles.itemIcon} />
                  <Text style={styles.itemTitle}>Bestill kantinekort!</Text>
                </Pressable>
              </View>
            )}

            {currentStatus === 'completed' && (
              <>
                <View style={{ flex: 1 }}>
                  <Pressable
                    style={[styles.itemContainer]}
                    onPress={() => {
                      cafeteriaWebviewRef.current?.setUrl(`${koplingConfig.pejUrl}/${metaData?.pejShopId}/wallet/request-top-up?scheme=elevtjenesten`)
                      cafeteriaWebviewRef.current?.setIsActive(true)
                    }}
                  >
                    <Icon type="material-community" name="hand-coin-outline" size={30} color={theme.colors.primary} style={styles.itemIcon} />
                    <Text style={styles.itemTitle}>Be om penger</Text>
                  </Pressable>
                </View>
                <View style={{ flex: 1 }}>
                  <BalanceButton cafeteriaWebviewRef={cafeteriaWebviewRef.current} shopId={metaData?.pejShopId} />
                </View>
              </>
            )}
          </View>
          <View style={{ flex: 1, alignItems: 'center' }}>
            <Pressable
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                paddingVertical: 10,
                paddingHorizontal: 20,
                marginBottom: -10,
              }}
              onPress={() => {
                if (currentStatus === 'completed') {
                  cafeteriaWebviewRef.current?.setUrl(`${koplingConfig.pejUrl}/${metaData?.pejShopId}/user-order-history?scheme=elevtjenesten`)
                  cafeteriaWebviewRef.current?.setIsActive(true)
                } else {
                  WebBrowser.openBrowserAsync(`${koplingConfig.pejUrl}/${metaData?.pejShopId}/user-order-history`, {
                    presentationStyle: WebBrowser.WebBrowserPresentationStyle.PAGE_SHEET,
                    enableDefaultShareMenuItem: false,
                    controlsColor: theme.colors.primary,
                  })
                }
              }}
            >
              <Icon type="octicons" name="history" size={18} color={theme.colors.white} />
              <Text style={{ fontSize: 14, fontFamily: 'FSJoeyMedium', color: theme.colors.white, textDecorationLine: 'underline', marginLeft: 5 }}>
                Ordrehistorikk
              </Text>
            </Pressable>
          </View>
        </>
      ) : (
        <View style={[styles.itemsContainer]}>
          <View style={{ flex: 1 }}>
            <Pressable
              style={[styles.itemContainer]}
              onPress={() => {
                WebBrowser.openBrowserAsync(`https://bit.ly/${metaData?.pejShopId}`, {
                  presentationStyle: WebBrowser.WebBrowserPresentationStyle.PAGE_SHEET,
                  enableDefaultShareMenuItem: false,
                  controlsColor: theme.colors.primary,
                })
              }}
            >
              <Icon type="material-community" name="food-apple-outline" size={30} color={theme.colors.primary} style={styles.itemIcon} />
              <Text style={styles.itemTitle}>Handle</Text>
            </Pressable>
          </View>
        </View>
      )}
      <CafeteriaWebview ref={cafeteriaWebviewRef} />
      <CafeteriaRegister ref={cafeteriaRegisterRef} pejShopId={metaData?.pejShopId} />
    </View>
  )
}

export default CafeteriaWidgets

const styles = StyleSheet.create({
  mainContainer: {
    paddingVertical: 20,
    paddingHorizontal: 15,
    backgroundColor: '#1e5a0f',
    borderRadius: 8,
  },
  itemsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  itemContainer: {
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: '#c4e08c',
    borderRadius: 8,
    padding: 10,
    margin: 5,
  },
  itemIcon: {
    backgroundColor: '#fff',
    width: 54,
    height: 54,
    justifyContent: 'center',
    borderRadius: 100,
    marginBottom: 5,
  },
  itemTitle: {
    fontFamily: 'FSJoeyMedium',
    fontSize: 14,
  },
})
