import { StyleSheet, View, Pressable } from 'react-native'
import { Text } from '@rneui/themed'

// Themes
import theme from '@/config/themes'

// Types
type ToastNetworkStatusProps = {
  toast: any
}

const toastNetworkStatus = ({ toast: _toast }: ToastNetworkStatusProps) => {
  const doClose = () => {
    if (_toast && _toast.id) {
      toast.hide(_toast.id)
    }
  }

  return (
    <View style={{ width: 750, maxWidth: '100%', minWidth: '100%' }}>
      <View style={styles.mainContainer}>
        <View style={styles.content}>
          {!!_toast.message && (
            <Text style={styles.description} numberOfLines={2}>
              {_toast.message}
            </Text>
          )}
        </View>
        <Pressable style={styles.closeButtonContainer} onPress={doClose}>
          <Text style={styles.closeButtonText}>LUKK</Text>
        </Pressable>
      </View>
    </View>
  )
}

export default toastNetworkStatus

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginVertical: 10,
    paddingHorizontal: 20,
    paddingVertical: 3,
    backgroundColor: theme.colors.black,
  },
  content: {
    flexShrink: 1,
    flexGrow: 1,
  },

  description: {
    fontFamily: 'FSJoeyMedium',
    color: theme.colors.white,
  },

  closeButtonContainer: {
    alignSelf: 'flex-start',
    paddingLeft: 20,
    paddingVertical: 2,
  },
  closeButtonText: {
    fontFamily: 'FSJoeyMedium',
    color: theme.colors.primary,
  },
})
