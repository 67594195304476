import axios, { AxiosResponse } from 'axios'

// APIs
import logout from '@/api/auth/logout'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'
// Types
export type IChangePasswordArgs = {
  currentPassword: string
  newPassword: string
}
export type IChangePasswordReturn = Promise<AxiosResponse>

/*
  Change password from the API, using axios.
  Example usage: Go to profile settings / security page
*/
async function changePassword(args: IChangePasswordArgs): IChangePasswordReturn {
  try {
    const res = await axios.put('/api/user/secret', { currentPassword: args.currentPassword, newPassword: args.newPassword })
    await logout()
    return res
  } catch (error: any) {
    xConsole().error(error, 'changePassword')
    return error.response
  }
}

export default changePassword
