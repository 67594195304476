import { View, StyleSheet, ActivityIndicator } from 'react-native'
import { Image } from '@rneui/themed'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

// Types
type FeaturedImageProps = {
  src: string
  hasVideo?: boolean
}

const FeaturedImage = ({ src, hasVideo }: FeaturedImageProps) => {
  const insets = useSafeAreaInsets()
  return (
    <View style={styles.mainContainer}>
      {!!src && !hasVideo ? (
        <Image source={{ uri: src }} style={styles.image} PlaceholderContent={<ActivityIndicator />} placeholderStyle={styles.imagePlaceholder} />
      ) : (
        <View style={{ height: insets.top + 59 }}></View>
      )}
    </View>
  )
}

export default FeaturedImage

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    flexBasis: 'auto', // Web
  },
  image: {
    width: '100%',
    aspectRatio: 16 / 9,
  },
  imagePlaceholder: {
    paddingTop: 50,
    backgroundColor: 'transparent',
    flex: 1,
  },
})
