import React from 'react'
import { StyleSheet, View, ActivityIndicator } from 'react-native'
import { Image, Text, Button } from '@rneui/themed'

// Themes
import theme from '../../../config/themes'

type ListEmergencyItemProps = {
  item: any
  onPress: any
}
const ListEmergencyItem = ({ item, onPress }: ListEmergencyItemProps) => {
  return (
    <View style={styles.mainContainer}>
      <View style={styles.headerContainer}>
        <Image
          source={{ uri: item.photoURL }}
          containerStyle={styles.logoContainer}
          PlaceholderContent={<ActivityIndicator />}
          placeholderStyle={{ backgroundColor: 'transparent' }}
        />
        <View>
          <Text style={styles.title}>{item.title}</Text>
          <Text style={styles.subtitle}>{item.subtitle}</Text>
        </View>
      </View>
      <View>
        <Text style={styles.description}>{item.description}</Text>
        <Button
          title={item.phoneNumber}
          icon={{
            name: 'phone',
            type: 'font-awesome',
            size: 25,
            color: theme.colors.white,
          }}
          iconContainerStyle={styles.iconButtonContainer}
          containerStyle={styles.buttonContiner}
          titleStyle={styles.buttonTitle}
          onPress={onPress}
        />
      </View>
    </View>
  )
}

export default ListEmergencyItem

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: theme.colors.white,
    padding: 20,
    marginBottom: 20,
    borderRadius: 10,
  },
  headerContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    width: 300,
  },
  logoContainer: {
    aspectRatio: 1,
    height: 50,
    marginRight: 15,
  },
  title: {
    fontFamily: 'FSJoeyMedium',
    fontSize: 18,
  },
  subtitle: {
    fontSize: 16,
    color: '#666666',
  },
  description: {
    marginTop: 10,
    fontSize: 18,
  },
  buttonContiner: {
    marginTop: 15,
  },
  buttonTitle: {
    fontFamily: 'FSJoey',
    fontSize: 18,
    lineHeight: 20,
  },
  iconButtonContainer: {
    marginLeft: -10,
    marginRight: 10,
  },
})
