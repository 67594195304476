import { useEffect, useState, useRef } from 'react'
import { StyleSheet, View } from 'react-native'
import { Image, ListItem, Text, Icon } from '@rneui/themed'
import axios from 'axios'

// Theme
import theme from '@/config/themes'

// Components
import ContactProfileOverlay from '@/components/contacts/institutions/ProfileOverlay'
import ContactItem from '@/components/contacts/institutions/Item'

// Navigation
import { useNavigation, NavigationProp } from '@react-navigation/native'

// Variables
const HELPER_IDS = ['aDv0MJLLIVeWnoq2nIeer']

// Types
import type { IContactOverlayRef, IUserOther, RootNavigatorParamList } from '@/types'
import { Routes } from '@/config/routes'

const BullyPageButtons = () => {
  const navigation = useNavigation<NavigationProp<RootNavigatorParamList>>()

  const [helpers, setHelpers] = useState<IUserOther[]>([])
  const ContactOverlayRef = useRef<IContactOverlayRef>(null)

  const openContact = (data: IUserOther) => {
    ContactOverlayRef.current?.setIsActive(true, data)
  }

  useEffect(() => {
    getHelpers()
  }, [])

  const getHelpers = async () => {
    const results: IUserOther[] = []
    for (const id of HELPER_IDS) {
      try {
        const res = await axios.get(`/api/user/${id}`)
        results.push({
          userId: res.data.userId,
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          displayName: res.data.displayName,
          avatarId: res.data.avatarId,
          description: res.data.description,
          availability: res.data.availability,
          title: res.data.title,
        })
      } catch (error) {}
    }
    setHelpers(results)
  }

  return (
    <>
      <View style={{ marginHorizontal: 20, marginVertical: 10 }}>
        <Text style={{ fontFamily: 'FSJoeyMedium', fontSize: 18 }}>Snakk om det. Du kan være anonym.</Text>
      </View>
      <View>
        {helpers.map((v) => (
          <ContactItem
            key={v.userId}
            item={{ type: 'item', props: v }}
            onPress={() => openContact(v)}
            headerBgColor={theme.colors.white}
            placeholderColor={'#ffffff'}
            itemListContainerBgColor={'#f7f7f7'}
          />
        ))}
      </View>

      <View style={{ marginHorizontal: 20, marginVertical: 10 }}>
        <Text style={{ fontFamily: 'FSJoeyMedium', fontSize: 18 }}>Hjelpetjenester</Text>
      </View>
      <View>
        <ListItem
          onPress={() => {
            navigation.navigate(Routes.OrganizationContactsPage, {
              id: 'a8645d50-4da5-4e67-be6b-e879159b5202',
            })
          }}
          bottomDivider
          containerStyle={[styles.itemListContainer, { backgroundColor: theme.colors.white }]}
        >
          <Image
            source={{ uri: 'https://kopling-squidex-production.azurewebsites.net/api/assets/app/1d5763c5-236a-4826-8f29-7aa3ed78f0c6/bla-kors.png' }}
            style={styles.itemListImage}
            containerStyle={styles.itemListImageContainer}
          />
          <ListItem.Content>
            <ListItem.Title numberOfLines={1}>Snakk om mobbing</ListItem.Title>
            <ListItem.Subtitle numberOfLines={1} style={{ color: theme.colors?.grey2 }}>
              Blåkors
            </ListItem.Subtitle>
          </ListItem.Content>
          <Icon type="entypo" name="chevron-right" color={theme.colors.primary} />
        </ListItem>
      </View>

      <ContactProfileOverlay ref={ContactOverlayRef} />
    </>
  )
}

export default BullyPageButtons

const styles = StyleSheet.create({
  itemListContainer: {
    height: 73,
    paddingHorizontal: 20,
    marginHorizontal: 20,
    marginBottom: 10,
    borderRadius: 8,
  },
  itemListImageContainer: {
    width: 50,
    aspectRatio: 1,
  },
  itemListImage: {
    width: '100%',
    height: 'auto',
  },
})
