import axios, { AxiosResponse } from 'axios'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Types
export type IForgotPasswordArgs = {
  phoneNumber: string
}
export type IForgotPasswordReturn = Promise<AxiosResponse>

/*
  Forgot password from the API, using axios. ()
  Example usage: Go to profile settings / password page, click and send reset link to SMS
*/
async function forgotPassword(args: IForgotPasswordArgs): IForgotPasswordReturn {
  try {
    const res = await axios.post('/api/user/forgotpassword', null, { params: { phoneNumber: args.phoneNumber } })
    return res
  } catch (error: any) {
    xConsole().error(error, 'forgotPassword')
    return error.response
  }
}

export default forgotPassword
