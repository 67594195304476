import { StyleSheet, TouchableOpacity } from 'react-native'
import { Icon } from '@rneui/themed'

import theme from '@/config/themes'

type BackButtonProps = {
  onGoBack: Function
}

function BackButton({ onGoBack }: BackButtonProps) {
  return (
    <TouchableOpacity
      onPress={() => {
        onGoBack()
      }}
      style={styles.backButton}
      testID={'LoginBackButton'}
    >
      <Icon type="entypo" name="chevron-left" color={theme.colors.black} size={41} />
    </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
  backButton: {
    width: 60,
    padding: 10,
    marginLeft: -20,
    marginBottom: -15,
  },
})

export default BackButton
