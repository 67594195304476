import { StyleSheet, View, StyleProp, ViewStyle } from 'react-native'
import { Image, Button } from '@rneui/themed'

// Navigation
import { useNavigation, NavigationProp } from '@react-navigation/native'

// Types
import { RootNavigatorParamList } from '../types'
import { Routes } from '../config/routes'
type EmptyInstitutionProps = {
  text?: string
  containerStyle?: StyleProp<ViewStyle>
}

const EmptyInstitution = ({ text = 'Legg til skoler og tjenester i profilen din!', containerStyle = null }: EmptyInstitutionProps) => {
  const navigation = useNavigation<NavigationProp<RootNavigatorParamList>>()

  const onPress = () => {
    navigation.navigate(Routes.SettingsInstitutions)
  }

  return (
    <View style={[styles.mainContainer, containerStyle]}>
      <Image source={require('../assets/images/icons/person-with-border.png')} style={styles.image} containerStyle={styles.imageContainer} />
      <Button title={text} type="clear" containerStyle={styles.buttonContainer} titleStyle={styles.buttonTitle} onPress={onPress} />
    </View>
  )
}

export default EmptyInstitution

const styles = StyleSheet.create({
  mainContainer: {
    width: 330,
    maxWidth: '95%',
    aspectRatio: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#dcd7cf',
    borderRadius: 1000,
    borderWidth: 30,
    borderColor: '#e7e3dd',
    alignSelf: 'center',
  },
  buttonContainer: {
    maxWidth: '60%',
  },
  buttonTitle: {
    fontSize: 15,
    color: '#2b2c2b',
    textDecorationLine: 'underline',
  },
  imageContainer: {
    width: 110,
    aspectRatio: 1,
  },
  image: {
    width: '100%',
    height: 'auto',
  },
})
