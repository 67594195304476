import axios, { AxiosResponse } from 'axios'
import queryString from 'query-string'

// Configs
import { koplingConfig } from '@/config'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Types
export type IRefreshTokenArgs = string
export type IRefreshTokenReturn = Promise<AxiosResponse>

/*
  Refresh token from the API, using axios with x-www-form-urlencoded and queryString
  Example usage: Refresh token when token is expired
*/
async function refreshToken(_refreshToken: IRefreshTokenArgs): IRefreshTokenReturn {
  try {
    const res = await axios({
      method: 'POST',
      url: '/connect/token',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      data: queryString.stringify({
        grant_type: 'refresh_token',
        scope: 'offline_access mobile',
        client_id: koplingConfig.clientId,
        refresh_token: _refreshToken,
      }),
      timeout: 20000,
    })
    return res
  } catch (error: any) {
    xConsole().error(error, 'refreshToken')
    return error.response as AxiosResponse
  }
}

export default refreshToken
