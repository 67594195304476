// APIs
import getApiChannels from '@/api/channels/getChannels'
import getApiChannel from '@/api/channels/getChannel'
import getApiTotalUnreadChannels from '@/api/channels/getTotalUnreadChannels'
import createApiChannel from '@/api/channels/createChannel'
import leaveApiChannel from '@/api/channels/leaveChannel'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '@/store'
import queryString from 'query-string'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Types
import { IChannelsState, IChannel, IUserState, IChannelCustomSystemMessage } from '@/types'

const initialState: IChannelsState = {
  channels: [],
  nextLink: null,
  channelActualData: {},
  totalUnread: 0,
  isLoading: false,
  error: false,
}

const channels = createSlice({
  name: 'channels',
  initialState,
  reducers: {
    getChannels(state) {
      state.isLoading = true
      state.error = false
    },
    getChannelsSuccess(state, action: PayloadAction<{ channels: Array<IChannel>; nextLink: string | null; reset?: boolean }>) {
      const payload = action.payload
      const channels = !payload.reset ? [...state.channels] : []
      const ids = state.channels.map((o) => o.id)

      for (const v of payload.channels) {
        if (!ids.includes(v?.id)) {
          channels.push(v)
        } else {
          const index = state.channels.findIndex((b) => b.id === v.id)
          channels[index] = v

          try {
            if (+new Date(state.channels[index].lastMessageReceivedOn) > +new Date(v.lastMessageReceivedOn)) {
              channels[index].lastMessageReceivedOn = state.channels[index].lastMessageReceivedOn
            }
          } catch (error) {}
        }
      }

      state.channels = channels.sort((a: IChannel, b: IChannel) => {
        return new Date(b?.lastMessageReceivedOn || '').getTime() - new Date(a?.lastMessageReceivedOn || '').getTime()
      })

      state.nextLink = payload.nextLink
      state.isLoading = false
    },
    getChannelsFailure(state) {
      state.isLoading = false
      state.error = true
    },
    getChannelActualDataSuccess(state, action: PayloadAction<{ id: string; data: IChannel }>) {
      const payload = action.payload
      state.channelActualData = { ...state.channelActualData, ...{ [payload.id]: payload.data } }
    },
    resetNextLink(state) {
      state.nextLink = null
    },
    setTotalUnreadChannels(state, action: PayloadAction<number>) {
      state.totalUnread = action.payload
    },
    setChannelLastMessageReceivedOn(state, action: PayloadAction<{ id: string; lastMessageReceivedOn: string }>) {
      const payload = action.payload
      const findIndex = state.channels.findIndex((o) => o.id === payload.id)
      if (findIndex !== -1) {
        state.channels[findIndex].lastMessageReceivedOn = payload.lastMessageReceivedOn
        state.channels = state.channels.sort((a: IChannel, b: IChannel) => {
          return new Date(b?.lastMessageReceivedOn || '').getTime() - new Date(a?.lastMessageReceivedOn || '').getTime()
        })
      }
    },
    deleteChannel(state, action: PayloadAction<string>) {
      const id = action.payload
      state.channels = state.channels.filter((v) => v.id !== id)
      delete state.channelActualData[id]
    },
  },
})

export const {
  getChannels,
  getChannelsSuccess,
  getChannelsFailure,
  getChannelActualDataSuccess,
  resetNextLink,
  setTotalUnreadChannels,
  setChannelLastMessageReceivedOn,
  deleteChannel,
} = channels.actions

export default channels.reducer

export type IFetchChannelsArgs = {
  scope?: string
  per_page?: number
  page?: number
  nextLink?: string
  reset?: boolean
}
export const fetchChannels =
  (args?: IFetchChannelsArgs): AppThunk =>
  async (dispatch, getState) => {
    try {
      const { user, token }: IUserState = getState().user
      const isTokenExpired = token ? new Date(token?.expires_date).getTime() < new Date().getTime() : true
      dispatch(getChannels())

      if (user && !isTokenExpired) {
        let endpoint = '/api/channel/list3'
        if (args?.nextLink) {
          endpoint = args.nextLink
        } else {
          const defaultParams = {
            scope: 'id,lastMessageReceivedOn,topic,topicAvatarId,participants,topicDescription,isAnonymous,availability',
            per_page: 100,
            page: 1,
          }
          const params = { ...defaultParams, ...args }
          const paramsStr = queryString.stringify(params)
          endpoint = `${endpoint}?${paramsStr}`
        }
        const res = await getApiChannels(endpoint)
        if (res?.status === 200) {
          dispatch(getChannelsSuccess({ channels: res.data.items, nextLink: res.data.nextLink, reset: args?.reset }))
        } else {
          dispatch(getChannelsSuccess({ channels: [], nextLink: null }))
        }
      }
    } catch (error: any) {
      xConsole().error(error, 'channelsSlice (fetchChannels)')
      dispatch(getChannelsFailure())
    }
  }

export const fetchChannelActualData =
  (id: string, lastMessageReceivedOn?: string, unread?: boolean): AppThunk =>
  async (dispatch, getState) => {
    try {
      const { channelActualData } = getState().channels
      if (channelActualData[id] && channelActualData[id].lastMessageReceivedOn === lastMessageReceivedOn && channelActualData[id].unread === unread) {
        return false
      }
      const res = await getApiChannel(id)
      if (res.status === 200 && res.data?.topic !== 'Chat Topic') {
        dispatch(getChannelActualDataSuccess({ id: id, data: res.data }))
      }
    } catch (error: any) {
      xConsole().error(error, 'channelsSlice (fetchChannelActualData)')
      dispatch(getChannelsFailure())
    }
  }

export const fetchTotalUnreadChannels = (): AppThunk => async (dispatch, getState) => {
  try {
    const { user, token }: IUserState = getState().user
    const isTokenExpired = token ? new Date(token?.expires_date).getTime() < new Date().getTime() : true
    if (user && !isTokenExpired) {
      const res = await getApiTotalUnreadChannels()
      if (res?.status === 200) {
        dispatch(setTotalUnreadChannels(res.data))
      }
    }
  } catch (error: any) {
    xConsole().error(error, 'channelsSlice (fetchTotalUnreadChannels)')
    dispatch(getChannelsFailure())
  }
}

interface ICreateChannelArgs {
  participant: string
  isAnonymous: boolean
  reference: object
  firstMessage: string
  customSystemMessage?: IChannelCustomSystemMessage
  onSuccess: Function
  onError: Function
}
export const createChannel =
  (args: ICreateChannelArgs): AppThunk =>
  async (dispatch, getState) => {
    try {
      const res = await createApiChannel({
        participant: args.participant,
        isAnonymous: args.isAnonymous,
        reference: args.reference,
        firstMessage: args.firstMessage,
        customSystemMessage: args.customSystemMessage,
      })
      if (res.status === 200) {
        dispatch(fetchChannels())
        let i = 0
        const interval = setInterval(() => {
          i++
          const { channels } = getState().channels
          const currentChannel = channels.find((c) => c.id === res.data.id)
          if (currentChannel || i >= 200) {
            clearInterval(interval)
            args.onSuccess(currentChannel)
          }
        }, 500)
      } else {
        args.onError()
      }
    } catch (error: any) {
      xConsole().error(error as Error, 'channelsSlice (createChannel)')
      args.onError(error.message)
    }
  }

interface ILeaveChannelArgs {
  id: string
  onSuccess: Function
  onError: Function
}
export const leaveChannel =
  (args: ILeaveChannelArgs): AppThunk =>
  async (dispatch, getState) => {
    try {
      const res = await leaveApiChannel({
        id: args.id,
      })
      if (res.status === 200) {
        dispatch(deleteChannel(args.id))
        args.onSuccess(res.data.id)
      } else {
        args.onError()
      }
    } catch (error: any) {
      xConsole().error(error as Error, 'channelsSlice (leaveChannel)')
      args.onError(error.message)
    }
  }
