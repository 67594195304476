import { useState, useEffect } from 'react'
import { View, Platform } from 'react-native'
import { Text, Button } from '@rneui/themed'
import * as Linking from 'expo-linking'

// Store
import { useSelector } from 'react-redux'
import { RootState } from '@/store/rootReducer'

// Contexts
import { useNotifications } from '@/contexts/notifications'

const SettingsNotificationInfo = () => {
  if (Platform.OS === 'web') return
  const notifications = useNotifications()

  const [isOk, setIsOk] = useState(false)
  const [notificationData, setNotificationData] = useState()

  const init = () => {
    if (typeof notifications?.getNotificationData !== 'function') return
    const notificationData = notifications.getNotificationData()
    setIsOk(Object.values(notificationData).every((v) => v !== null)) // Check nulls
    setNotificationData(notificationData)
  }
  useEffect(() => {
    init()
  }, [])

  return (
    <View style={{ justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}>
      <View>
        <Text style={{ fontSize: 18, fontFamily: 'FSJoeyMedium' }}>Skal vi sende deg varsler?</Text>
        <Text style={{ fontSize: 11 }}>Se ut som vi kan ikke sende varsler. Registrerer deg nå.</Text>
      </View>
      <View>
        <Button
          title="Registrere"
          buttonStyle={{ width: 100, paddingVertical: 8 }}
          onPress={async () => {
            await notifications.initNotifications()
            const status = await notifications.promptRequestPermissions()
            if (status === 'denied') {
              let url = ''
              if (Platform.OS === 'android') {
                url = 'https://support.google.com/googleplay/answer/9431959'
              } else if (Platform.OS === 'ios') {
                url = 'https://support.apple.com/guide/iphone/control-access-to-information-in-apps-iph251e92810/ios'
              }
              if (url) {
                toast.show(url, {
                  type: 'main',
                  data: {
                    title: 'Se om tillatelse?',
                    icon: 'success',
                    onPress() {
                      Linking.openURL(url)
                    },
                  },
                })
              }
            }
            init()
          }}
        />
      </View>
    </View>
  )
}

export default SettingsNotificationInfo
