import { useEffect, useState, useRef } from 'react'
import { View, StyleSheet } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { ScrollView } from 'react-native-gesture-handler'
import { Image, ListItem, Text, Icon } from '@rneui/themed'
import axios from 'axios'

import TabMenus from '@/components/TabMenus'

// Components
import TopNavbar from '@/components/contents/main/TopNavbar'
import FeaturedImage from '@/components/contents/main/FeaturedImage'
import ArticleTitle from '@/components/contents/main/ArticleTitle'
import ContactProfileOverlay from '@/components/contacts/institutions/ProfileOverlay'
import ContactItem from '@/components/contacts/institutions/Item'

// Theme
import theme from '@/config/themes'

// Navigation
import { useNavigation, NavigationProp } from '@react-navigation/native'

// Variables
const HELPER_IDS = ['aDv0MJLLIVeWnoq2nIeer', 'CWNHTNj9mEdkhUI15hTPu']

// Types
import type { IContactOverlayRef, IUserOther, RootNavigatorParamList } from '@/types'
import { Routes } from '@/config/routes'

let BullyScreen = () => {
  const navigation = useNavigation<NavigationProp<RootNavigatorParamList>>()
  const [helpers, setHelpers] = useState<IUserOther[]>([])
  const ContactOverlayRef = useRef<IContactOverlayRef>(null)

  useEffect(() => {
    getHelpers()
  }, [])

  const getHelpers = async () => {
    const results: IUserOther[] = []
    for (const id of HELPER_IDS) {
      try {
        const res = await axios.get(`/api/user/${id}`)
        results.push({
          userId: res.data.userId,
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          displayName: res.data.displayName,
          avatarId: res.data.avatarId,
          description: res.data.description,
          availability: res.data.availability,
          title: res.data.title,
        })
      } catch (error) {}
    }
    setHelpers(results)
  }

  const openContact = (data: IUserOther) => {
    ContactOverlayRef.current?.setIsActive(true, data)
  }

  return (
    <SafeAreaView edges={['bottom', 'left', 'right']} style={{ flexDirection: 'column', flex: 1 }}>
      <TopNavbar goBack />

      <ScrollView>
        <FeaturedImage src={''} />
        <ArticleTitle title={'Mobbeknappen'} />
        <View style={{ marginTop: 10 }}>
          <ListItem
            onPress={() =>
              navigation.navigate(Routes.BullyPage, {
                id: '9e53b5b8-08ce-4f19-a7e6-9f439698d125',
              })
            }
            bottomDivider
            containerStyle={[styles.itemListContainer, { backgroundColor: theme.colors.white }]}
          >
            <Image source={require('../assets/images/icons/mc-01.png')} style={styles.itemListImage} containerStyle={styles.itemListImageContainer} />
            <ListItem.Content>
              <ListItem.Title numberOfLines={1}>Blir du mobbet?</ListItem.Title>
            </ListItem.Content>
            <Icon type="entypo" name="chevron-right" color={theme.colors.primary} />
          </ListItem>
          <ListItem
            onPress={() =>
              navigation.navigate(Routes.BullyPage, {
                id: 'dc9f7195-6c5a-4b2d-ba86-5533e7ba7ab7',
              })
            }
            bottomDivider
            containerStyle={[styles.itemListContainer, { backgroundColor: theme.colors.white }]}
          >
            <Image source={require('../assets/images/icons/mc-02.png')} style={styles.itemListImage} containerStyle={styles.itemListImageContainer} />
            <ListItem.Content>
              <ListItem.Title numberOfLines={1}>Varsle mobbing av andre?</ListItem.Title>
            </ListItem.Content>
            <Icon type="entypo" name="chevron-right" color={theme.colors.primary} />
          </ListItem>
          <ListItem
            onPress={() =>
              navigation.navigate(Routes.BullyPage, {
                id: 'dee2e73e-22a9-49c3-9f44-60c33e10a282',
              })
            }
            bottomDivider
            containerStyle={[styles.itemListContainer, { backgroundColor: theme.colors.white }]}
          >
            <Image source={require('../assets/images/icons/mc-03.png')} style={styles.itemListImage} containerStyle={styles.itemListImageContainer} />
            <ListItem.Content>
              <ListItem.Title numberOfLines={1}>Mobber du andre?</ListItem.Title>
            </ListItem.Content>
            <Icon type="entypo" name="chevron-right" color={theme.colors.primary} />
          </ListItem>
        </View>
        <Text h4 h4Style={{ fontFamily: 'FSJoeyBold', fontSize: 20, paddingHorizontal: 20, marginVertical: 20 }}>
          Snakke med noen
        </Text>
        <View>
          {helpers.map((v) => (
            <ContactItem
              key={v.userId}
              item={{ type: 'item', props: v }}
              onPress={() => openContact(v)}
              headerBgColor={theme.colors.white}
              placeholderColor={'#ffffff'}
              itemListContainerBgColor={'#f7f7f7'}
            />
          ))}
        </View>
      </ScrollView>
      <ContactProfileOverlay ref={ContactOverlayRef} />

      <TabMenus />
    </SafeAreaView>
  )
}

export default BullyScreen

const styles = StyleSheet.create({
  itemListContainer: {
    paddingHorizontal: 20,
    marginHorizontal: 20,
    marginBottom: 10,
    borderRadius: 8,
  },
  itemListImageContainer: {
    width: 70,
    aspectRatio: 1,
    borderRadius: 8,
  },
  itemListImage: {
    width: '100%',
    height: 'auto',
  },
})
