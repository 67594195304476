import { useEffect, useState, forwardRef, useCallback, useImperativeHandle } from 'react'
import { Platform, Dimensions, StyleSheet, View, ActivityIndicator } from 'react-native'
import { FlatList } from 'react-native-gesture-handler'
import { Text, Icon } from '@rneui/themed'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import axios from 'axios'
import uFuzzy from '@leeoniya/ufuzzy'
import { uniq } from 'lodash'

// Store
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/store/rootReducer'
import { fetchUser } from '@/slices/userSlice'
import { fetchInstitutions } from '@/slices/institutionsSlice'

// Components
import SearchInstitutions from '@/components/institutions/selector/Search'
import InstitutionItem from '@/components/institutions/selector/Item'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Theme
import theme from '@/config/themes'

// Types
import type { IInstitution } from '@/types'
type InstitutionListSelectorProps = {
  onSelect?: Function
  onSubmit?: Function
  filterCounty?: string | boolean
  defaultSelected?: Array<string>
  multiple?: boolean
}

type IInstitutionList =
  | {
      type: 'header'
      props: {
        title: string
      }
    }
  | {
      type: 'item'
      props: IInstitution
    }

// Variables
const { height } = Dimensions.get('window')

const InstitutionSelectorList = forwardRef(
  ({ onSelect, onSubmit, filterCounty, defaultSelected = [], multiple = false }: InstitutionListSelectorProps, ref) => {
    const dispatch = useDispatch()
    const [isActive, setIsActive] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const insets = useSafeAreaInsets()

    useImperativeHandle(ref, () => ({
      setIsActive,
    }))

    // State
    const { user } = useSelector((state: RootState) => state.user)
    const { institutions, isLoading: isApiLoading } = useSelector((state: RootState) => state.institutions)
    const [institutionList, setInstitutionList] = useState<IInstitutionList[]>([])
    const [institutionsFiltered, setInstitutionsFiltered] = useState<IInstitutionList[]>([])

    useEffect(() => {
      dispatch(fetchInstitutions())
    }, [])

    useEffect(() => {
      filterInstitutions()
    }, [institutions])

    const filterInstitutions = useCallback(() => {
      const list: IInstitutionList[] = []
      if (filterCounty && !multiple) {
        list.push({
          type: 'item',
          props: {
            institutionId: 'none-selected',
            institutionName: 'Finner ikke skolen min - fortsett uten',
            description: '',
            linkURL: '',
            countyId: null,
          },
        })
        for (const institution of institutions.filter((v) => !v.isCounty)) {
          if (institution.countyId === filterCounty) {
            if (!list.some((v) => v.type === 'header')) {
              list.push({
                type: 'header',
                props: { title: 'Disse skolene har Elevtjenesten' },
              })
            }

            if (!institution.isCounty && !institution.isNational) {
              list.push({ type: 'item', props: institution })
            }
          }
        }

        setInstitutionList(list)
      } else {
        for (const institution of institutions) {
          if (!institution.isCounty && !institution.isNational) {
            list.push({ type: 'item', props: institution })
          }
        }
      }
      setInstitutionList(list)
    }, [filterCounty, institutions, multiple])

    const onSelectItem = async (active: boolean, id: string) => {
      setIsLoading(true)
      try {
        if (onSelect) {
          onSelect(active, id)
        }
      } catch (error: any) {
        xConsole().error(error as Error, 'institutions/selector/List.ts')
      } finally {
        setIsLoading(false)
      }
    }

    const renderItem = useCallback(
      ({ item }) => {
        if (item.type == 'item') {
          let isActive = !!user?.institutions.some((a) => a.institutionId === item.props.institutionId)
          if (defaultSelected.length) {
            isActive = !!defaultSelected.some((a) => a === item.props.institutionId)
          }
          if (!multiple) {
            isActive = defaultSelected[0] === item.props.institutionId
          }
          return <InstitutionItem item={item.props} onPress={(active, id) => onSelectItem(active, id)} active={isActive} loading={isLoading} />
        } else {
          return (
            <Text key={item.props.id} style={{ paddingLeft: 20, marginTop: 15, fontFamily: 'FSJoeyMedium', fontSize: 20, height: 40 }}>
              {item.props.title}
            </Text>
          )
        }
      },
      [user, isLoading, defaultSelected]
    )

    const onSearch = useCallback(
      (s: string) => {
        try {
          if (s.length) {
            const result: IInstitutionList[] = []
            const uf = new uFuzzy({ intraMode: 1, intraIns: 1, intraSub: 1, intraTrn: 1, intraDel: 1 })
            const formatted = Object.entries([...institutionList]).map(([k, v]) =>
              v.type === 'item' && v.props.institutionId ? v.props.institutionName : ''
            )
            const idxs = uf.filter(formatted, s)
            const info = uf.info(idxs, formatted, s)
            const order = uf.sort(info, formatted, s)
            for (const i of order) {
              const item = institutionList[info.idx[i]]
              result.push(item)
            }
            setInstitutionsFiltered(result)
          } else {
            setInstitutionsFiltered([])
          }
        } catch (error) {
          xConsole().error(error as Error, 'institutions/selector/List.tsx (onSearch)')
        }
      },
      [institutionList]
    )

    return (
      <View style={styles.listContainer}>
        <Text h4 style={{ paddingHorizontal: 20 }}>
          Velg skole
        </Text>
        <SearchInstitutions
          onSearch={(s: string) => {
            onSearch(s)
          }}
        />
        {institutionList.length ? (
          <FlatList
            data={institutionsFiltered.length > 0 ? institutionsFiltered : institutionList}
            keyExtractor={(item: any, index: any) => index}
            renderItem={renderItem}
            style={{ marginTop: 10 }}
            contentInset={{ bottom: insets.bottom }}
            testID="InstitutionSelectorList_BottomSheetFlatList"
          />
        ) : isApiLoading ? (
          <View style={{ flex: 1, height: '100%', justifyContent: 'center' }}>
            <ActivityIndicator color={theme.colors.black} testID="InstitutionSelectorList_View_ActivityIndicator" />
          </View>
        ) : (
          <></>
        )}
        {!!defaultSelected.length && (
          <Icon
            type="octicon"
            name="arrow-right"
            size={48}
            color={theme.colors.white}
            containerStyle={styles.fabContainer}
            onPress={() => {
              if (onSubmit) {
                onSubmit({ reference: 'click-arrow' })
              }
            }}
          />
        )}
      </View>
    )
  }
)

export default InstitutionSelectorList

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 200,
  },
  contentContainer: {
    backgroundColor: 'white',
  },
  itemContainer: {
    padding: 6,
    margin: 6,
    backgroundColor: '#eee',
  },
  listContainer: {
    flex: 1,
    ...Platform.select({
      web: {
        maxHeight: height * 0.7 - 70,
      },
    }),
  },
  fabContainer: {
    position: 'absolute',
    bottom: 100,
    right: 20,
    width: 66,
    height: 66,
    backgroundColor: theme.colors.primary,
    borderRadius: 100,
    justifyContent: 'center',
  },
})
