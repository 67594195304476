import { useEffect, useState, useCallback } from 'react'
import { StyleSheet, StyleProp, ViewStyle, Text } from 'react-native'

import { SimpleGrid } from 'react-native-super-grid'
import { useFocusEffect } from '@react-navigation/native'

// Store
import { useSelector } from 'react-redux'
import { RootState } from '@/store/rootReducer'

// APIs
import getApiContents from '@/api/contents/getPages'

// Components
import ItemContent from '@/components/contents/main/ItemContent'

// Helpers
import getTargetGroupsByUser from '@/plugins/helpers/getTargetGroupsByUser'

// Types
type QueryContentsProps = {
  ids: Array<{ id: string }>
  containerStyle?: StyleProp<ViewStyle>
}

const QueryContents = ({ ids, containerStyle }: QueryContentsProps) => {
  const { user } = useSelector((state: RootState) => state.user)
  const [contents, setContents] = useState<Array<any>>([])

  if (!user || !ids?.length) return <></>

  const [isLoading, setIsLoading] = useState(false)

  useFocusEffect(
    useCallback(() => {
      getContents()
    }, [])
  )

  const getContents = useCallback(async () => {
    try {
      setIsLoading(true)
      const _contents: any = []
      const targetGroups = getTargetGroupsByUser(user)
      const _ids = ids.map((v) => v.id)
      const res = await getApiContents({ targetGroups, ids: _ids })

      res.data.forEach((v: any, i: number) => {
        _contents.push({ id: v.id, data: v.flatData })
      })

      // Sort by given ids
      _contents.sort((a, b) => _ids.indexOf(a?.id) - _ids.indexOf(b?.id))

      const formattedContents = [..._contents]
      _contents.forEach((v: any, i: number) => {
        const findCurrentIndex = formattedContents.findIndex((a) => a.id === v.id)
        const prevContent = _contents[i - 1]
        const nextContent = _contents[i + 1]
        if (v.data.fullWidth) {
          formattedContents.splice(findCurrentIndex + 1, 0, { data: {} })
        }

        let preventDuplicateReplace = false
        if (prevContent?.data?.fullWidth && !v.data?.fullWidth && nextContent?.data?.fullWidth) {
          formattedContents.splice(findCurrentIndex + 1, 0, { data: {}, fillable: true })
          preventDuplicateReplace = true
        }

        if (v.data?.fullWidth === false) {
          const replaceWithIndex = formattedContents.findIndex((v) => v.fillable)
          if (~replaceWithIndex && !preventDuplicateReplace) {
            formattedContents[replaceWithIndex] = v
            formattedContents.splice(findCurrentIndex, 1)
          }
        }
      })

      // Save data
      setContents(formattedContents)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }, [user.userId])

  const renderItem = useCallback(({ item }) => {
    if (!item.id) {
      return <></>
    }
    return <ItemContent data={item} />
  }, [])

  return (
    <SimpleGrid
      style={[styles.mainContainer, containerStyle]}
      spacing={20}
      maxItemsPerRow={2}
      data={contents}
      renderItem={renderItem}
      listKey={'1'}
    />
  )
}

export default QueryContents

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    flexWrap: 'wrap',
    flexFlow: 'row wrap',
  },
})
