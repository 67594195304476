import { StyleSheet, Dimensions, View } from 'react-native'
import { Text, Button } from '@rneui/themed'

// Components
import Avatar from '@/components/Avatar'

// Helpers
import checkUsersAlreadyConnected from '@/plugins/helpers/checkUsersAlreadyConnected'
import getApiUsers from '@/api/users/getApiUsers'

// Store
import { useSelector } from 'react-redux'
import { RootState } from '@/store/rootReducer'

// Navigation
import { useNavigation, NavigationProp, StackActions } from '@react-navigation/native'

// Themes
import theme from '@/config/themes'

// Variables
const { width } = Dimensions.get('window')

// Types
import type { IMessage, RootNavigatorParamList } from '@/types'
import type { MessageProps } from 'react-native-gifted-chat'
import { Routes } from '@/config/routes'
type ChatMessageContactCardProps = MessageProps<IMessage> & {
  isAnonymous: boolean
}

function ChatMessageContactCard(props: ChatMessageContactCardProps) {
  const { currentMessage, isAnonymous, position } = props
  const navigation = useNavigation<NavigationProp<RootNavigatorParamList>>()
  const item = currentMessage && currentMessage.metaData ? currentMessage.metaData : null
  const { channels } = useSelector((state: RootState) => state.channels)

  /* ========= Start chat ======== */
  const startChat = async (isAnonymous: boolean) => {
    const channelExists = checkUsersAlreadyConnected(item.targetId, isAnonymous, channels)
    if (channelExists) {
      const pushAction = StackActions.push(Routes.Chat, {
        item: channelExists,
      } as RootNavigatorParamList['ChatScreen'])
      navigation.dispatch(pushAction)
    } else {
      // Go to new channel for create new one
      const pushAction = StackActions.push(Routes.Chat, {
        item: {
          topic: item.targetDisplayName,
          topicDescription: item.targetTitle,
          topicAvatarId: item.targetAvatarId,
          isAnonymous: isAnonymous,
        },
        create: item.targetUserId,
        customSystemMessage: {
          type: 'contactCardSystemMessage',
          message: `${item.targetUserId}||${item.senderDisplayName}||${item.refContent}`,
        },
        reference: { institution: '' }, // @TODO If contact card ?
      } as RootNavigatorParamList['ChatScreen'])
      navigation.dispatch(pushAction)
    }
  }

  if (!item) {
    return null
  }
  return (
    <View style={[styles.mainContainer, { backgroundColor: !isAnonymous ? '#f2efea' : '#1d1d1d' }]}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Avatar
          avatarId={item.targetAvatarId}
          placeholderTitle={item.targetDisplayName}
          props={{
            size: 45,
            containerStyle: { marginRight: 15 },
            placeholderStyle: { backgroundColor: theme.colors.grey4 },
          }}
        />
        <View>
          <Text
            style={{
              fontFamily: 'FSJoeyMedium',
              fontSize: 18,
              color: !isAnonymous ? theme.colors.black : theme.colors.white,
            }}
          >
            {item.targetDisplayName}
          </Text>
          {!!item.targetTitle && (
            <Text
              style={{
                fontSize: 14,
                color: !isAnonymous ? '#666666' : '#cecece',
              }}
            >
              {item.targetTitle}
            </Text>
          )}
        </View>
      </View>
      {position === 'right' && !!item.refContent && (
        <Text
          style={{
            fontSize: 16,
            color: !isAnonymous ? '#2b2c2b' : '#ffffff',
            textAlign: 'center',
            marginTop: 15,
          }}
        >
          {item.refContent.trim()}
        </Text>
      )}
      {position === 'left' && (
        <>
          <Button
            title={'START DIALOG SOM ANONYM'}
            containerStyle={[styles.buttonContainer, { marginTop: 15 }]}
            buttonStyle={[styles.button, { backgroundColor: theme.colors.grey1 }]}
            titleStyle={styles.buttonTitle}
            onPress={() => {
              startChat(true)
            }}
          />
          <Button
            title={'START DIALOG'}
            containerStyle={[styles.buttonContainer, { marginTop: 10 }]}
            buttonStyle={[styles.button]}
            titleStyle={styles.buttonTitle}
            onPress={() => {
              startChat(false)
            }}
          />
        </>
      )}
    </View>
  )
}

export default ChatMessageContactCard

const styles = StyleSheet.create({
  mainContainer: {
    width: width - 81,
    marginHorizontal: -8,
    marginVertical: -2,
    padding: 20,
    borderRadius: 15,
    alignItems: 'center',
    maxWidth: 500,
  },
  buttonContainer: {
    width: '100%',
  },
  button: {
    paddingVertical: 10,
  },
  buttonTitle: {
    fontFamily: 'FSJoeyMedium',
    fontSize: 14,
    lineHeight: 14,
  },
})
