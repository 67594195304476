import { useState, useEffect, useCallback, useRef } from 'react'
import { View } from 'react-native'
import { FlatList } from 'react-native-gesture-handler'
import { debounce, uniqBy } from 'lodash'
import uFuzzy from '@leeoniya/ufuzzy'

// Store
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/store/rootReducer'
import { fetchInstitutionHelpers } from '@/slices/institutionsSlice'

// Components
import SearchContacts from '@/components/contacts/institutions/Search'
import ContactItem from '@/components/contacts/institutions/Item'
import ContactProfileOverlay from '@/components/contacts/institutions/ProfileOverlay'
import EmptyInstitution from '@/components/EmptyInstitution'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Theme
import theme from '@/config/themes'

// Types
import type { IInstitution, IInstitutionListItem, IContactOverlayRef } from '@/types'
type ListContactsProps = {
  isWhiteBg?: boolean
}

const ListContacts = ({ isWhiteBg = false }: ListContactsProps) => {
  const dispatch = useDispatch()
  const ContactOverlayRef = useRef<IContactOverlayRef>(null)

  // State: User
  const { user } = useSelector((state: RootState) => state.user)
  if (!user) {
    return null
  }

  // State: Institutions
  const { helpers } = useSelector((state: RootState) => state.institutions)
  const [helpersFiltered, setHelpersFiltered] = useState<IInstitutionListItem[]>([])

  const updateInstitutionHelpers = () => {
    dispatch(fetchInstitutionHelpers())
  }

  useEffect(() => {
    updateInstitutionHelpers()
  }, [user.institutions])

  const openContact = (data: IInstitution) => {
    ContactOverlayRef.current?.setIsActive(true, data)
  }

  const renderItem = useCallback(
    ({ item }) => (
      <ContactItem
        item={item}
        onPress={() => openContact(item.props)}
        headerBgColor={isWhiteBg ? theme.colors.white : ''}
        placeholderColor={isWhiteBg ? '#ffffff' : '#f2efea'}
        itemListContainerBgColor={isWhiteBg ? '#f7f7f7' : ''}
      />
    ),
    []
  )

  const onSearch = useCallback(
    debounce((s: string) => {
      try {
        if (s.length) {
          const result: IInstitutionListItem[] = []
          const uf = new uFuzzy({ intraMode: 1, intraIns: 1, intraSub: 1, intraTrn: 1, intraDel: 1 })
          const formattedHelpers = Object.entries([...helpers]).map(([k, v]) => (v.type === 'item' && v.props.displayName) || '')
          const idxs = uf.filter(formattedHelpers, s)
          const info = uf.info(idxs, formattedHelpers, s)
          const order = uf.sort(info, formattedHelpers, s)
          for (const i of order) {
            const item = helpers[info.idx[i]]
            if (item.type === 'item') {
              result.push(item)
            }
          }
          setHelpersFiltered(uniqBy(result, 'props.userId'))
        } else {
          setHelpersFiltered([])
        }
      } catch (error) {
        xConsole().error(error as Error, 'contacts/institutions/List.tsx (onSearch)')
      }
    }, 1000),
    [helpers]
  )

  return (
    <>
      <SearchContacts
        onSearch={(s: string) => {
          onSearch(s)
        }}
        inputColor={isWhiteBg ? '#f7f7f7' : ''}
      />
      {helpers.length ? (
        <View style={{ flex: 1 }}>
          <FlatList
            data={helpersFiltered.length ? helpersFiltered : helpers}
            keyExtractor={(item: any, index: any) => index}
            renderItem={renderItem}
            // stickyHeaderIndices={indices}
            style={{ minHeight: 1 }} // Hacks scroll height problem on Web
            contentContainerStyle={{ paddingBottom: 65 }}
          />
        </View>
      ) : (
        <View style={{ flex: 1, height: '100%', justifyContent: 'center' }}>
          {!user.institutions.length && <EmptyInstitution containerStyle={{ marginTop: -85 }} />}
        </View>
      )}

      <ContactProfileOverlay ref={ContactOverlayRef} />
    </>
  )
}

export default ListContacts
