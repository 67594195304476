import { View } from 'react-native'

// Components
import ScreenSubpageLayout from '@/components/settings/ScreenSubpageLayout'
import Crisisroutine from '@/components/contents/Crisisroutine'

const SettingsCrisisroutineScreen = () => {
  return (
    <ScreenSubpageLayout title="Retningslinjer ved bekymring for elev som tar kontakt via appen">
      <View style={{ marginTop: 15, marginBottom: 30 }}>
        <Crisisroutine />
      </View>
    </ScreenSubpageLayout>
  )
}

export default SettingsCrisisroutineScreen
