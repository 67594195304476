import { useState, useRef, useCallback } from 'react'
import { View, ActivityIndicator } from 'react-native'
import { FlashList } from '@shopify/flash-list'
import uFuzzy from '@leeoniya/ufuzzy'
import { debounce } from 'lodash'
import axios, { CancelTokenSource } from 'axios'

// APIs
import { chatApi } from '@/api/chatApi'

// Navigation
import { useNavigation, NavigationProp, useFocusEffect } from '@react-navigation/native'

// Store
import { useDispatch } from 'react-redux'

// Components
import SearchChannels from '@/components/channels/Search'
import ChannelItem from '@/components/channels/Item'
import ChannelSettingsOverlay from '@/components/channels/settings/Overlay'
import ChannelEmpty from '@/components/channels/Empty'

// Types
import type { IChannel, IChannelSettingsOverlayRef, RootNavigatorParamList } from '@/types'
import { Routes } from '@/config/routes'

const ListChannels = () => {
  const dispatch = useDispatch()
  const navigation = useNavigation<NavigationProp<RootNavigatorParamList>>()
  const channelSettingsOverlayRef = useRef<IChannelSettingsOverlayRef>(null)

  const [isRefetching, setIsRefetching] = useState(false)

  // State: Channels
  const [dataSearchFiltered, setDataSearchFiltered] = useState<IChannel[]>([])

  const { data, isFetching, refetch } = chatApi.endpoints.getChannels.useQuery(undefined, {
    pollingInterval: 30000,
    skip: false,
    refetchOnMountOrArgChange: true,
  })

  const connectChannel = (data: IChannel) => {
    navigation.navigate(Routes.Chat, {
      item: data,
    })
  }

  // Update actual channel data when came back
  useFocusEffect(
    useCallback(() => {
      dispatch(chatApi.endpoints.getChannels.initiate())
      refetch()
    }, [])
  )

  const openChannelSettings = (id: string) => {
    if (!channelSettingsOverlayRef.current) {
      return
    }
    channelSettingsOverlayRef.current.setIsActive(true, id)
  }

  const [cancelSearchToken, setCancelSearchToken] = useState<CancelTokenSource | null>(null)
  const onSearch = useCallback(
    debounce(async (s: string) => {
      try {
        if (cancelSearchToken) {
          cancelSearchToken.cancel()
        }
        if (s.length) {
          const cToken = axios.CancelToken.source()
          setCancelSearchToken(cToken)

          const res = await axios.get('/api/channel/list3?scope=id,topic,lastMessageReceivedOn&per_page=100&page=1', {
            cancelToken: cToken.token,
          })
          const data = res.data.items
          const result: IChannel[] = []
          const uf = new uFuzzy({ intraMode: 1, intraIns: 1, intraSub: 1, intraTrn: 1, intraDel: 1 })
          const formattedChannels = Object.entries([...data]).map(([k, v]) => v.topic || '')
          const idxs = uf.filter(formattedChannels, s)
          const info = uf.info(idxs, formattedChannels, s)
          const order = uf.sort(info, formattedChannels, s)
          for (const i of order) {
            const item = data[info.idx[i]]
            result.push(item)
          }
          setDataSearchFiltered(result)
        } else {
          setDataSearchFiltered([])
        }
      } catch {}
    }, 500),
    [cancelSearchToken]
  )

  const onRefresh = () => {
    setIsRefetching(true)
    dispatch(chatApi.util.resetApiState())
    dispatch(chatApi.endpoints.getChannels.initiate())
    setTimeout(async () => {
      await refetch()
      setIsRefetching(false)
    }, 1000)
  }

  const renderItem = ({ item }) => {
    return <ChannelItem item={item} onPress={() => connectChannel(item)} onLongPress={() => openChannelSettings(item.id)} />
  }

  const footerRenderer = () => {
    return <View style={{ height: 73, justifyContent: 'center', alignItems: 'center' }}>{!!isFetching && <ActivityIndicator />}</View>
  }

  let dataFiltered = [...(data ?? [])]
  dataFiltered = dataFiltered.filter((v, i, a) => a.map((v) => v.id).indexOf(v.id) === i)
  if (dataSearchFiltered.length) {
    dataFiltered = [...dataSearchFiltered]
  }

  return (
    <>
      <SearchChannels onSearch={onSearch} />
      <FlashList
        data={dataFiltered}
        renderItem={renderItem}
        estimatedItemSize={73}
        ListEmptyComponent={<ChannelEmpty />}
        refreshing={isRefetching}
        onRefresh={onRefresh}
      />
      <ChannelSettingsOverlay ref={channelSettingsOverlayRef} />
    </>
  )
}

export default ListChannels
