import axios, { AxiosResponse } from 'axios'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Types
export type ISetProfileInfoArgs = {
  // Custom type for safe
  firstName?: string
  lastName?: string
  description?: string
  title?: string
  defaultAvatarId?: string
  county?: string
  email?: string
}
export type ISetProfileInfoReturn = Promise<AxiosResponse>

/*
  Set/update profile info (like firstName & lastName)
  Example usage: Profile setting page
*/
async function setProfileInfo(args: ISetProfileInfoArgs): ISetProfileInfoReturn {
  try {
    const res = await axios.put('/api/user/details', args)
    if (args.defaultAvatarId) {
      // If new default avatar
      try {
        await axios.post('/api/avatar/default', args.defaultAvatarId, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
      } catch (error) {}
    }
    return res
  } catch (error: any) {
    xConsole().error(error, 'setProfileInfo')
    return error.response as AxiosResponse
  }
}

export default setProfileInfo
