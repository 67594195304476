import { View } from 'react-native'
import { Image } from '@rneui/themed'

// Store
import { useDispatch } from 'react-redux'
import { setUserToken } from '@/slices/userSlice'

// Components
import SubmitButton from './SubmitButton'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Types
import type { ILoginScreenAuth } from '@/types'
type ReadyFormProps = {
  auth: ILoginScreenAuth
}

function ReadyForm({ auth }: ReadyFormProps) {
  const dispatch = useDispatch()

  const login = async () => {
    try {
      if (!auth || !auth.token || !auth.firstName || !auth.lastName || !auth.countyId) {
        return
      }
      dispatch(setUserToken(auth.token))
    } catch (error: any) {
      xConsole().error(error, 'InstitutionsForm.tsx (login)')
    }
  }

  return (
    <>
      <View style={{ alignItems: 'center', marginBottom: 20, marginTop: -20 }}>
        <Image
          source={require('@/assets/images/login/ready.png')}
          style={{ width: '100%', height: 'auto' }}
          containerStyle={{ width: 250, aspectRatio: 1 }}
        />
      </View>
      <View>
        <SubmitButton title="SPØR OM HVA DU VIL" onPress={login} loading={false} valid={true} />
      </View>
    </>
  )
}

export default ReadyForm
