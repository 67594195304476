import React from 'react'
import { TouchableOpacity, StyleProp, ViewStyle } from 'react-native'
import { Text } from '@rneui/themed'
import * as Clipboard from 'expo-clipboard'

// Themes
import theme from '../config/themes'

// Store
import { useSelector } from 'react-redux'
import { RootState } from '@/store/rootReducer'

type CopyDataButtonProps = {
  data: any
  text?: string
  containerStyle?: StyleProp<ViewStyle>
}

const CopyDataButton = ({ data, text = '(Copy data)', containerStyle }: CopyDataButtonProps) => {
  const { isDevMode } = useSelector((state: RootState) => state.user)

  const onPress = async () => {
    let output = data
    if (typeof data === 'object') {
      output = JSON.stringify({ data })
    }
    await Clipboard.setStringAsync(output)
  }
  if (!isDevMode) {
    return null
  }
  return (
    <TouchableOpacity style={containerStyle} onPress={onPress}>
      <Text style={{ color: theme.colors.blue, fontSize: 10 }}>{text}</Text>
    </TouchableOpacity>
  )
}

export default CopyDataButton
