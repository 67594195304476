import { StyleSheet, View, ActivityIndicator, Platform } from 'react-native'
import { Text } from '@rneui/themed'

// Themes
import theme from '@/config/themes'

type ChatEmptyProps = {
  isLoading: any
  isAnonymous?: boolean
}
function ChatEmpty({ isLoading, isAnonymous }: ChatEmptyProps) {
  return (
    <View style={styles.mainContainer}>
      <Text style={[styles.text, { color: !isAnonymous ? '#666666' : '#cecece' }]}>
        Vil du dele personlig informasjon om deg selv, avtal et møte med meg. Gamle meldinger slettes automatisk etter 180 dager.
      </Text>
      {isLoading && <ActivityIndicator color={theme.colors.black} />}
    </View>
  )
}

export default ChatEmpty

const styles = StyleSheet.create({
  mainContainer: {
    transform: Platform.OS === 'android' ? [{ rotate: '180deg' }] : [{ scaleY: -1 }],
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: { paddingVertical: 20, paddingHorizontal: 40, fontSize: 18, textAlign: 'center' },
})
