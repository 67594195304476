import axios, { AxiosResponse } from 'axios'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

/**
 * Get all institutions by Axios with token by default
 */
async function getInstitutions(): Promise<AxiosResponse> {
  try {
    const res = await axios.get('/api/institution/list?per_page=1000&page=1&isActive=true')
    return res
  } catch (error: any) {
    xConsole().error(error, 'getInstitutions')
    return error.response as AxiosResponse
  }
}

export default getInstitutions
