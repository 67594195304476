import { useState } from 'react'
import { Platform, StyleSheet, View } from 'react-native'
import { Input, Icon } from '@rneui/themed'

// Store
import { useDispatch } from 'react-redux'
import { setUserToken } from '@/slices/userSlice'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// APIs
import acceptTermsConditions from '@/api/auth/acceptTermsConditions'

// Auth
import { useAuth } from '@/contexts/auth'

// Theme
import theme from '@/config/themes'

// Components
import SubmitButton from '@/components/login/SubmitButton'

// Types
import { ILoginScreenAuth } from '@/types'
type PasswordFormProps = {
  auth: ILoginScreenAuth | null
}

function PasswordForm({ auth }: PasswordFormProps) {
  const _auth = useAuth()
  const dispatch = useDispatch()
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  // Validation
  const passwordRegex = new RegExp('.{5,}$')
  const onChangeText = (password: string): void => {
    setPassword(password)
    setIsValid(!!password && passwordRegex.test(password))
  }

  // Submit
  const onSubmit = async (): Promise<void> => {
    toast.hideAll()
    if (!isValid) {
      toast.show('Skriv inn riktig passord for å logge inn.', { type: 'main', data: { title: 'Obs!', icon: 'error' } })
      return
    }

    try {
      setIsLoading(true)
      if (!auth || !auth.verifyCode) {
        setIsLoading(false)
        toast.show('Beklager, noe gikk galt.', {
          type: 'main',
          data: {
            title: 'Obs!',
            icon: 'error',
          },
        })
        return
      }

      const res = await _auth.getToken({
        phoneNumber: auth.phoneNumber,
        confirmCode: auth.verifyCode,
        requestId: auth.requestId,
        password: password,
      })
      if (res.status === 200) {
        setIsLoading(false)
        auth.token = res.data
        dispatch(setUserToken(res.data))
        acceptTermsConditions({ accept: true, accessToken: auth.token?.access_token })
      } else if (res.data?.error === 'access_denied') {
        setIsLoading(false)
        toast.show('Feil passord prøv igjen.', { type: 'main', data: { title: 'Obs!', icon: 'error' } })
      } else {
        setIsLoading(false)
        toast.show('Beklager, noe gikk galt.', { type: 'main', data: { title: 'Obs!', icon: 'error' } })
      }
    } catch (error: any) {
      xConsole().error(error as Error, 'PasswordForm.tsx (onSubmit)')
      toast.show(error.message, {
        type: 'main',
        data: {
          title: 'Obs!',
          icon: 'error',
        },
      })
    }
  }
  return (
    <View>
      <Input
        placeholder="Fyll inn passord"
        secureTextEntry={!showPassword}
        containerStyle={styles.inputContainer}
        inputStyle={[styles.input, { textAlign: 'center' }]}
        inputContainerStyle={{ borderBottomWidth: 0 }}
        placeholderTextColor={theme.colors.black}
        onChangeText={onChangeText}
        onSubmitEditing={onSubmit}
        disabledInputStyle={{ opacity: 1 }}
        disabled={isLoading}
        returnKeyType={Platform.OS === 'ios' ? 'done' : 'next'}
        rightIcon={
          <Icon
            type="ionicon"
            name={showPassword ? 'eye-outline' : 'eye-off-outline'}
            size={24}
            color="black"
            onPress={() => {
              setShowPassword((prev) => !prev)
            }}
            pressableProps={{ style: { paddingHorizontal: 20, height: '100%', justifyContent: 'center' } }}
          />
        }
        rightIconContainerStyle={{
          position: 'absolute',
          right: 0,
        }}
      />
      <SubmitButton
        title="LOGG INN"
        onPress={async () => {
          onSubmit()
        }}
        loading={isLoading}
        valid={isValid}
      />
    </View>
  )
}

export default PasswordForm

const styles = StyleSheet.create({
  inputContainer: {
    height: 55,
    paddingHorizontal: 0,
  },
  input: {
    height: 55,
    fontSize: 18,
    textAlign: 'center',
    borderWidth: 1.5,
    borderColor: theme.colors.black,
    borderRadius: 30,
  },
})
