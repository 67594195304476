import React, { useEffect, useCallback } from 'react'
import { View, ActivityIndicator } from 'react-native'
import { FlatList } from 'react-native-gesture-handler'

// Store
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/store/rootReducer'
import { fetchInstitutionHelpers } from '@/slices/institutionsSlice'

// Components
import ShareContactsListItem from '@/components/chat/actions/shareContacts/Item'

// Theme
import theme from '@/config/themes'

// Types
import type { IChannel } from '@/types'
type ShareContactsListProps = {
  trigger: Function
  participants: IChannel['participants']
}

const ShareContactsList = ({ trigger, participants }: ShareContactsListProps) => {
  const dispatch = useDispatch()

  // State:
  const { helpers } = useSelector((state: RootState) => state.institutions)

  const updateInstitutions = async () => {
    dispatch(fetchInstitutionHelpers())
  }

  useEffect(() => {
    updateInstitutions()
  }, [])

  const renderItem = useCallback(
    ({ item }) => <ShareContactsListItem item={item} trigger={trigger} disabled={participants.some((v) => v.userId === item.props.userId)} />,
    []
  )

  return (
    <>
      {helpers.length ? (
        <View style={{ flex: 1 }}>
          <FlatList
            data={helpers}
            keyExtractor={(item: any, index: any) => index}
            renderItem={renderItem}
            style={{ minHeight: 1 }} // Hacks scroll height problem on Web
            contentContainerStyle={{ paddingBottom: 65 }}
          />
        </View>
      ) : (
        <View style={{ flex: 1, height: '100%', justifyContent: 'center' }}>
          <ActivityIndicator color={theme.colors.black} />
        </View>
      )}
    </>
  )
}

export default ShareContactsList
