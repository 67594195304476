import { SafeAreaView } from 'react-native-safe-area-context'
import { Text } from '@rneui/themed'
import TabMenus from '@/components/TabMenus'
import { View } from 'react-native'

// Components
import TopNavbar from '@/components/contents/main/TopNavbar'
import FeaturedImage from '@/components/contents/main/FeaturedImage'
import ArticleTitle from '@/components/contents/main/ArticleTitle'
import QueryContents from '@/components/contents/main/Query'

// Types
import type { NativeStackScreenProps } from '@react-navigation/native-stack'
import type { RootNavigatorParamList } from '@/types'
type ContentCategoryProps = NativeStackScreenProps<RootNavigatorParamList, 'ContentCategoryScreen'>

let ContentCategoryScreen = (props: ContentCategoryProps) => {
  const categoryName = props.route?.params?.name

  const renderHeader = (
    <View style={{ marginBottom: 20 }}>
      <FeaturedImage src={''} />
      <ArticleTitle title={categoryName} />
      <Text style={{ paddingHorizontal: 20, fontSize: 20 }}>Her kan du bestille og betale for mat i skolens kantine.</Text>
    </View>
  )

  return (
    <SafeAreaView edges={['bottom', 'left', 'right']} style={{ flexDirection: 'column', flex: 1 }}>
      <TopNavbar goBack />
      <QueryContents categories={[categoryName]} renderHeader={renderHeader} />
      <TabMenus />
    </SafeAreaView>
  )
}

export default ContentCategoryScreen
