import { useState, useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import Avatar from '@/components/Avatar'

// APIs
import getDefaultAvatars from '@/api/auth/getDefaultAvatars'

// Themes
import theme from '@/config/themes'

// Types
type SettingsAvatarListProps = {
  onChange: (avatarId: string) => void
  newDefaultAvatarId: string
}
type AvatarItem = {
  id: string
}

const SettingsAvatarList = ({ onChange, newDefaultAvatarId }: SettingsAvatarListProps) => {
  const [avatars, setAvatars] = useState<AvatarItem[]>([])

  useEffect(() => {
    getDefaultAvatars().then(({ data }) => setAvatars(data))
  }, [])

  return (
    <View style={styles.mainContainer}>
      {(avatars.length ? avatars : []).map((avatar) => (
        <Avatar
          size={60}
          key={avatar.id}
          avatarId={avatar.id}
          props={{
            avatarStyle: newDefaultAvatarId === avatar.id ? styles.itemContainerSelected : {},
            containerStyle: styles.itemContainer,
            onPress: () => {
              onChange(newDefaultAvatarId !== avatar.id ? avatar.id : '')
            },
          }}
        />
      ))}
    </View>
  )
}

export default SettingsAvatarList

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: 'row',
    alignContent: 'space-around',
    flexWrap: 'wrap',
  },
  itemContainer: {
    margin: 5,
  },
  itemContainerSelected: {
    borderWidth: 5,
    borderColor: theme.colors.primary,
  },
})
