import { useState, useEffect, useCallback } from 'react'
import { View, RefreshControl, ActivityIndicator, Dimensions } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { ScrollView } from 'react-native-gesture-handler'

import TabMenus from '@/components/TabMenus'

// APIs
import getApiPageById from '@/api/contents/getPageById'

// Components
import TopNavbar from '@/components/contents/main/TopNavbar'
import FeaturedImage from '@/components/contents/main/FeaturedImage'
import FeaturedYoutube from '@/components/contents/main/FeaturedYoutube'
import ArticleTitle from '@/components/contents/main/ArticleTitle'
import ArticleContent from '@/components/contents/main/ArticleContent'

import { useAppInsights } from '@/contexts/appInsights'

// Theme
import theme from '@/config/themes'

// Variables
const { height } = Dimensions.get('window')

// Types
import type { NativeStackScreenProps } from '@react-navigation/native-stack'
import type { RootNavigatorParamList } from '@/types'
type ContentPageProps = NativeStackScreenProps<RootNavigatorParamList, 'ContentPageScreen'>

const ContentPageScreen = ({ route }: ContentPageProps) => {
  const { id } = route.params
  const { appInsights } = useAppInsights()
  const [isLoading, setIsLoading] = useState(false)
  const [isRefreshing, setIsRefreshing] = useState(false)
  const [content, setContent] = useState<Record<string, any>>({})

  useEffect(() => {
    getContent()
  }, [])

  const getContent = useCallback(async () => {
    try {
      setIsLoading(true)
      const res = await getApiPageById(id)
      setContent(res.data)
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }, [id])

  useEffect(() => {
    try {
      let initializer: any = null
      if (content.title) {
        initializer = appInsights.addTelemetryInitializer((envelope) => {
          if (envelope.baseType === 'PageviewData' && envelope.baseData?.name === 'ContentPageScreen') {
            envelope.baseData.uri = `kopling://content/${content.title.toLowerCase().replace(/ /g, '-')}`
          }
        })
      }
      return () => {
        if (initializer && initializer.remove) {
          initializer.remove()
        }
      }
    } catch {}
  }, [content])

  const refreshContent = useCallback(async () => {
    try {
      setIsRefreshing(true)
      const res = await getApiPageById(id)
      await new Promise((r) => setTimeout(r, 1000))
      setContent(res.data)
    } catch (error) {
    } finally {
      setIsRefreshing(false)
    }
  }, [id])

  return (
    <SafeAreaView edges={['bottom', 'left', 'right']} style={{ flexDirection: 'column', flex: 1 }}>
      <TopNavbar goBack />

      <ScrollView refreshControl={<RefreshControl refreshing={isRefreshing} onRefresh={refreshContent} />}>
        {!isLoading ? (
          <>
            <FeaturedImage
              src={content.featuredImage && content.featuredImage.length ? content.featuredImage[0].url : ''}
              hasVideo={!!content.featuredYoutube}
            />
            {!!content.featuredYoutube && <FeaturedYoutube id={content.featuredYoutube} />}
            {!!content.title && <ArticleTitle title={content.title} />}
            {!!content.content && <ArticleContent content={content.content} />}
          </>
        ) : (
          <View style={{ justifyContent: 'center', flex: 1, height: height }}>
            <ActivityIndicator style={{ margin: 10 }} color={theme.colors.black} />
          </View>
        )}
      </ScrollView>

      <TabMenus />
    </SafeAreaView>
  )
}

export default ContentPageScreen
