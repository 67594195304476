import React from 'react'
import { StyleSheet, View } from 'react-native'
import { Text, Button, ListItem } from '@rneui/themed'
import { useBottomSheetModal } from '@gorhom/bottom-sheet'

// Helpers
import checkUsersAlreadyConnected from '@/plugins/helpers/checkUsersAlreadyConnected'

// Store
import { useSelector } from 'react-redux'
import { RootState } from '@/store/rootReducer'

// Navigation
import { useNavigation, NavigationProp } from '@react-navigation/native'

// Components
import Avatar from '@/components/Avatar'

// Theme
import theme from '@/config/themes'

// Types
import type { IUser, IUserOther, RootNavigatorParamList, IChannel } from '@/types'
import { Routes } from '@/config/routes'

type ContactProps = {
  item: IUserOther
}

const ContactProfile = ({ item }: ContactProps) => {
  const navigation = useNavigation<NavigationProp<RootNavigatorParamList>>()
  const { dismissAll } = useBottomSheetModal()

  const { user } = useSelector((state: RootState) => state.user)
  if (!user) {
    return
  }
  const { channels } = useSelector((state: RootState) => state.channels)

  /* ========= Start chat ======== */
  const startChat = (isAnonymous: boolean) => {
    const channelExists = checkUsersAlreadyConnected(item.userId, isAnonymous, channels)
    if (channelExists) {
      // Go to old channel already exists
      navigation.navigate(Routes.Chat, {
        item: channelExists,
      })
    } else {
      // Go to new channel for create new one
      navigation.navigate(Routes.Chat, {
        item: {
          topic: item.displayName,
          topicDescription: item.title,
          topicAvatarId: item.avatarId,
          availability: item.availability,
          isAnonymous: isAnonymous,
        } as IChannel,
        create: item.userId,
        reference: { institution: item.institutionId },
      })
    }
    dismissAll()
  }
  return (
    <View style={styles.mainContainer}>
      <View>
        <ListItem containerStyle={styles.itemListContainer}>
          <Avatar avatarId={item.avatarId} placeholderTitle={item.displayName} availability={item.availability} />
          <ListItem.Content style={styles.itemListContent}>
            <ListItem.Title>{item.displayName}</ListItem.Title>
            <ListItem.Subtitle>{item.title}</ListItem.Subtitle>
          </ListItem.Content>
        </ListItem>
        <Text style={styles.description} numberOfLines={4}>
          {item.description}
        </Text>
      </View>
      {user.userId !== item.userId && (
        <View style={{ marginBottom: 40 }}>
          <Button
            title={'START DIALOG SOM ANONYM'}
            containerStyle={styles.buttonContainer}
            buttonStyle={[theme._.ButtonStyle.Large, { backgroundColor: theme.colors.black }]}
            onPress={() => {
              startChat(true)
            }}
          />
          <Button
            title={'START DIALOG'}
            containerStyle={styles.buttonContainer}
            buttonStyle={[theme._.ButtonStyle.Large]}
            onPress={() => {
              startChat(false)
            }}
          />
        </View>
      )}
    </View>
  )
}

export default ContactProfile

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    flexDirection: 'column',
    flexGrow: 1,
    justifyContent: 'space-between',
  },
  itemListContainer: {
    justifyContent: 'center',
    paddingTop: 0,
  },
  itemListContent: {
    flex: 0,
    minWidth: 150,
  },
  description: {
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 20,
  },
  buttonContainer: {
    marginVertical: 5,
  },
})
