import { StyleSheet, View } from 'react-native'
import { Icon, Text } from '@rneui/themed'
import { isSameUser, isSameDay } from 'react-native-gifted-chat'

// Themes
import theme from '@/config/themes'

// Types
import type { BubbleProps, IMessage } from 'react-native-gifted-chat'
type ChatTicksProps = BubbleProps<IMessage> & {
  isAnonymous: boolean
}

function ChatTicks(props: ChatTicksProps) {
  const { isAnonymous } = props

  return (
    <View style={styles.mainContainer}>
      <Text style={[styles.text, { color: !isAnonymous ? theme.colors.black : theme.colors.white }]}>
        Lest
        <Icon
          type="antdesign"
          name="check"
          size={12}
          style={{ marginTop: 3, marginLeft: 4 }}
          color={!isAnonymous ? theme.colors.black : theme.colors.white}
        />
      </Text>
    </View>
  )
}

export default ChatTicks

const styles = StyleSheet.create({
  mainContainer: {
    position: 'absolute',
    left: -52,
    top: -18,
  },
  text: {
    fontFamily: 'FSJoeyMedium',
    fontSize: 12,
    lineHeight: 12,
  },
})
