import React, { useState } from 'react'
import { View } from 'react-native'
import { Button, Overlay, Text, Icon } from '@rneui/themed'

// Auth
import { useAuth } from '@/contexts/auth'

// Themes
import theme from '@/config/themes'

const SettingsLogout = () => {
  const auth = useAuth()
  const [showConfirmLogout, setShowConfirmLogout] = useState(false)

  return (
    <>
      <Button
        containerStyle={{
          width: 80,
        }}
        title="Logg ut"
        type="clear"
        onPress={() => {
          setShowConfirmLogout(true)
        }}
      />
      <Overlay
        isVisible={showConfirmLogout}
        onBackdropPress={() => setShowConfirmLogout(false)}
        overlayStyle={{
          alignItems: 'center',
          paddingTop: 50,
          paddingBottom: 20,
          paddingHorizontal: 40,
        }}
      >
        <Icon name="log-out" type="feather" color={theme.colors.black} size={60} />
        <Text h4 style={{ marginVertical: 15, textAlign: 'center' }}>
          Er du sikker på at du vil logge ut?
        </Text>
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Button
            title="Ja, logg ut"
            onPress={async () => {
              const isLoggedOut = await auth.logout()
              if (!isLoggedOut) {
                toast.show('Beklager, noe gikk galt.', { type: 'main', data: { title: 'Obs!', icon: 'error' } })
              }
            }}
            type="clear"
            buttonStyle={{ width: 100 }}
            titleStyle={{ color: theme.colors.black, fontFamily: 'FSJoeyMedium' }}
          />
          <Button
            title="Avbryt"
            onPress={() => {
              setShowConfirmLogout(false)
            }}
            type="clear"
            buttonStyle={{ width: 100 }}
            titleStyle={{ color: theme.colors.black, fontFamily: 'FSJoeyMedium' }}
          />
        </View>
      </Overlay>
    </>
  )
}

export default SettingsLogout
