import { useEffect, useState, useRef } from 'react'
import { Input, Icon } from '@rneui/themed'
import { Platform, StyleSheet, View, TextInput } from 'react-native'
import axios from 'axios'

// Auth
import { useAuth } from '@/contexts/auth'

// Theme
import theme from '@/config/themes'

// Components
import SubmitButton from '@/components/login/SubmitButton'

// Types
import type { ILoginScreenAuth } from '@/types'
import type { Input as BaseInput } from '@rneui/base'
type NewPasswordFormProps = {
  onSuccess: (auth: ILoginScreenAuth) => void
  auth: ILoginScreenAuth | null
}

let tempPassword = ''

function NewPasswordForm({ onSuccess, auth }: NewPasswordFormProps) {
  const _auth = useAuth()
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isPasswordValid, setIsPasswordValid] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const passwordInputRef = useRef<TextInput & BaseInput>(null)
  const confirmPasswordInputRef = useRef<TextInput & BaseInput>(null)
  const isPasswordAlreadySet = !!auth?.token

  useEffect(() => {
    if (auth && auth.token && tempPassword) {
      passwordInputRef.current?.setNativeProps({ text: tempPassword })
      confirmPasswordInputRef.current?.setNativeProps({ text: tempPassword })
    }
  }, [auth])

  // Validation
  const passwordRegex = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/)
  const onChangePassword = (s: string): void => {
    setPassword(s)
    setIsPasswordValid(!!s && passwordRegex.test(s))
  }
  const onChangeConfirmPassword = (s: string): void => {
    setConfirmPassword(s)
    setIsConfirmPasswordValid(s === password)
  }

  // Submit
  const onSubmit = async () => {
    toast.hideAll()
    if (!isPasswordValid) {
      toast.show('Passordene stemmer ikke overens.', { type: 'main', data: { title: 'Obs!', icon: 'error' } })
      return
    }
    if (!isConfirmPasswordValid) {
      toast.show('Gjenta passord er ikke like.', { type: 'main', data: { title: 'Obs!', icon: 'error' } })
      return
    }

    if (!auth || !auth.verifyCode) {
      return
    }

    setIsLoading(true)
    const res = await _auth.getToken({
      phoneNumber: auth.phoneNumber,
      confirmCode: auth.verifyCode,
      requestId: auth.requestId,
      password: password,
    })
    setIsLoading(false)

    if (res.status === 200) {
      auth.token = res.data
      axios.defaults.headers.common['Authorization'] = auth.token?.access_token ? `Bearer ${auth.token.access_token}` : ''
      await axios.put('/api/user/details', { firstName: auth.firstName, lastName: auth.lastName })
      onSuccess(auth)
      tempPassword = password
    } else if (res.data?.error === 'access_denied') {
      toast.show('Feil passord prøv igjen.', { type: 'main', data: { title: 'Obs!', icon: 'error' } })
    } else {
      toast.show('Beklager, noe gikk galt.', { type: 'main', data: { title: 'Obs!', icon: 'error' } })
    }
  }

  return (
    <View>
      <Input
        ref={passwordInputRef}
        placeholder="Fyll inn passord"
        secureTextEntry={!showPassword}
        containerStyle={styles.inputContainer}
        inputStyle={[styles.input, { textAlign: 'center' }]}
        inputContainerStyle={{ borderBottomWidth: 0 }}
        placeholderTextColor={theme.colors.black}
        onChangeText={onChangePassword}
        onSubmitEditing={onSubmit}
        returnKeyType={Platform.OS === 'ios' ? 'done' : 'next'}
        rightIcon={
          <Icon
            type="ionicon"
            name={showPassword ? 'eye-outline' : 'eye-off-outline'}
            size={24}
            color="black"
            onPress={() => {
              setShowPassword((prev) => !prev)
            }}
            pressableProps={{ style: { paddingHorizontal: 20, height: '100%', justifyContent: 'center' } }}
          />
        }
        rightIconContainerStyle={{
          position: 'absolute',
          right: 0,
        }}
        disabled={isPasswordAlreadySet}
      />

      <Input
        ref={confirmPasswordInputRef}
        placeholder="Gjenta passord"
        secureTextEntry={!showPassword}
        containerStyle={styles.inputContainer}
        inputStyle={[styles.input, { textAlign: 'center' }]}
        inputContainerStyle={{ borderBottomWidth: 0 }}
        placeholderTextColor={theme.colors.black}
        onChangeText={onChangeConfirmPassword}
        onSubmitEditing={onSubmit}
        returnKeyType={Platform.OS === 'ios' ? 'done' : 'next'}
        disabled={isPasswordAlreadySet}
      />
      {!isPasswordAlreadySet ? (
        <SubmitButton title="BEKREFT PASSORD" onPress={onSubmit} loading={isLoading} valid={isPasswordValid && isConfirmPasswordValid} />
      ) : (
        <SubmitButton title="GÅ VIDERE" onPress={() => onSuccess(auth)} loading={false} valid={true} />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  inputContainer: {
    height: 55,
    paddingHorizontal: 0,
    marginBottom: 15,
  },
  input: {
    height: 55,
    fontSize: 18,
    textAlign: 'center',
    borderWidth: 1.5,
    borderColor: theme.colors.black,
    borderRadius: 30,
  },
})

export default NewPasswordForm
