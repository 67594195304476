import axios, { AxiosResponse } from 'axios'
import queryString from 'query-string'
import { koplingConfig } from '@/config'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Types
export type IGetPageByIdReturn = Promise<AxiosResponse>

/*
  Get Squidex "Page" by id
*/
async function getPageById(id: string): IGetPageByIdReturn {
  try {
    const res = await axios({
      url: `${koplingConfig.contentApiUrl}/api/content/app/graphql`,
      method: 'post',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      data: queryString.stringify({
        query: `{findPagesContent(id: "${id}") {
          id,
          flatData {
            title,
            content,
            excerpt,
            featuredImage { url },
            featuredYoutube
          }
        }}`,
      }),
    })
    if (!res.data?.data?.findPagesContent?.flatData) {
      throw new Error('NOT_FOUND')
    }

    res.data = res.data.data.findPagesContent.flatData

    return res
  } catch (error: any) {
    xConsole().error(error, 'getPageById')
    if (error instanceof Error) {
      throw { response: { status: 400 } }
    }
    return error.response as AxiosResponse
  }
}

export default getPageById
