import { useState, useEffect, useCallback } from 'react'
import { StyleSheet, View, TouchableOpacity } from 'react-native'
import { Image, Text, Badge } from '@rneui/themed'
import { useNavigation, NavigationProp, useRoute } from '@react-navigation/native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import AsyncStorage from '@react-native-async-storage/async-storage'

// APIs
import { chatApi } from '@/api/chatApi'

// Types
import { RootNavigatorParamList } from '../types'
import { Routes } from '../config/routes'

type ITabList = {
  label: string
  icon: string
  iconActive: string
  destination: Routes
  destinationParams?: object
}

const tabList: ITabList[] = [
  {
    label: 'TJENESTER',
    icon: require('../assets/images/tabmenus/contents.png'),
    iconActive: require('../assets/images/tabmenus/contents-active.png'),
    destination: Routes.Contents,
  },
  {
    label: 'MELDINGER',
    icon: require('../assets/images/tabmenus/chat.png'),
    iconActive: require('../assets/images/tabmenus/chat-active.png'),
    destination: Routes.Channels,
  },
  {
    label: 'HJELPERE',
    icon: require('../assets/images/tabmenus/organizations.png'),
    iconActive: require('../assets/images/tabmenus/organizations-active.png'),
    destination: Routes.Contacts,
    destinationParams: { screen: Routes.InstitutionContacts },
  },
  {
    label: 'PROFIL',
    icon: require('../assets/images/tabmenus/profile.png'),
    iconActive: require('../assets/images/tabmenus/profile-active.png'),
    destination: Routes.Settings,
  },
]

const TabMenus = () => {
  const { navigate } = useNavigation<NavigationProp<RootNavigatorParamList>>()
  const route = useRoute()
  const insets = useSafeAreaInsets()
  const [totalUnread, setTotalUnread] = useState(0)

  const { data: totalUnreadData } = chatApi.endpoints.getTotalUnreadChannels.useQuery(undefined, {
    pollingInterval: 1000,
    skip: false,
    refetchOnMountOrArgChange: true,
  })

  useEffect(() => {
    try {
      if (totalUnreadData !== undefined) {
        setTotalUnread(totalUnreadData)
        AsyncStorage.setItem('totalUnreadChannels', String(totalUnreadData))
      } else {
        AsyncStorage.getItem('totalUnreadChannels').then((res) => {
          if (res) setTotalUnread(parseInt(res))
        })
      }
    } catch {}
  }, [totalUnreadData])

  const onTabPress = useCallback(
    (item: ITabList) => {
      navigate(item.destination, item.destinationParams ?? {})
    },
    [navigate]
  )

  return (
    <View style={[styles.mainContainer, { marginBottom: -insets.bottom, paddingBottom: insets.bottom }]}>
      <View style={styles.tabContainer}>
        {tabList.map((item: any, i: number) => (
          <TouchableOpacity style={[styles.tabItem]} onPress={() => onTabPress(item)} key={i}>
            <Image
              source={
                route.name === item.destination ||
                (route?.name?.includes('Settings') && item.destination === Routes.Settings) ||
                (route?.name.includes('Content') && item.destination === Routes.Contents) ||
                (route?.name === 'FAQScreen' && item.destination === Routes.Contents)
                  ? item.iconActive
                  : item.icon
              }
              style={styles.tabIcon}
              placeholderStyle={{ backgroundColor: 'transparent' }}
            />
            {item.label === 'MELDINGER' && !!totalUnread && (
              <Badge status="primary" containerStyle={{ position: 'absolute', top: 5, left: '55%' }} value={totalUnread} />
            )}
            <Text style={styles.tabLabel}>{item.label}</Text>
          </TouchableOpacity>
        ))}
      </View>
    </View>
  )
}

export default TabMenus

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: '#e8e5df',
  },
  tabContainer: {
    flexDirection: 'row',
    height: 62, // to know the height
  },
  tabItem: {
    paddingVertical: 7,
    flexGrow: 1,
    flexBasis: 0,
    alignItems: 'center',
  },
  tabIcon: {
    aspectRatio: 1,
    width: 32,
    height: 32,
  },
  tabLabel: {
    fontFamily: 'FSJoeyMedium',
    fontSize: 11,
  },
})
