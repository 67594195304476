const themes = {
  colors: {
    primary: '#e94e24',
    secondary: '#ed762a',
    warning: '#ed762a', // dup
    success: '#0ba789',
    error: '#ff3b30',
    white: '#ffffff',
    light: '#dddddd',
    grey0: '#464646',
    grey1: '#505050',
    grey2: '#787878',
    grey3: '#a0a0a0',
    grey4: '#c8c8c8',
    black: '#2b2c2b',
    green: '#C8E6CA',
    blue: '#4D7FB2',
    bluelight: '#badfe5',
    teal: '#008093',
    bodybg: '#F2EFEA',
    divider: '#e1deda',
  },
  components: {
    Button: {
      buttonStyle: {
        borderRadius: 125,
        paddingVertical: 15,
      },
      titleStyle: {
        fontSize: 16,
        textAlignVertical: 'center',
        fontFamily: 'FSJoeyMedium',
        lineHeight: 16,
      },
    },
    Text: {
      style: {
        fontFamily: 'FSJoey',
        fontSize: 16,
      },
      h1Style: {
        fontFamily: 'FSJoeyBold',
        fontWeight: 'normal',
        fontSize: 45,
        lineHeight: 49,
      },
      h2Style: {
        fontFamily: 'FSJoeyBold',
        fontWeight: 'normal',
        fontSize: 45,
        lineHeight: 45,
      },
      h3Style: {
        fontFamily: 'FSJoey',
        fontWeight: 'normal',
        fontSize: 30,
        lineHeight: 33,
      },
      h4Style: {
        fontFamily: 'FSJoeyMedium',
        fontWeight: 'normal',
        fontSize: 25,
        lineHeight: 27,
      },
    },
    ListItemTitle: {
      style: {
        fontFamily: 'FSJoeyMedium',
        fontSize: 18,
      },
    },
    ListItemSubtitle: {
      style: {
        fontFamily: 'FSJoey',
        fontSize: 16,
        color: '#666666',
      },
    },
    Overlay: {
      overlayStyle: {
        margin: 20,
        borderRadius: 20,
        padding: 20,
        maxWidth: 500,
      },
    },
    Badge: {
      textStyle: {
        fontSize: 10,
        fontFamily: 'FSJoey',
      },
    },
    Input: {
      inputStyle: {
        fontFamily: 'FSJoey',
      },
    },
    SearchBar: {
      containerStyle: {
        margin: 0,
        padding: 0,
        borderTopWidth: 0,
        borderBottomWidth: 0,
        minHeight: 36,
        backgroundColor: 'transparent',
      },
      inputContainerStyle: {
        paddingLeft: 5,
        height: 36,
        backgroundColor: '#ffffff',
        borderRadius: 20,
      },
      inputStyle: {
        fontFamily: 'FSJoey',
        fontSize: 16,
      },
    },
  },
  _: {
    Container: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: 15,
    },
    ButtonStyle: {
      Large: {
        minHeight: 54,
        borderRadius: 30,
      },
      Clear: {
        borderRadius: 0,
        paddingVertical: 5,
      },
    },
    MarkdownStyle: {
      // https://github.com/mientjan/react-native-markdown-renderer/blob/master/src/lib/styles.js
      heading1: {
        fontFamily: 'FSJoeyBold',
        fontWeight: 'normal',
        fontSize: 45,
        lineHeight: 45,
        color: '#2b2c2b',
      },
      heading2: {
        fontFamily: 'FSJoey',
        fontWeight: 'normal',
        fontSize: 30,
        lineHeight: 33,
        color: '#2b2c2b',
      },
      heading3: {
        fontFamily: 'FSJoeyMedium',
        fontWeight: 'normal',
        fontSize: 25,
        lineHeight: 27,
        color: '#2b2c2b',
      },
      text: {
        fontFamily: 'FSJoey',
        fontSize: 16,
        color: '#2b2c2b',
      },
      strong: {
        fontFamily: 'FSJoeyMedium',
      },
      em: {
        opacity: 0.75,
      },
      heading: {},
      hr: {
        backgroundColor: '#e1deda',
        height: 1,
        marginVertical: 20,
      },
      link: {
        color: '#e94e24',
        textDecorationLine: 'underline',
      },
    },
  },
}
export default themes
