import axios, { AxiosResponse } from 'axios'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Types
export type IGetDefaultAvatarsReturn = Promise<AxiosResponse>

/*
  Get default avatars list
  Example usage: In setting page, change user's avatar
*/
async function getGetDefaultAvatars(): IGetDefaultAvatarsReturn {
  try {
    const res = await axios.get('/api/avatar/list')
    return res
  } catch (error: any) {
    xConsole().error(error, 'getGetDefaultAvatars')
    return error.response as AxiosResponse
  }
}

export default getGetDefaultAvatars
