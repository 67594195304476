import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { View, ScrollView } from 'react-native'
import { Text, Button, Overlay } from '@rneui/themed'

// Components
import Privacypolicy from '@/components/contents/Privacypolicy'

// Theme
import theme from '../config/themes'

type TermsOfServiceProps = {
  testID: string
  onAgreed: () => void
}

const TermsOfService = forwardRef(({ testID, onAgreed }: TermsOfServiceProps, ref) => {
  const [isActive, setIsActive] = useState(false)

  useImperativeHandle(ref, () => ({
    setIsActive,
  }))

  return (
    <Overlay testID={testID} isVisible={isActive} onBackdropPress={() => setIsActive(false)} overlayStyle={{ maxHeight: '75%' }}>
      <Text h4 style={{ margin: 5, marginTop: 20, marginBottom: 5 }}>
        Personvernerklæring
      </Text>
      <ScrollView>
        <Privacypolicy />
      </ScrollView>
      <View
        style={{
          flexDirection: 'row-reverse',
          alignItems: 'center',
          marginTop: 20,
        }}
      >
        <Button
          title="GODTA VILKÅRENE"
          onPress={() => {
            setIsActive(false)
            onAgreed()
          }}
          buttonStyle={[
            {
              width: 160,
              paddingVertical: 10,
              backgroundColor: theme.colors.white,
              borderWidth: 1,
              borderColor: theme.colors.primary,
              borderRadius: 30,
            },
          ]}
          titleStyle={{ color: theme.colors.primary }}
        />
        <Button
          title="LUKK"
          onPress={() => setIsActive(false)}
          type="clear"
          buttonStyle={[theme._.ButtonStyle.Clear, { marginRight: 20 }]}
          titleStyle={{ color: theme.colors.primary }}
        />
      </View>
    </Overlay>
  )
})

export default TermsOfService
