import React from 'react'
import { View, Linking } from 'react-native'

// Components
import ContactItem from './Item'

const list = [
  {
    title: 'Hjelpetelefonen',
    subtitle: 'Mental Helse',
    photoURL: 'https://profile.kopling.no/img/hjelpetelefonen.png',
    description:
      'Hjelpetelefonen er Mental Helses gratis døgnåpne telefontjeneste for alle som trenger noen å snakke med. Du kan være anonym og vi har taushetsplikt.',
    phoneNumber: '116 123',
  },
  {
    title: 'Hjelpetelefonen',
    subtitle: 'for seksuelt misbrukte',
    photoURL: 'https://profile.kopling.no/img/Hjelpetelefonenforseksueltmissbruke-square.png',
    description:
      'Vi hjelper seksuelt misbrukte personer, incestutsatte og deres pårørende. Du kan være anonym om du vil – vi tar deg alltid på alvor. Det er helt gratis å ringe til oss!',
    phoneNumber: '800 57 000 ',
  },
  {
    title: 'Medisinsk nødnummer',
    subtitle: '',
    photoURL: 'https://profile.kopling.no/img/medisinsk-nodnummer.png',
    description: 'Ring medisinsk nødnummer dersom du trenger akutt helsehjelp og det kan stå om liv og helse.',
    phoneNumber: '113',
  },
  {
    title: 'Legevakt',
    subtitle: '',
    photoURL: 'https://profile.kopling.no/img/legevakt.png',
    description:
      'Hvis du ringer 116 117 kommer du til nærmeste legevakt, uansett hvor du er i landet. Hit kan du ringe når fastlegen ikke er tilgjengelig og du er fysisk eller psykisk syk og trenger hjelp raskt.',
    phoneNumber: '116 117',
  },
  {
    title: 'Giftinformasjonen',
    subtitle: '',
    photoURL: 'https://profile.kopling.no/img/giftinformasjonen.png',
    description: 'Du kan ringe Giftinformasjonen dersom du har fått i deg, eller har vært i kontakt med, noe som kan være giftig.',
    phoneNumber: '22 59 13 00',
  },
]

const ListEmergencies = () => {
  const onPress = (data: any) => {
    Linking.openURL(`tel:${data.phoneNumber.replace(/\s/g, '')}`)
  }

  return (
    <View style={{ padding: 20, marginBottom: 50 }}>
      {list.map((data, index) => (
        <ContactItem key={index} item={data} onPress={() => onPress(data)} />
      ))}
    </View>
  )
}

export default ListEmergencies
