import { Platform } from 'react-native'
import { MessageText } from 'react-native-gifted-chat'

// Themes
import theme from '../../config/themes'

// Types
import type { IMessage } from '../../types'
import type { MessageTextProps } from 'react-native-gifted-chat'
type ChatMessageTextProps = MessageTextProps<IMessage>

function ChatMessageText(props: ChatMessageTextProps) {
  return (
    <MessageText
      {...props}
      linkStyle={{ left: { color: theme.colors.primary }, right: { color: theme.colors.primary } }}
      customTextStyle={Platform.OS === 'web' && { display: 'flex', marginHorizontal: 5, marginVertical: 3 }}
    />
  )
}

export default ChatMessageText
