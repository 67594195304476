/*
  Format phoneNumber to Firebase auth by phone's pattern
  Params: { phoneNumber(string)(required) }
  Return: formattedPhoneNumber(string)
*/

export const phoneNumberRegex = new RegExp('^[0-9]{8,8}$|^[+][0-9]{10,15}$')

const formatPhoneNumber = (num: string) => {
  return num.startsWith('+') ? num : `+47${num}`
}

export default formatPhoneNumber
