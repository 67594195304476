import React from 'react'
import { StyleSheet, StyleProp, ViewStyle, TextStyle } from 'react-native'
import { Button } from '@rneui/themed'
import { IconNode } from '@rneui/base/dist/Icon/Icon'

import theme from '@/config/themes'

type SubmitButtonProps = {
  title: string
  onPress: (args: any) => void
  loading: boolean
  valid: boolean
  containerStyle?: StyleProp<ViewStyle>
  buttonStyle?: StyleProp<ViewStyle>
  titleStyle?: StyleProp<TextStyle>
  icon?: IconNode
  disabled?: boolean
}
function SubmitButton({ title, onPress, loading, valid, containerStyle, buttonStyle, titleStyle, icon, disabled }: SubmitButtonProps) {
  return (
    <Button
      title={title}
      onPress={onPress}
      loading={loading}
      containerStyle={[styles.buttonContainer, containerStyle]}
      buttonStyle={[valid ? styles.buttonValid : styles.button, buttonStyle]}
      titleStyle={[styles.buttonTitle, titleStyle]}
      loadingProps={{ color: theme.colors.black }}
      testID={'SubmitButton'}
      icon={icon}
      iconContainerStyle={{ marginRight: 10 }}
      disabled={disabled}
    />
  )
}

export default SubmitButton

const styles = StyleSheet.create({
  buttonContainer: {
    marginVertical: 5,
  },
  button: {
    backgroundColor: 'transparent',
    height: 55,
    fontSize: 18,
    textAlign: 'center',
    borderWidth: 1.5,
    borderColor: theme.colors.black,
    borderRadius: 30,
  },
  buttonTitle: {
    fontSize: 18,
    color: theme.colors.black,
    lineHeight: 19, // Fix invisible "Å"
  },
  buttonValid: {
    backgroundColor: theme.colors.white,
    borderWidth: 1,
    borderColor: theme.colors.black,
    borderRadius: 30,
  },
})
