// Navigation & drawer
import { createDrawerNavigator } from '@react-navigation/drawer'

// Screens
import ChannelsScreen from '@/screens/ChannelsScreen'
import ContactsScreen from '@/screens/contacts/ContactsScreen'
import SettingsScreen from '@/screens/settings/SettingsScreen'
import ContentsScreen from '@/screens/contents/ContentsScreen'

// Types
import { RootNavigatorParamList } from '@/types'
import { Routes } from '@/config/routes'

// Variables
const Drawer = createDrawerNavigator<RootNavigatorParamList>()

const RootNavigator = () => {
  return (
    <Drawer.Navigator
      screenOptions={({ navigation }) => ({
        headerShown: false,
        swipeEnabled: false,
        drawerStyle: {
          width: 0,
        },
      })}
      drawerContent={() => <></>}
    >
      <Drawer.Screen name={Routes.Contents} component={ContentsScreen} options={{ title: 'Dine tjenester' }} />
      <Drawer.Screen name={Routes.Channels} component={ChannelsScreen} options={{ title: 'Dine meldinger' }} />
      <Drawer.Screen name={Routes.Contacts} component={ContactsScreen} options={{ title: 'Dine hjelpere' }} />
      <Drawer.Screen name={Routes.Settings} component={SettingsScreen} options={{ title: 'Innstillinger' }} />
    </Drawer.Navigator>
  )
}

export default RootNavigator
