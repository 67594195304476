import { useState, useCallback, useEffect } from 'react'
import { View, Dimensions, Pressable, Platform } from 'react-native'
import { Text, Icon } from '@rneui/themed'

// Navigation
import { useNavigation, NavigationProp } from '@react-navigation/native'
import { Routes } from '@/config/routes'

// Store
import { useSelector } from 'react-redux'
import { RootState } from '@/store/rootReducer'

// API
import { contentApi } from '@/slices/contentsSlice'

// Components
import CafeteriaWidgets from '@/components/cafeterias/Widgets'

// Themes
import theme from '@/config/themes'

// Variables
const { width } = Dimensions.get('window')

// Types
import type { RootNavigatorParamList } from '@/types'
type IWidget = {
  name: string
  metaData?: { [key: string]: any }
}

const MainWidgets = () => {
  const navigation = useNavigation<NavigationProp<RootNavigatorParamList>>()
  const { user } = useSelector((state: RootState) => state.user)
  const [data, setData] = useState<IWidget[]>([])

  const { data: userCafeteriasData, refetch } = contentApi.endpoints.userCafeterias.useQuery(undefined, {
    skip: false,
    refetchOnMountOrArgChange: true,
  })

  useEffect(() => {
    initData()
  }, [userCafeteriasData])

  useEffect(() => {
    refetch()
  }, [user?.institutions])

  const initData = useCallback(async () => {
    const widgets: IWidget[] = []
    if (userCafeteriasData && userCafeteriasData.length && userCafeteriasData[0]?.pejShopId) {
      // Cafeteria
      try {
        widgets.push({
          name: 'cafeteria',
          metaData: userCafeteriasData[0],
        })
      } catch (error) {}
    }

    // FAQs
    try {
      widgets.push({ name: 'faq' })
    } catch (error) {}

    // FAQs with Bully
    try {
      const isKopling = user?.institutions?.some((v) => v.institutionId === '20170a075f8b4f469f1d2ef57d3ec2d4')
      if (isKopling) {
        widgets.map((v) => {
          if (v.name === 'faq') {
            v.name = 'faqAndBully'
          }
        })
      }
    } catch (error) {}

    setData(widgets)
  }, [userCafeteriasData, user?.institutions])

  if (!data?.length) return <></>

  return (
    <>
      {data.map((v: any, i: number) => (
        <View style={{ width: width - 40, maxWidth: Platform.OS === 'web' ? '200%' : 'auto' }} key={i}>
          {v.name === 'faq' && (
            <Pressable
              onPress={() => navigation.navigate(Routes.FAQ)}
              style={{
                flex: 1,
                backgroundColor: theme.colors.white,
                borderRadius: 8,
                padding: 20,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: i === data.length - 1 ? 0 : 20,
              }}
            >
              <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                <Icon type="octicon" name="question" size={46} color={theme.colors.primary} style={{ marginRight: 20 }} />
                <View>
                  <Text style={{ fontFamily: 'FSJoeyMedium', fontSize: 18 }}>Hva lurer du på?</Text>
                  <Text>Spørsmål og svar</Text>
                </View>
              </View>
              <Icon type="octicon" name="arrow-right" size={24} color={theme.colors.primary} />
            </Pressable>
          )}
          {v.name === 'faqAndBully' && (
            <View style={{ flexDirection: 'row' }}>
              <Pressable
                onPress={() => navigation.navigate(Routes.FAQ)}
                style={{
                  flex: 1,
                  backgroundColor: '#2199af',
                  borderRadius: 8,
                  padding: 20,
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: i === data.length - 1 ? 0 : 20,
                  marginRight: 10,
                }}
              >
                <View style={{ alignItems: 'flex-start' }}>
                  <Icon type="octicon" name="question" size={26} color={theme.colors.white} style={{ marginRight: 20 }} />
                  <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Text style={{ fontFamily: 'FSJoeyMedium', fontSize: 18, color: theme.colors.white }}>Lurer på…</Text>
                    <Icon type="octicon" name="arrow-right" size={24} color={theme.colors.white} />
                  </View>
                </View>
              </Pressable>
              <Pressable
                onPress={() => navigation.navigate(Routes.Bully)}
                style={{
                  flex: 1,
                  backgroundColor: '#8d004b',
                  borderRadius: 8,
                  padding: 20,
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: i === data.length - 1 ? 0 : 20,
                  marginRight: 10,
                }}
              >
                <View style={{ alignItems: 'flex-start' }}>
                  <Icon type="feather" name="alert-circle" size={26} color={theme.colors.white} style={{ marginRight: 20 }} />
                  <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Text style={{ fontFamily: 'FSJoeyMedium', fontSize: 18, color: theme.colors.white }}>Mobbeknapp</Text>
                    <Icon type="octicon" name="arrow-right" size={24} color={theme.colors.white} />
                  </View>
                </View>
              </Pressable>
            </View>
          )}
          {v.name === 'cafeteria' && (
            <View style={{ marginBottom: i === data.length - 1 ? 0 : 20 }}>
              <CafeteriaWidgets metaData={v.metaData} />
            </View>
          )}
        </View>
      ))}
    </>
  )
}

export default MainWidgets
