import getApiMessages from '@/api/messages/getMessages'

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '@/store'
import { xConsole } from '@/plugins/helpers/xConsole'

// Types
import { IMessagesState, IMessage, IUserState } from '@/types'
const initialState: IMessagesState = {
  messages: {},
  isLoading: false,
  error: false,
  unsubscribe: null,
}

const messages = createSlice({
  name: 'messages',
  initialState,
  reducers: {
    getMessages(state) {
      state.isLoading = true
      state.error = false
    },
    getMessagesSuccess(state, action: PayloadAction<{ id: string; messages: Array<IMessage> }>) {
      const payload = action.payload
      state.isLoading = false
      state.messages[payload.id] = payload.messages
    },
    getMessagesFailure(state) {
      state.isLoading = false
      state.error = true
    },
    setUnsubscribe(state, action: PayloadAction<ReturnType<typeof setInterval>>) {
      state.unsubscribe = action.payload
    },
  },
})

export const { getMessages, getMessagesSuccess, getMessagesFailure, setUnsubscribe } = messages.actions

export default messages.reducer

interface IFetchMessagesArgs {
  id: string
  limit: number
  before?: string | null
}
export const fetchMessages =
  (args: IFetchMessagesArgs): AppThunk =>
  async (dispatch, getState) => {
    const defaults = {
      id: null,
      limit: 5,
      before: null,
    }
    const options = { ...defaults, ...args }
    try {
      const { user }: IUserState = getState().user
      const { unsubscribe }: IMessagesState = getState().messages
      dispatch(getMessages())
      if (unsubscribe) {
        clearInterval(unsubscribe)
      }
      if (user) {
        const _func = async () => {
          const res = await getApiMessages({ id: options.id, limit: options.limit, before: options.before })
          if (res.status === 200) {
            dispatch(getMessagesSuccess({ id: options.id, messages: res.data }))
          }
        }
        _func()
        const interval = setInterval(_func, 5000)
        dispatch(setUnsubscribe(interval))
      }
    } catch (error: any) {
      xConsole().error(error, 'messagesSlice (fetchMessages)')
      dispatch(getMessagesFailure())
    }
  }
