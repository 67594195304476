import { Platform } from 'react-native'

/**
 * Check the given string is a safe URL (domain)
 *
 * @example
 * import isDomainSafe from '@/plugins/helpers/isDomainSafe'
 * // Then use inside a function
 * isDomainSafe('https://example.com')
 */

const safeDomains = ['kopling.no', 'pej.se', 'pej.io']

function isDomainSafe(url: string) {
  let isSafe = false
  try {
    const { hostname } = new URL(url)
    const urlParts = new URL(url).hostname.split('.')
    const domain = urlParts
      .slice(0)
      .slice(-(urlParts.length === 4 ? 3 : 2))
      .join('.')
    isSafe = safeDomains.includes(domain)
  } catch {}
  return isSafe
}

export default isDomainSafe
