import { useState, useCallback, useRef, useMemo, forwardRef, useImperativeHandle } from 'react'
import { StyleSheet, View } from 'react-native'
import { Text, Button, Dialog, Image } from '@rneui/themed'
import { BottomSheetModal, BottomSheetBackdrop } from '@gorhom/bottom-sheet'

import * as WebBrowser from 'expo-web-browser'
import axios from 'axios'
import { koplingConfig } from '@/config'

// API
import { contentApi } from '@/slices/contentsSlice'
import getTokenExchange from '@/api/auth/getTokenExchange'

// Context
import { useAppInsights } from '@/contexts/appInsights'

// Store
import { useSelector } from 'react-redux'
import { RootState } from '@/store/rootReducer'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Themes
import theme from '@/config/themes'

// Types
export type ICafeteriaRegisterRef = {
  setIsActive: (bool: boolean) => void
  current: any
}
type ICafeteriaRegisterProps = {
  pejShopId: string
}

const CafeteriaRegister = forwardRef<any, ICafeteriaRegisterProps>((props, ref) => {
  const { appInsights } = useAppInsights()
  const { user } = useSelector((state: RootState) => state.user)
  const bottomSheetModalRef = useRef<BottomSheetModal>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [registerStatus, setRegisterStatus] = useState('')
  const [triggerUserMetaData] = contentApi.endpoints.editUserMetaData.useMutation()
  const snapPoints = useMemo(() => ['55%'], [])

  const { data: userCafeteriasData } = contentApi.endpoints.userCafeterias.useQuery(undefined, {
    skip: false,
    refetchOnMountOrArgChange: true,
  })

  useImperativeHandle(ref, () => ({
    setIsActive,
  }))

  const submit = useCallback(async () => {
    try {
      toast.hideAll()
      setIsLoading(true)

      const tokenExchange = await getTokenExchange()
      if (tokenExchange?.data?.access_token) {
        // Send to Pej
        const res = await axios.post(`${koplingConfig.pejApiUrl}/auth`, {
          action: 'et_register',
          token: tokenExchange.data.access_token,
          shopId: props.pejShopId,
        })

        // Save Cafeteria card data in User Meatadata
        await triggerUserMetaData({
          key: 'cafeteriaCards',
          value: {
            [props.pejShopId]: {
              createdOn: +new Date(),
              token: res.data.token,
              userId: res.data.userId,
              shopId: props.pejShopId,
            },
          },
        })

        doTracking()
        setRegisterStatus('success')
        setIsActive(false)
      }
    } catch (error: any) {
      console.log(error.response?.data)
      // Try to re-check if user is already created (by using balance endpoint)
      if (error.response?.data?.key === 'failed_user_creation') {
        try {
          const tokenExchange = await getTokenExchange()

          if (tokenExchange?.data?.access_token) {
            const res = await axios.post(`${koplingConfig.pejApiUrl}/auth`, {
              action: 'et_login',
              token: tokenExchange.data.access_token,
              shopId: props.pejShopId,
            })

            // Save Cafeteria card data in User Meatadata
            await triggerUserMetaData({
              key: 'cafeteriaCards',
              value: {
                [props.pejShopId]: {
                  createdOn: +new Date(),
                  token: res.data.token,
                  userId: res.data.userId,
                  shopId: props.pejShopId,
                },
              },
            })
          }

          setRegisterStatus('success')
          setIsActive(false)
          return false
        } catch {}
      }

      // Something wrong
      setRegisterStatus('error')
      xConsole().error(error, 'cafeterias/Register.tsx (submit)')
    } finally {
      setIsLoading(false)
    }
  }, [user, props])

  const renderBackdrop = useCallback((props) => <BottomSheetBackdrop {...props} disappearsOnIndex={-1} appearsOnIndex={0} />, [])

  const setIsActive = (isActive: boolean) => {
    if (isActive) {
      bottomSheetModalRef.current?.present()
    } else {
      bottomSheetModalRef.current?.dismiss()
    }
  }

  const doTracking = useCallback(() => {
    try {
      if (!props.pejShopId || !userCafeteriasData) return
      const shopId = props.pejShopId
      const institution = userCafeteriasData.find((x) => x.pejShopId === shopId) ?? null

      if (institution) {
        appInsights.trackEvent({
          name: 'Canteen',
          properties: {
            eventType: 'Register',
            institutionName: institution.institutionName,
            institutionId: institution.institutionId,
            shopId,
          },
        })
      }
    } catch (error) {
      console.log(error)
    }
  }, [props.pejShopId, userCafeteriasData])

  return (
    <>
      <BottomSheetModal ref={bottomSheetModalRef} index={0} snapPoints={snapPoints} backdropComponent={renderBackdrop}>
        <View style={styles.bottomSheetHeader}>
          <Button
            title="LUKK"
            onPress={() => setIsActive(false)}
            type="clear"
            buttonStyle={[theme._.ButtonStyle.Clear, { width: 75, paddingRight: 0, justifyContent: 'flex-end' }]}
            titleStyle={{ color: theme.colors.primary }}
          />
        </View>
        <View style={{ flex: 1, paddingHorizontal: 20 }}>
          <>
            <Text h4>Bestill kantinekort</Text>
            <Text style={{ fontSize: 18, marginVertical: 10 }}>
              <Text style={{ fontFamily: 'FSJoeyMedium', fontSize: 18 }}>Lei av å bruke lunsjen til å stå i kø?</Text>
              {'\n'}
              Med kantinekortet går det raskere å betale når du bestiller, du kan betale rett fra kortet. Andre, f.eks dine foreldre kan betale inn
              til kortet ditt.
              {'\n'}
              {'\n'}
              <Text style={{ fontFamily: 'FSJoeyMedium', fontSize: 18 }}>Logger bare inn en gang!</Text>
              {'\n'}
              Med kantinekort kan foresatte fylle opp penger på kortet og du kan handle raskere i kantina.
              {'\n'}
              Det betyr mindre kø og bedre tid til å spise!
            </Text>
            <View style={{ marginVertical: 10 }}>
              <Button
                title="BESTILL"
                containerStyle={styles.buttonContainer}
                buttonStyle={styles.button}
                titleStyle={styles.buttonTitle}
                loading={isLoading}
                onPress={submit}
              />
            </View>
          </>
        </View>
      </BottomSheetModal>
      <Dialog isVisible={!!registerStatus} overlayStyle={styles.dialogMainContainer}>
        {registerStatus === 'success' && (
          <>
            <Image
              source={require('@/assets/images/icons/smile.png')}
              style={styles.dialogIcon}
              containerStyle={styles.dialogIconContainer}
              placeholderStyle={styles.dialogIconPlaceholder}
            />
            <Text style={styles.dialogTitleText}>Hurra kantinekortet ditt er klart!</Text>
            <Text style={styles.dialogContentText}>
              Kantinekortet gjør det raskere og enklere å handle i kantinen.
              {'\n'}
              Husk at du må overføre penger til kortet før du går i kantinen.
              {'\n'}
              Foreldre eller andre kan betale penger direkte inn på det digitale kantinekortet ditt om du klikker på denne lenken og sender en
              forespørsel.
              {'\n'}
              {'\n'}
              Du kan be om penger fra foreldrene dine til kantinemat ved å klikke på linken her:
            </Text>
            <Text
              style={styles.dialogContentLink}
              onPress={() => {
                WebBrowser.openBrowserAsync('https://pej.io', {
                  presentationStyle: WebBrowser.WebBrowserPresentationStyle.PAGE_SHEET,
                  enableDefaultShareMenuItem: false,
                  controlsColor: theme.colors.primary,
                })
              }}
            >
              www.pej.io
            </Text>
          </>
        )}
        {registerStatus === 'error' && (
          <>
            <Image
              source={require('@/assets/images/icons/oh.png')}
              style={styles.dialogIcon}
              containerStyle={styles.dialogIconContainer}
              placeholderStyle={styles.dialogIconPlaceholder}
            />
            <Text style={styles.dialogTitleText}>Sorry, noe gikk galt</Text>
            <Text style={styles.dialogContentText}>
              Prøv igjen, det skjedde noe galt da vi skulle sette opp kontoen din. Prøv igjen om et par minutter.
              {'\n'}
              {'\n'}
              Hvis det fortsatt ikke fungerer ta kontakt med oss her:
            </Text>
            <Text
              style={styles.dialogContentLink}
              onPress={() => {
                WebBrowser.openBrowserAsync('https://kopling.no', {
                  presentationStyle: WebBrowser.WebBrowserPresentationStyle.PAGE_SHEET,
                  enableDefaultShareMenuItem: false,
                  controlsColor: theme.colors.primary,
                })
              }}
            >
              www.kopling.no
            </Text>
          </>
        )}
        <Dialog.Actions>
          <Dialog.Button title="LUKK" titleStyle={styles.dialogButtonTitle} onPress={() => setRegisterStatus('')} />
        </Dialog.Actions>
      </Dialog>
    </>
  )
})

export default CafeteriaRegister

const styles = StyleSheet.create({
  bottomSheetHeader: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  buttonContainer: {
    marginTop: 20,
    marginBottom: 60,
  },
  button: {
    backgroundColor: theme.colors.primary,
  },
  buttonTitle: {
    color: theme.colors.white,
  },

  // Dialog
  dialogMainContainer: {
    borderRadius: 25,
    width: 360,
    paddingTop: 15,
    paddingBottom: 15,
    alignContent: 'center',
  },
  dialogIcon: {
    aspectRatio: 1,
    width: 67,
    height: 67,
  },
  dialogIconContainer: {
    alignSelf: 'center',
  },
  dialogIconPlaceholder: {
    backgroundColor: 'transparent',
  },
  dialogTitleText: {
    fontFamily: 'FSJoeyMedium',
    fontSize: 20,
    textAlign: 'center',
    marginVertical: 15,
  },
  dialogContentText: {
    fontFamily: 'FSJoey',
    fontSize: 18,
    color: '#666',
    textAlign: 'center',
  },
  dialogContentLink: {
    fontFamily: 'FSJoey',
    fontSize: 18,
    color: theme.colors.primary,
    textAlign: 'center',
    textDecorationLine: 'underline',
  },
  dialogButtonTitle: {
    fontFamily: 'FSJoeyMedium',
    fontSize: 16,
    color: theme.colors.primary,
  },
})
