import React, { useState } from 'react'
import { StyleSheet } from 'react-native'
import { SearchBar } from '@rneui/themed'

// Theme
import theme from '../../../config/themes'

// Types
type SearchContactsProps = {
  onSearch: (s: string) => void
  inputColor?: string
}

const SearchContacts = ({ onSearch, inputColor = '' }: SearchContactsProps) => {
  const [search, setSearch] = useState('')

  const updateSearch = (text: string): void => {
    setSearch(text)
    onSearch(text)
  }

  return (
    <SearchBar
      placeholder="Søk etter hjelper"
      placeholderTextColor="#666666"
      onChangeText={updateSearch}
      value={search}
      containerStyle={[styles.container]}
      inputContainerStyle={{ backgroundColor: inputColor || theme.colors.white }}
      searchIcon={{ type: 'antdesign', name: 'search1' }}
    />
  )
}

export default SearchContacts

const styles = StyleSheet.create({
  container: {
    marginVertical: 15,
    paddingHorizontal: 15,
  },
})
