import { useState, useCallback, useRef, useMemo, forwardRef, useImperativeHandle, useEffect } from 'react'
import { StyleSheet, View } from 'react-native'
import { Button } from '@rneui/themed'
import * as Linking from 'expo-linking'
import { BottomSheetModal, BottomSheetBackdrop } from '@gorhom/bottom-sheet'
import { NativeViewGestureHandler } from 'react-native-gesture-handler'
import * as WebBrowser from 'expo-web-browser'
import moment from 'moment'

import { WebView, WebViewMessageEvent } from 'react-native-webview'

// Context
import { useAppInsights } from '@/contexts/appInsights'

// APIs
import { contentApi } from '@/slices/contentsSlice'
import getTokenExchange from '@/api/auth/getTokenExchange'

// Helpers
import isDomainSafe from '@/plugins/helpers/isDomainSafe'

// Themes
import theme from '@/config/themes'

// Types
export type ICafeteriaWebviewRef = {
  setIsActive: (bool: boolean) => void
  setUrl: (url: string) => void
  current: any
}
type ICafeteriaWebviewProps = {}

const CafeteriaWebview = forwardRef<any, ICafeteriaWebviewProps>((props, ref) => {
  const { appInsights } = useAppInsights()
  const bottomSheetModalRef = useRef<BottomSheetModal>(null)
  const [sessionExpiresIn, setSessionExpiresIn] = useState<number>()
  const webViewRef = useRef<WebView>(null)
  const [url, setUrl] = useState<string>('')
  const { data: userCafeteriasData } = contentApi.endpoints.userCafeterias.useQuery(undefined, {
    skip: false,
    refetchOnMountOrArgChange: true,
  })

  const [triggerCafeteriaBalance] = contentApi.endpoints.userCafeteriaBalance.useLazyQuery()

  const snapPoints = useMemo(() => ['90%'], [])

  useImperativeHandle(ref, () => ({
    setIsActive,
    setUrl,
  }))

  useEffect(() => {
    const subscription = Linking.addEventListener('url', handleUrlScheme)
    return () => subscription.remove()
  }, [])
  const handleUrlScheme = (e: Linking.EventType) => {
    try {
      const { hostname, path, queryParams } = Linking.parse(e.url)
      // Redriect
      const redirectUrl = decodeURI(queryParams?.url as string)
      if (hostname === 'pej' && path === 'redirect' && redirectUrl) {
        if (!isDomainSafe(redirectUrl)) return
        bottomSheetModalRef.current?.present()
        try {
          WebBrowser.dismissBrowser() // In case the browser is still open (iOs)
        } catch {}

        setUrl(redirectUrl)
      }
    } catch {}
  }

  const renderBackdrop = useCallback((props) => <BottomSheetBackdrop {...props} disappearsOnIndex={-1} appearsOnIndex={0} />, [])
  const onDismiss = () => {
    updateCafeteriaBalance()
  }

  const setIsActive = (isActive: boolean) => {
    if (isActive) {
      bottomSheetModalRef.current?.present()
    } else {
      bottomSheetModalRef.current?.dismiss()
    }
  }

  const updateCafeteriaBalance = useCallback(async () => {
    try {
      const shopId = url.match(/https?:\/\/[^\/]+\/(\d+)/)?.[1]
      if (shopId) {
        triggerCafeteriaBalance(shopId)
      }
    } catch (error) {}
  }, [url, triggerCafeteriaBalance])

  const onMessage = useCallback(
    async (e: WebViewMessageEvent) => {
      // Data from postMessage
      let data: { [key: string]: any } = {}
      try {
        data = JSON.parse(e.nativeEvent.data)
      } catch {}

      // Login when status is ready
      try {
        if (data?.status === 'ready') {
          const diff = sessionExpiresIn ? moment.unix(sessionExpiresIn).diff(moment(), 'minutes') : 0
          if (diff <= 30) {
            const tokenExchange = await getTokenExchange()
            if (tokenExchange?.data?.access_token) {
              const js = `
              window.postMessage({
                  'action': 'et_login',
                  'token': '${tokenExchange.data.access_token}'
                },
                '*'
              );
              true;
            `
              webViewRef.current?.injectJavaScript(js)
            }
          }
        }
      } catch {}

      // Set session expires in
      try {
        if (data?.status === 'login_succesful') {
          const expiresIn = moment().add(data.expires_in, 'milliseconds').unix()
          setSessionExpiresIn(expiresIn)
        }
      } catch {}
    },
    [webViewRef, sessionExpiresIn]
  )

  useEffect(() => {
    doTracking()
  }, [url])

  const doTracking = useCallback(() => {
    try {
      if (!url || !userCafeteriasData) return
      const shopId = url.match(/https?:\/\/[^\/]+\/(\d+)/)?.[1]
      const institution = userCafeteriasData.find((x) => x.pejShopId === shopId) ?? null

      let eventType = ''
      if (url.endsWith('/request-top-up')) {
        eventType = 'RequestTopup'
      } else if (url.endsWith('/wallet')) {
        eventType = 'Wallet'
      } else if (url.endsWith('/user-order-history')) {
        eventType = 'OrderHistory'
      } else {
        eventType = 'Order'
      }
      if (institution && eventType) {
        appInsights.trackEvent({
          name: 'Canteen',
          properties: {
            eventType,
            institutionName: institution.institutionName,
            institutionId: institution.institutionId,
            shopId,
          },
        })
      }
    } catch (error) {
      console.log(error)
    }
  }, [url, userCafeteriasData])

  return (
    <>
      <BottomSheetModal
        ref={bottomSheetModalRef}
        index={0}
        snapPoints={snapPoints}
        backdropComponent={renderBackdrop}
        enableContentPanningGesture={false}
        onDismiss={onDismiss}
      >
        <View style={styles.bottomSheetHeader}>
          <Button
            title="LUKK"
            onPress={() => setIsActive(false)}
            type="clear"
            buttonStyle={[theme._.ButtonStyle.Clear, { width: 75, paddingRight: 0, justifyContent: 'flex-end' }]}
            titleStyle={{ color: theme.colors.primary }}
          />
        </View>

        <NativeViewGestureHandler disallowInterruption={true}>
          <View style={{ flex: 1, paddingHorizontal: 20 }}>
            <WebView
              ref={webViewRef}
              style={{ marginVertical: 15 }}
              source={{ uri: url }}
              onMessage={onMessage}
              cacheEnabled
              originWhitelist={['*']}
              onShouldStartLoadWithRequest={(request) => {
                if (request.url.includes('vipps.no')) {
                  void Linking.openURL(request.url)
                  return false
                }
                return true
              }}
            />
          </View>
        </NativeViewGestureHandler>
      </BottomSheetModal>
    </>
  )
})

export default CafeteriaWebview

const styles = StyleSheet.create({
  bottomSheetHeader: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
})
