import { useState, useRef } from 'react'
import { StyleSheet, View } from 'react-native'
import { Button, Overlay, Text, Icon, Input } from '@rneui/themed'

// Store
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/store/rootReducer'
import { fetchUser } from '@/slices/userSlice'

// APIs
import verifyPassword from '@/api/auth/verifyPassword'
import requestDeleteAccount from '@/api/auth/requestDeleteAccount'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Themes
import theme from '@/config/themes'

const SettingsDeleteAccountButton = () => {
  const dispatch = useDispatch()
  const { user } = useSelector((state: RootState) => state.user)
  const [isActive, setIsActive] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [isPasswordValid, setIsPasswordValid] = useState(true)

  if (!user) {
    return null
  }

  const openDeleteAccount = () => {
    setIsActive(true)
    // organizationSelectorRef.current?.setIsActive(true)
  }

  const onChangeText = (s: string) => {
    setPassword(s)
  }

  const doDeleteAccount = async () => {
    try {
      setIsLoading(true)
      setIsPasswordValid(true)
      if (!password.length) {
        return
      }

      // Verify password
      const verifyPasswordResponse = await verifyPassword({ currentPassword: password })
      if (verifyPasswordResponse.status !== 200) {
        toast.show('Beklager, noe gikk galt.', { type: 'main', data: { title: 'Obs!', icon: 'error' } })
        return
      }
      if (!verifyPasswordResponse.data) {
        setIsPasswordValid(false)
        return
      }

      // Request to delete account
      const res = await requestDeleteAccount()
      if (res.status !== 200) {
        toast.show('Beklager, noe gikk galt.', { type: 'main', data: { title: 'Obs!', icon: 'error' } })
        return
      }
      toast.show('Vi skal slette konto i 7 dager.', { type: 'main', data: { title: 'Forespurt', icon: 'success' } })
      setIsActive(false)
    } catch (error: any) {
      console.log(error.response.data)
      toast.show('Beklager, noe gikk galt.', { type: 'main', data: { title: 'Obs!', icon: 'error' } })
      xConsole().error(error, 'EditInfo.tsx (submit)')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <Button
        title="SLETT KONTO"
        onPress={openDeleteAccount}
        disabledStyle={{ backgroundColor: theme.colors.primary, opacity: 0.5 }}
        disabledTitleStyle={{ color: theme.colors.white }}
      />
      <Overlay
        isVisible={isActive}
        onBackdropPress={() => {
          if (!isLoading) {
            setIsActive(false)
          }
        }}
        overlayStyle={{
          alignItems: 'center',
          paddingTop: 50,
          paddingBottom: 20,
          paddingHorizontal: 40,
        }}
      >
        <Icon name="sad" type="ionicon" color={theme.colors.black} size={60} />
        <Text h4 style={{ marginVertical: 15, textAlign: 'center' }}>
          Er du sikker på at du vil slette kontoen din?
        </Text>
        <Text style={{ marginVertical: 15, textAlign: 'center' }}>Denne handlingen kan ikke reverseres.</Text>

        <Input
          placeholder={'Bekreft passord'}
          containerStyle={styles.mainInputContainer}
          inputContainerStyle={styles.inputContainer}
          inputStyle={styles.input}
          errorStyle={styles.inputErrorText}
          errorMessage={!isPasswordValid ? 'Feil passord, prøv igjen.' : ''}
          onChangeText={onChangeText}
          secureTextEntry
          disabled={isLoading}
        />
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Button
            title="Slett konto"
            onPress={doDeleteAccount}
            type="clear"
            buttonStyle={{ width: 100 }}
            titleStyle={{ color: theme.colors.black, fontFamily: 'FSJoeyMedium' }}
            disabledStyle={{ opacity: 0.25 }}
            disabled={isLoading}
          />
          <Button
            title="Avbryt"
            onPress={() => {
              setIsActive(false)
            }}
            type="clear"
            buttonStyle={{ width: 100 }}
            titleStyle={{ color: theme.colors.black, fontFamily: 'FSJoeyMedium' }}
            disabledStyle={{ opacity: 0.25 }}
            disabled={isLoading}
          />
        </View>
      </Overlay>
    </>
  )
}

export default SettingsDeleteAccountButton

const styles = StyleSheet.create({
  mainInputContainer: {
    width: 300,
    paddingHorizontal: 0,
  },

  inputLabel: {
    fontFamily: 'FSJoeyMedium',
  },
  inputContainer: {
    borderColor: theme.colors.light,
  },
  input: {
    fontSize: 16,
    minHeight: 35,
    textAlign: 'center',
  },
  inputErrorText: {
    color: theme.colors.primary,
    fontSize: 9,
    marginLeft: 0,
    height: 10,
    textAlign: 'center',
  },
})
