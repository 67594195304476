import { useState, useCallback } from 'react'
import { View, StyleSheet } from 'react-native'
import { Text, Button, Image, Icon } from '@rneui/themed'
import { SafeAreaView } from 'react-native-safe-area-context'
import LottieView from 'lottie-react-native'
import asyncStorage from '@react-native-async-storage/async-storage'

import { DrawerScreenProps } from '@react-navigation/drawer'

// Themes
import theme from '@/config/themes'

import { Routes } from '@/config/routes'

// Types
interface IntroScreenProps extends DrawerScreenProps<any, any> {}

const DATA = [
  {
    image: require('@/assets/images/intro-mc-1.png'),
    title: 'Snarveien til hjelp!',
    description: 'Her finner du mange fagpersoner som kan hjelpe deg. Det er selvsagt helt gratis.',
    button: 'GÅ VIDERE',
  },
  {
    image: require('@/assets/images/intro-mc-2.png'),
    title: 'En trygg kanal',
    description:
      'Du kan ta kontakt som deg selv, eller anonymt. Din data er alltid trygg hos oss. Gamle meldinger slettes automatisk etter 180 dager.',

    button: 'GÅ VIDERE',
  },
  {
    image: require('@/assets/images/intro-mc-3.png'),
    title: 'Innboksen har\nikke kontortid',
    description: 'Du kan sende melding når du vil, om hva du vil!',
    button: 'LAG EN KONTO',
  },
]

const IntroScreen = ({ navigation }: IntroScreenProps) => {
  const [step, setStep] = useState(0)

  const goNext = useCallback(async () => {
    if (step === 2) {
      await asyncStorage.setItem('alreadyShownIntro', 'true')
      navigation.navigate(Routes.Login)
      setTimeout(() => {
        setStep(0)
      }, 500)
    } else {
      setStep(step + 1)
    }
  }, [step])

  return (
    <View style={{ flex: 1 }}>
      <View style={styles.mainContainer}>
        <SafeAreaView style={styles.animationContainer} edges={['top']}>
          <Image source={DATA[step]?.image || null} style={styles.image} containerStyle={styles.imageContainer} />
          <LottieView source={require('@/assets/lottiefiles/intro-wave.json')} style={styles.lottieWave} autoPlay loop resizeMode={'cover'} />
        </SafeAreaView>

        <SafeAreaView style={styles.contentContainer} edges={['bottom']}>
          <View>
            <Text style={styles.title}>{DATA[step]?.title}</Text>
            <Text style={styles.text}>{DATA[step]?.description}</Text>
          </View>
          <View style={styles.footerContainer}>
            <View style={styles.dotsContainer}>
              <Icon
                type="entypo"
                name="dot-single"
                color={theme.colors.black}
                size={38}
                containerStyle={styles.dotsIconContainer}
                iconStyle={{ opacity: step === 0 ? 1 : 0.4 }}
              />
              <Icon
                type="entypo"
                name="dot-single"
                color={theme.colors.black}
                size={38}
                containerStyle={styles.dotsIconContainer}
                iconStyle={{ opacity: step === 1 ? 1 : 0.4 }}
              />
              <Icon
                type="entypo"
                name="dot-single"
                color={theme.colors.black}
                size={38}
                containerStyle={styles.dotsIconContainer}
                iconStyle={{ opacity: step === 2 ? 1 : 0.4 }}
              />
            </View>
            <Button buttonStyle={styles.button} titleStyle={styles.buttonTitle} onPress={goNext}>
              {DATA[step]?.button}
            </Button>
          </View>
        </SafeAreaView>
      </View>
    </View>
  )
}

export default IntroScreen

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    flexDirection: 'column',
    width: '100%',
  },

  /* ===== Content ===== */
  contentContainer: {
    minHeight: '30%',
    backgroundColor: '#bAdfe5',
    justifyContent: 'space-around',
    paddingHorizontal: 20,
    marginTop: -50,
    zIndex: 2,
  },
  title: {
    fontFamily: 'FSJoeyBold',
    fontSize: 45,
    marginBottom: 10,
  },
  text: {
    fontSize: 20,
  },
  footerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 10,
  },
  button: {
    width: 180,
    backgroundColor: theme.colors.white,
    borderWidth: 1,
    borderColor: theme.colors.black,
    borderRadius: 30,
  },
  buttonTitle: {
    fontFamily: 'FSJoeyMedium',
    fontSize: 18,
    color: theme.colors.black,
    lineHeight: 19, // Fix invisible "Å"
  },

  /* ===== Animation ===== */
  animationContainer: {
    flex: 1,
    backgroundColor: '#e9e0db',
    zIndex: 1,
    justifyContent: 'flex-end',
  },
  imageContainer: {
    height: '100%',
  },
  image: {
    width: '100%',
    height: 'auto',
  },
  lottieWave: {
    position: 'absolute',
    buttom: 0,
  },

  /* ===== Dots ===== */
  dotsContainer: {
    flexDirection: 'row',
  },
  dotsIconContainer: {
    marginHorizontal: -12,
  },
})
