import React from 'react'
import { StyleSheet } from 'react-native'
import { Day } from 'react-native-gifted-chat'

// Types
import type { IMessage } from '../../types'
import type { DayProps } from 'react-native-gifted-chat'
type ChatDayProps = DayProps<IMessage> & {
  isAnonymous: boolean
}

function ChatDay(props: ChatDayProps) {
  const { isAnonymous } = props
  return (
    <Day
      {...props}
      dateFormat={'dddd D. MMM YYYY'}
      containerStyle={styles.container}
      textStyle={[styles.text, { color: !isAnonymous ? '#2b2c2b' : '#ffffff' }]}
    />
  )
}

export default ChatDay

const styles = StyleSheet.create({
  container: {
    marginTop: 10,
    marginBottom: 5,
  },
  text: {
    fontFamily: 'FSJoey',
    fontSize: 12,
  },
})
