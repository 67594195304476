import { StyleSheet, View } from 'react-native'
import { Text } from '@rneui/themed'

const ChannelEmpty = () => {
  return (
    <View style={styles.mainContainer}>
      <Text style={styles.text}>Hvis du har et spørsmål - lite eller stort er det mange her som kan hjelpe deg.</Text>
    </View>
  )
}

export default ChannelEmpty

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 300,
    padding: 20,
    fontSize: 20,
  },
  text: {
    fontSize: 20,
    textAlign: 'center',
  },
})
