import axios from 'axios'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Types
import type { IToken } from '@/types'
export type IVerifyTokenArgs = IToken
export type IVerifyTokenReturn = Promise<false | IToken>

/*
  Verify token from the API, using axios for getting userinfo for verify it
  Example usage: After got token / after refreshed token
*/
async function verifyToken(args: IVerifyTokenArgs): IVerifyTokenReturn {
  try {
    axios.defaults.headers.common['Authorization'] = args.access_token ? `Bearer ${args.access_token}` : ''
    const res = await axios.get('/api/user/profile')
    return res.status === 200 ? args : false
  } catch (error: any) {
    xConsole().error(error, 'verifyToken')
    return false
  }
}

export default verifyToken
