import axios, { AxiosResponse } from 'axios'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Types
export type ICreateMessageArgs = {
  id: string
  content: string
  metaData?: object
}
export type ICreateMessageReturn = Promise<AxiosResponse>

async function createMessage({ id, content = '', metaData = {} }: ICreateMessageArgs): ICreateMessageReturn {
  try {
    const res = await axios.post(`/api/channel/${id}/message`, { content: content, metaData: metaData })
    return res
  } catch (error: any) {
    xConsole().error(error, 'createMessage')
    return error.response
  }
}

export default createMessage
