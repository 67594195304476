import axios, { AxiosResponse } from 'axios'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Types
export type IVerifyPasswordArgs = {
  currentPassword: string
}
export type IVerifyPasswordReturn = Promise<AxiosResponse>

/*
  Verify password from the API, using axios. ()
  Example usage: Do something important to their account, verify the password before doing that
*/
async function verifyPassword(args: IVerifyPasswordArgs): IVerifyPasswordReturn {
  try {
    const res = await axios.post('/api/user/verifypassword', { currentPassword: args.currentPassword })
    return res
  } catch (error: any) {
    xConsole().error(error, 'verifyPassword')
    return error.response
  }
}

export default verifyPassword
