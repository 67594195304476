import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '@/store'

// APIs
import getApiInstitutions from '@/api/institutions/getInstitutions'
import getApiCounties from '@/api/institutions/getCounties'
import getApiInstitutionHelpers from '@/api/institutions/getInstitutionHelpers'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Types
import type { IInstitutionsState, IInstitutionHelper, IInstitution, ICounty, IInstitutionListItem } from '@/types'
const initialState: IInstitutionsState = {
  institutions: [],
  counties: [],
  helpers: [],
  isLoading: false,
  error: false,
}

const institutions = createSlice({
  name: 'institutions',
  initialState,
  reducers: {
    getInstitutions(state) {
      state.isLoading = true
      state.error = false
    },
    getInstitutionsSuccess(state, action: PayloadAction<{ institutions: Array<IInstitution> }>) {
      const payload = action.payload
      state.isLoading = false
      state.institutions = payload.institutions
    },
    getInstitutionsFailure(state) {
      state.isLoading = false
      state.error = true
    },

    getCounties(state) {
      state.isLoading = true
      state.error = false
    },
    getCountiesSuccess(state, action: PayloadAction<{ counties: Array<ICounty> }>) {
      const payload = action.payload
      state.isLoading = false
      state.counties = payload.counties
    },
    getCountiesFailure(state) {
      state.isLoading = false
      state.error = true
    },

    getInstitutionHelpers(state) {
      state.isLoading = true
      state.error = false
    },
    getInstitutionHelpersSuccess(state, action: PayloadAction<{ helpers: Array<IInstitutionHelper> }>) {
      const payload = action.payload
      state.isLoading = false
      const items: IInstitutionListItem[] = []
      for (const institution of payload.helpers) {
        items.push({
          type: 'header',
          props: {
            id: institution.institutionId,
            title: institution.institutionName,
          },
        })
        for (const user of institution.users) {
          user.institutionId = institution.institutionId
          items.push({
            type: 'item',
            props: user,
          })
        }
      }
      state.helpers = items
    },
    getInstitutionHelpersFailure(state) {
      state.isLoading = false
      state.error = true
    },
  },
})

export const {
  getInstitutions,
  getInstitutionsSuccess,
  getInstitutionsFailure,
  getCounties,
  getCountiesSuccess,
  getCountiesFailure,
  getInstitutionHelpers,
  getInstitutionHelpersSuccess,
  getInstitutionHelpersFailure,
} = institutions.actions

export default institutions.reducer

export const fetchInstitutions = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getInstitutions())
    const res = await getApiInstitutions()
    dispatch(getInstitutionsSuccess({ institutions: res.data }))
  } catch (error: any) {
    dispatch(getInstitutionsFailure())
    xConsole().error(error, 'fetchInstitutions')
  }
}

export const fetchCounties = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getCounties())
    const res = await getApiCounties()
    dispatch(getCountiesSuccess({ counties: res.data }))
  } catch (error: any) {
    dispatch(getCountiesFailure())
    xConsole().error(error, 'fetchCounties')
  }
}

export const fetchInstitutionHelpers = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getInstitutionHelpers())
    const res = await getApiInstitutionHelpers()
    dispatch(getInstitutionHelpersSuccess({ helpers: res.data }))
  } catch (error: any) {
    dispatch(getInstitutionsFailure())
    xConsole().error(error, 'fetchInstitutionHelpers')
  }
}
