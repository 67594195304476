import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '@/store'

// APIs
import getApiFAQs from '@/api/faqs/getFAQs'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Types
import { IFAQState, IFAQ, IUserState } from '@/types'

const initialState: IFAQState = {
  faqs: [],
  isLoading: false,
  error: false,
}

const faq = createSlice({
  name: 'faqs',
  initialState,
  reducers: {
    getFAQs(state) {
      state.isLoading = true
      state.error = false
    },
    getFAQsSuccess(state, action: PayloadAction<{ faqs: Array<IFAQ> }>) {
      const payload = action.payload
      state.isLoading = false
      state.faqs = payload.faqs
    },
    getFAQsFailure(state) {
      state.isLoading = false
      state.error = true
    },
  },
})

export const { getFAQs, getFAQsSuccess, getFAQsFailure } = faq.actions

export default faq.reducer

export const fetchFAQ = (): AppThunk => async (dispatch, getState) => {
  try {
    const { user, token }: IUserState = getState().user
    const isTokenExpired = token ? new Date(token?.expires_date).getTime() < new Date().getTime() : true
    if (user && !isTokenExpired) {
      dispatch(getFAQs())
      const res = await getApiFAQs()
      if (res.status === 200) {
        dispatch(getFAQsSuccess({ faqs: res.data }))
      }
    }
  } catch (error: any) {
    dispatch(getFAQsFailure())
    xConsole().error(error, 'fetchFAQs')
  }
}
