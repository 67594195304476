import { StyleSheet, View } from 'react-native'
import { Text, Icon } from '@rneui/themed'

// Themes
import theme from '@/config/themes'

// Types
import type { IMessage } from '../../types'
import type { SystemMessageProps } from 'react-native-gifted-chat'
type ChatSystemMessageParticipantRemovedProps = SystemMessageProps<IMessage> & {
  isAnonymous: boolean
}

function ChatSystemMessageParticipantRemoved(props: ChatSystemMessageParticipantRemovedProps) {
  const { currentMessage, isAnonymous } = props
  if (!currentMessage) {
    return null
  }
  return (
    <View style={styles.mainContainer}>
      <View style={[styles.container, { backgroundColor: !isAnonymous ? '#efebe5' : '#1d1d1d' }]}>
        <Icon
          type="material-community"
          name="door-open"
          size={35}
          containerStyle={styles.iconContaniner}
          color={!isAnonymous ? theme.colors.black : theme.colors.white}
        />
        <Text style={[styles.text, { color: !isAnonymous ? theme.colors.black : theme.colors.white }]}>
          «{currentMessage.metaData?.user?.displayName || 'Noen'}» har avsluttet samtalen.
        </Text>
      </View>
    </View>
  )
}

export default ChatSystemMessageParticipantRemoved

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    marginTop: 30,
    marginBottom: 30,
    marginHorizontal: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    flexDirection: 'row',
    width: '100%',
    padding: 15,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 20,
  },
  iconContaniner: {
    marginRight: 15,
  },
  text: {
    color: theme.colors.black,
    fontSize: 16,
    fontWeight: '300',
    textAlign: 'center',
  },
})
