import { useState, useCallback } from 'react'
import { StyleSheet, View, Pressable } from 'react-native'
import { Text, Icon, Dialog } from '@rneui/themed'

// Themes
import theme from '@/config/themes'

type OrderButtonProps = {
  isRegistered: boolean
  doRegister: () => void
  openUrl: () => void
}
const OrderButton = ({ isRegistered, doRegister, openUrl }: OrderButtonProps) => {
  const [confirmDialogActive, setConfirmDialogActive] = useState(false)

  const onPress = useCallback(() => {
    if (!isRegistered) {
      setConfirmDialogActive(true)
    } else {
      openUrl()
    }
  }, [isRegistered])

  return (
    <>
      <Pressable style={[styles.itemContainer]} onPress={onPress}>
        <Icon type="material-community" name="food-apple-outline" size={30} color={theme.colors.primary} style={styles.itemIcon} />
        <Text style={styles.itemTitle}>Handle</Text>
      </Pressable>
      <Dialog isVisible={confirmDialogActive} overlayStyle={styles.dialogMainContainer}>
        <Icon type="octicon" name="credit-card" size={40} color={theme.colors.primary} containerStyle={styles.dialogIconContainer} />
        <Text style={styles.dialogTitleText}>Vil du bestille kantinekort?</Text>
        <Text style={styles.dialogContentText}>
          Det er mange fordeler med kantinekortet, Det går raskere å handle i kantinen og du kan betale rett fra kortet om du vil!
        </Text>

        <Dialog.Actions>
          <View style={styles.dialogActionsContainer}>
            <Dialog.Button
              title="IKKE NÅ"
              titleStyle={styles.dialogButtonTitle}
              onPress={() => {
                setConfirmDialogActive(false)
                setTimeout(openUrl, 250)
              }}
            />
            <Dialog.Button
              title="BESTILL KANTINEKORT"
              titleStyle={styles.dialogButtonTitle}
              containerStyle={styles.dialogButtonConfirm}
              onPress={() => {
                setConfirmDialogActive(false)
                setTimeout(doRegister, 250)
              }}
            />
          </View>
        </Dialog.Actions>
      </Dialog>
    </>
  )
}

export default OrderButton

const styles = StyleSheet.create({
  itemContainer: {
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: '#c4e08c',
    borderRadius: 8,
    padding: 10,
    margin: 5,
  },
  itemIcon: {
    backgroundColor: '#fff',
    width: 54,
    height: 54,
    justifyContent: 'center',
    borderRadius: 100,
    marginBottom: 5,
  },
  itemTitle: {
    fontFamily: 'FSJoeyMedium',
    fontSize: 14,
  },

  // Dialog
  dialogMainContainer: {
    borderRadius: 25,
    width: 360,
    paddingVertical: 25,
    alignContent: 'center',
  },
  dialogIconContainer: {
    alignSelf: 'center',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    width: 78,
    height: 78,
    backgroundColor: '#dbeab2',
    borderRadius: 100,
  },

  dialogTitleText: {
    fontFamily: 'FSJoeyMedium',
    fontSize: 20,
    textAlign: 'center',
    marginTop: 15,
  },
  dialogContentText: {
    fontFamily: 'FSJoey',
    fontSize: 18,
    color: '#666',
    textAlign: 'center',
  },
  dialogContentLink: {
    fontFamily: 'FSJoey',
    fontSize: 18,
    color: theme.colors.primary,
    textAlign: 'center',
    textDecorationLine: 'underline',
    marginTop: 15,
  },

  dialogActionsContainer: {
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
  dialogButtonTitle: {
    fontFamily: 'FSJoeyMedium',
    fontSize: 16,
    color: theme.colors.primary,
  },
  dialogButtonConfirm: {
    marginLeft: 10,
    borderColor: theme.colors.primary,
    borderWidth: 1,
    borderRadius: 100,
  },
})
