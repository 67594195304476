/*
  Check if users are alreay talk with others before
  Params: targetUserId(string), channels(array)(required)
  Return: boolean
*/

import { xConsole } from './xConsole'
import type { IChannel } from '@/types'

const checkUsersAlreadyConnected = (targetUserId: string, isAnonymous: boolean, channels: IChannel[]): IChannel | null => {
  try {
    let isChannelExists: IChannel | null = null
    for (const channel of channels) {
      const memberSize = Object.keys(channel.participants).length
      if (
        !isAnonymous && // Not as anonymous
        !channel.isAnonymous &&
        memberSize === 2 && // 2 members (private message / no group)
        channel.participants.find((v) => v.userId === targetUserId)
      ) {
        isChannelExists = channel
        break
      }
    }
    return isChannelExists
  } catch (error) {
    xConsole().error(error as Error, 'checkUsersAlreadyConnected.ts (Main)')
    return null
  }
}

export default checkUsersAlreadyConnected
