import { View } from 'react-native'

// APIs
import { chatApi } from '@/api/chatApi'

// Components
import ChatHeader from '@/components/chat/Header'
import ChatView from '@/components/chat/View'

// Types
import { RootNavigatorParamList } from '@/types'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { Routes } from '@/config/routes'

type ChatScreenProps = NativeStackScreenProps<RootNavigatorParamList, Routes.Chat>

const ChatScreen = (props: ChatScreenProps) => {
  /* ======= Prepare data ====== */
  const { item, create } = props.route.params

  const { data: channelData } = chatApi.endpoints.getChannel.useQuery(item.id, {
    skip: !!create,
    refetchOnMountOrArgChange: true,
  })

  return (
    <View style={{ flex: 1, backgroundColor: channelData?.isAnonymous || item.isAnonymous ? '#333333' : '#f8f8f8' }}>
      <ChatHeader
        id={channelData?.id || item.id}
        topic={channelData?.topic || item.topic}
        topicDescription={channelData?.topicDescription || item.topicDescription}
        topicAvatarId={channelData?.topicAvatarId || item.topicAvatarId}
        availability={channelData?.availability || item.availability}
        isAnonymous={channelData?.isAnonymous || item.isAnonymous}
      />
      <ChatView {...props} />
    </View>
  )
}

export default ChatScreen
