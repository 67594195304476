import { StyleSheet, View, Pressable } from 'react-native'
import { Text, Badge, Image } from '@rneui/themed'
import Avatar from '../Avatar'

// Themes
import theme from '@/config/themes'

// Types
type ToastMainProps = {
  toast: any
}

const toastMain = ({ toast: _toast }: ToastMainProps) => {
  const doPress = () => {
    if (_toast.data?.onPress) {
      _toast.data.onPress()
    }
  }
  const doClose = () => {
    if (_toast && _toast.id) {
      toast.hide(_toast.id)
    }
  }

  return (
    <View style={{ width: 750, maxWidth: '95%', minWidth: '95%' }}>
      <Pressable onPress={doPress}>
        <View style={styles.mainContainer}>
          {_toast.data?.icon === 'success' && (
            <Image
              source={require('../../assets/images/icons/smile.png')}
              style={styles.defaultIconImage}
              containerStyle={styles.defaultIconImageContainer}
            />
          )}
          {_toast.data?.icon === 'error' && (
            <Image
              source={require('../../assets/images/icons/oh.png')}
              style={styles.defaultIconImage}
              containerStyle={styles.defaultIconImageContainer}
            />
          )}
          {!_toast.data?.icon && _toast.data?.photoURL && (
            <Avatar
              photoURL={_toast.data.photoURL}
              placeholderTitle={_toast.data?.photoURPlaceholderTitle}
              props={{
                size: 46,
                containerStyle: styles.defaultIconImageContainer,
              }}
            />
          )}
          <View style={styles.content}>
            {!!_toast.data?.title && <Text style={styles.title}>{_toast.data.title}</Text>}
            {!!_toast.message && (
              <Text style={styles.description} numberOfLines={2}>
                {_toast.message}
              </Text>
            )}
          </View>
          <Pressable style={styles.closeButtonContainer} onPress={doClose}>
            <Text style={styles.closeButtonText}>LUKK</Text>
          </Pressable>
          {_toast.data?.badge && (
            <Badge value={_toast.data.badge} containerStyle={styles.badgeContainer} badgeStyle={styles.badge} textStyle={styles.badgeText} />
          )}
        </View>
      </Pressable>
    </View>
  )
}

export default toastMain

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 10,
    paddingHorizontal: 20,
    paddingVertical: 15,
    backgroundColor: theme.colors.white,
    borderRadius: 25,
    shadowColor: theme.colors.black,
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    elevation: 5,
  },
  content: {
    flexShrink: 1,
    flexGrow: 1,
  },
  title: {
    fontFamily: 'FSJoeyMedium',
    fontSize: 18,
  },
  description: {
    color: theme.colors.grey2,
  },
  defaultIconImage: {
    aspectRatio: 1,
    width: 35,
    height: 35,
  },
  defaultIconImageContainer: {
    marginRight: 20,
  },
  badgeContainer: {
    position: 'absolute',
    right: 0,
    bottom: -10,
  },
  badge: {
    width: 26,
    height: 26,
    backgroundColor: theme.colors.primary,
    borderRadius: 100,
  },
  badgeText: {
    fontFamily: 'FSJoeyBold',
  },
  closeButtonContainer: {
    alignSelf: 'flex-start',
    paddingBottom: 15,
    paddingLeft: 15,
    paddingVertical: 2,
  },
  closeButtonText: {
    fontFamily: 'FSJoeyMedium',
    fontSize: 16,
    color: theme.colors.primary,
  },
})
