'use strict'
import getApiUsers from '../api/users/getApiUsers'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppThunk } from '../store'
import { chunk } from 'lodash'
import { xConsole } from '../plugins/helpers/xConsole'
import { IUsersState, IUser } from '../types'

const initialState: IUsersState = {
  users: {},
  isLoading: false,
  error: false,
}

const user = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getUsers(state) {
      state.isLoading = true
      state.error = false
    },
    getUsersSuccess(state, action: PayloadAction<{ users: IUser }>) {
      const payload = action.payload
      state.isLoading = false
      // Limit total usersIn @TODO: Have to test
      const limitSize = 200
      const UserEntries = Object.entries(state.users)
      if (UserEntries.length >= limitSize) {
        const newUsersSize = Object.entries(payload.users).length
        if (limitSize > newUsersSize) {
          const usersInArray = UserEntries.map(([k, v]) => ({ [k]: v }))
          const usersChunk = chunk(usersInArray, limitSize - newUsersSize)
          state.users = Object.assign({}, ...usersChunk[0])
        } else {
          state.users = {}
        }
      }
      // Merge new users to current state
      state.users = { ...state.users, ...payload.users }
    },
    getUsersFailure(state) {
      state.isLoading = false
      state.error = true
    },
  },
})

export const { getUsers, getUsersSuccess, getUsersFailure } = user.actions

export default user.reducer

export const fetchUsers =
  (ids: Array<string> = []): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(getUsers())
      const res = await getApiUsers(ids)
      dispatch(getUsersSuccess({ users: res.data }))
    } catch (error) {
      dispatch(getUsersFailure())
      xConsole().error(error as Error, 'usersSlice.ts (fetchUsers)')
    }
  }
