import axios from 'axios'
import store from '@/store'
import * as TaskManager from 'expo-task-manager'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

//Types
export type ILogoutReturn = Promise<boolean>

/*
  Logout, that will revoke current token and remove data from store
  Example usage: User needs to logout after logged in
*/
async function logout(): ILogoutReturn {
  try {
    // Try to revoke token
    try {
      await axios.post('/api/profilesettings/logout')
      await TaskManager.unregisterAllTasksAsync()
    } catch (error: any) {}
    // Remove token from Axois default header
    axios.defaults.headers.common['Authorization'] = ''
    // Clear store
    store.dispatch({ type: 'CLEAR_ALL' })
    return true
  } catch (error: any) {
    xConsole().error(error, 'Logout')
    return false
  }
}

export default logout
