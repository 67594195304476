// Enumerator for all routes.
export enum Routes {
  Intro = 'IntroScreen',
  Login = 'LoginScreen',
  Channels = 'ChannelsScreen',
  Chat = 'ChatScreen',
  Contacts = 'ContactsScreen',
  InstitutionContacts = 'InstitutionContactsScreen',
  OrganizationContacts = 'OrganizationContactsScreen',
  OrganizationContactsPage = 'OrganizationContactsPageScreen',
  EmergencyContacts = 'EmergencyContactsScreen',
  Contents = 'ContentScreen',
  ContentPage = 'ContentPageScreen',
  ContentCategory = 'ContentCategoryScreen',
  FAQ = 'FAQScreen',
  Bully = 'BullyScreen',
  BullyPage = 'BullyPageScreen',
  ContactProfile = 'ContactProfileScreen',
  Settings = 'SettingsScreen',
  SettingsProfile = 'SettingsProfileScreen',
  SettingsSecurity = 'SettingsSecurityScreen',
  SettingsInstitutions = 'SettingsInstitutionsScreen',
  SettingsAvailability = 'SettingsAvailabilityScreen',
  SettingsDeleteAccount = 'SettingsDeleteAccountScreen',
  SettingsPrivacy = 'SettingsPrivacyScreen',
  SettingsCrisisroutine = 'SettingsCrisisroutineScreen',
  SettingsAdmin = 'SettingsAdminScreen',
}
