import { StyleSheet, View, TouchableOpacity } from 'react-native'
import { Icon } from '@rneui/themed'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import theme from '@/config/themes'

// Navigation
import { useNavigation } from '@react-navigation/native'

// Types
type ScreenTitleProps = {}
const ScreenTitle = ({}: ScreenTitleProps) => {
  const navigation = useNavigation()
  const insets = useSafeAreaInsets()
  return (
    <View style={[styles.mainContainer, { paddingTop: insets.top + 20 }]}>
      <TouchableOpacity
        onPress={() => {
          navigation.goBack()
        }}
        style={styles.backButton}
      >
        <Icon type="entypo" name="chevron-left" color={theme.colors.black} size={38} />
      </TouchableOpacity>
    </View>
  )
}

export default ScreenTitle

const styles = StyleSheet.create({
  mainContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingBottom: 10,
    marginBottom: 1,
    zIndex: 1,
  },
  backButton: {
    width: 45,
    height: 45,
    marginTop: -15,
    marginBottom: -15,
    backgroundColor: '#f2efea',
    borderRadius: 100,
    justifyContent: 'center',
    alignItems: 'center',
    shadowColor: theme.colors.black,
    shadowOffset: { width: 0, height: 0 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    elevation: 0,
  },
})
