import axios, { AxiosResponse } from 'axios'

// APIs
import logout from '@/api/auth/logout'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Types
export type IRequestDeleteAccountReturn = Promise<AxiosResponse>

/*
  Request to delete the account from the API, using axios. ()
  Example usage: User will request to delete their account (deactivate)
*/
async function requestDeleteAccount(): IRequestDeleteAccountReturn {
  try {
    const res = await axios.delete('/api/user/deactivate')
    await logout()
    return res
  } catch (error: any) {
    xConsole().error(error, 'requestDeleteAccount')
    return error.response
  }
}

export default requestDeleteAccount
