import { StyleSheet, View } from 'react-native'
import { ListItem, Text, Button, Icon } from '@rneui/themed'

import Avatar from '@/components/Avatar'

// Theme
import theme from '@/config/themes'

//Types
import type { IInstitutionListItem } from '@/types'
type ShareContactsListItemProps = {
  item: IInstitutionListItem
  trigger: Function
  disabled: Boolean
}

const ShareContactsListItem = ({ item, trigger, disabled }: ShareContactsListItemProps) => {
  if (item.type === 'item') {
    return (
      <ListItem key={item.props.userId} containerStyle={styles.listContainer}>
        <Avatar avatarId={item.props.avatarId} placeholderTitle={item.props.displayName} availability={item.props.availability} />
        <ListItem.Content>
          <ListItem.Title>{item.props.displayName}</ListItem.Title>
          <ListItem.Subtitle numberOfLines={1} style={{ color: theme.colors.grey2 }}>
            {item.props.title}
          </ListItem.Subtitle>
        </ListItem.Content>
        <Button
          containerStyle={{
            width: 105,
            borderRadius: 0,
          }}
          titleStyle={{
            fontSize: 14,
            color: theme.colors.primary,
          }}
          type="clear"
          buttonStyle={[theme._.ButtonStyle.Clear]}
          onPress={() => {
            trigger(item)
          }}
          disabled={!!disabled}
          disabledStyle={{ opacity: 0.2, backgroundColor: '#efefef' }}
          disabledTitleStyle={{ color: theme.colors.primary }}
        >
          DEL KONTAKT <Icon type="entypo" name="chevron-right" color={theme.colors.primary} />
        </Button>
      </ListItem>
    )
  } else {
    return (
      <View key={item.props.id} style={styles.header}>
        <Text h4 h4Style={{ fontSize: 20 }}>
          {item.props.title}
        </Text>
      </View>
    )
  }
}

export default ShareContactsListItem

const styles = StyleSheet.create({
  header: {
    flex: 1,
    justifyContent: 'center',
    paddingLeft: 20,
    backgroundColor: theme.colors.white,
    height: 40,
  },
  listContainer: {
    height: 73,
    backgroundColor: '#f7f7f7',
    paddingHorizontal: 20,
    marginHorizontal: 20,
    marginBottom: 10,
    borderRadius: 8,
  },
})
