/*
  Chat JS docs: https://github.com/MicrosoftDocs/azure-docs/blob/main/articles/communication-services/quickstarts/chat/includes/chat-js.md
  Rest API docs: https://learn.microsoft.com/en-us/rest/api/communication/chat/chat
*/

import { createContext, useState, useContext, useEffect, useCallback, useRef } from 'react'
import useExtendedState from '@/plugins/helpers/useExtendedState'
import { Platform } from 'react-native'
import axios from 'axios'
import moment from 'moment'

// Prepare libs
if (Platform.OS !== 'web') {
  require('node-libs-react-native/globals')
}
import 'react-native-get-random-values'
import 'react-native-url-polyfill/auto'

import { ChatClient, TypingIndicatorReceivedEvent } from '@azure/communication-chat'
import { AzureCommunicationTokenCredential } from '@azure/communication-common'
import '@azure/core-asynciterator-polyfill'

// Configs
import { koplingConfig } from '@/config'

// APIs
import { chatApi } from '@/api/chatApi'

// Store
import { useSelector, useDispatch } from 'react-redux'
import { RootState } from '@/store/rootReducer'

// Helpers
import { xConsole } from '@/plugins/helpers/xConsole'

// Types
import type { NavigationContainerRef } from '@react-navigation/native'
import type { IChannel, RootNavigatorParamList, IMessage } from '@/types'

// For Native
if (!window.addEventListener) {
  window.addEventListener = (x) => x
}

type ACSContextData = {
  getChatClient(): Promise<ChatClient | null>
  typingIndicators: TypingIndicatorReceivedEvent[]
}
type ACSProps = {
  children: React.ReactNode
  navigation: NavigationContainerRef<RootNavigatorParamList>
}

const ACSContext = createContext<ACSContextData>({} as ACSContextData)

const ACSProvider: React.FC<ACSProps> = ({ children, navigation }) => {
  const dispatch = useDispatch()
  const { user } = useSelector((state: RootState) => state.user)
  const [ASCChatClient, setASCChatClient, getASCChatClient] = useExtendedState<ChatClient | null>(null)
  const chatMessageReceivedUnsubscribe = useRef<any>()
  const participantsRemovedUnsubscribe = useRef<any>()
  const typingIndicatorReceivedUnsubscribe = useRef<any>()
  const [typingIndicators, setTypingIndicators] = useState<TypingIndicatorReceivedEvent[]>([])

  const [userCommunicationInfo, setUserCommunicationInfo] = useState<any>(null)
  const [currentUserId, setCurrentUserId] = useState('')
  const [isInit, setIsInit] = useState(false)

  const [triggerChannels] = chatApi.endpoints.getChannels.useLazyQuery()
  const [triggerChannel] = chatApi.endpoints.getChannel.useLazyQuery()
  const [triggerMessages] = chatApi.endpoints.getMessages.useLazyQuery()

  useEffect(() => {
    // if (!user?.roles?.includes('superadmin')) return

    if (!isInit && user && axios.defaults.headers.common['Authorization'] && user.userId !== currentUserId) {
      onChatMessageReceived()
      onParticipantsRemoved()
      onTypingIndicatorReceived()
      setCurrentUserId(user.userId)

      setIsInit(true) // Just for first-time load
    }

    // Logout
    if (!user && currentUserId) {
      unsubscribe()
    }
  }, [user, currentUserId])

  /* ====================================================================================================================================
   Chat client (get chatClient from the SDK)
  ==================================================================================================================================== */

  const getChatClient = async (): Promise<ChatClient | null> => {
    try {
      const ASCChatClient = await getASCChatClient()
      if (ASCChatClient) {
        return ASCChatClient // Return current chatClient when already init
      }
      const res = await axios.get('/api/channel/token') // Get ACS user's token by Kopling API
      setUserCommunicationInfo(res.data)
      const tokenCredential = new AzureCommunicationTokenCredential(res.data.communicationAccessToken)
      const chatClient = new ChatClient(koplingConfig.ACSApiUrl, tokenCredential)
      await chatClient.startRealtimeNotifications() // Start real-time
      setASCChatClient(chatClient) // Store in React
      return chatClient
    } catch (error: any) {
      xConsole().error(error, 'getChatClient')
      return null
    }
  }

  /* ====================================================================================================================================
   Chat client events (real-time)
  ==================================================================================================================================== */
  const onChatMessageReceived = async () => {
    try {
      if (chatMessageReceivedUnsubscribe.current) return
      const chatClient = await getChatClient()
      chatClient?.on('chatMessageReceived', handleChatMessageReceived)
      chatMessageReceivedUnsubscribe.current = () => {
        chatClient?.off('chatMessageReceived', handleChatMessageReceived)
      }
    } catch (error: any) {}
  }

  const onParticipantsRemoved = async () => {
    try {
      if (participantsRemovedUnsubscribe.current) return
      const chatClient = await getChatClient()
      chatClient?.on('participantsRemoved', handleParticipantsRemoved)
      chatMessageReceivedUnsubscribe.current = () => {
        chatClient?.off('participantsRemoved', handleParticipantsRemoved)
      }
    } catch (error: any) {}
  }

  const onTypingIndicatorReceived = async () => {
    try {
      if (typingIndicatorReceivedUnsubscribe.current) return
      const chatClient = await getChatClient()
      chatClient?.on('typingIndicatorReceived', handleTypingIndicatorReceived)
      chatMessageReceivedUnsubscribe.current = () => {
        chatClient?.off('typingIndicatorReceived', handleTypingIndicatorReceived)
      }
    } catch (error: any) {}
  }

  const handleChatMessageReceived = async (v: any) => {
    // Channel
    dispatch(
      chatApi.util.updateQueryData('getChannel', v.threadId, (draft) => {
        Object.assign(draft, {
          lastMessage: v.message,
          lastMessageReceivedOn: moment(v.createdOn).format(),
          unread: v.metadata.senderUserId !== user?.userId,
        })
      })
    )
    triggerChannels()

    // Message

    dispatch(
      chatApi.util.updateQueryData('getMessages', { id: v.threadId }, (draft) => {
        try {
          const isDuplicated = draft.some((a) => a._id === v.id)
          if (isDuplicated) return
          const avatar = draft.find((a) => a.user._id === v.metadata?.senderUserId)?.user?.avatar // Find exist avatar from previous messages
          const message = {
            _id: v.id,
            createdAt: moment(v.createdOn).valueOf(),
            metaData: v.metadata,
            system: false,
            text: v.message,
            user: { _id: v.metadata?.senderUserId, name: v.senderDisplayN2ame, avatar: avatar },
          } as IMessage
          return [message].concat(draft)
        } catch (error) {}
      })
    )
    // triggerMessages({ id: v.threadId })
  }

  const handleParticipantsRemoved = async (v: any) => {
    triggerChannels()
    triggerChannel(v.threadId)
    triggerMessages({ id: v.threadId })
  }

  const handleTypingIndicatorReceived = (v: TypingIndicatorReceivedEvent) => {
    setTypingIndicators((prev) => {
      return [
        ...[v, ...prev]
          .reduce((m, r) => {
            const key = `${r.threadId}-${r.senderDisplayName}`
            return m.has(key) ? m : m.set(key, r)
          }, new Map())
          .values(),
      ]
    })
  }

  /* ====================================================================================================================================
   Get channels (direct from ACS) (un-used)
  ==================================================================================================================================== */

  /* ====================================================================================================================================
   Unsubscribe (remove all events) (example: when logged out)
  ==================================================================================================================================== */
  const unsubscribe = async () => {
    try {
      const ASCChatClient = await getASCChatClient()
      await ASCChatClient?.stopRealtimeNotifications()

      if (chatMessageReceivedUnsubscribe.current) {
        chatMessageReceivedUnsubscribe.current()
      }

      if (participantsRemovedUnsubscribe.current) {
        participantsRemovedUnsubscribe.current()
      }

      if (typingIndicatorReceivedUnsubscribe.current) {
        typingIndicatorReceivedUnsubscribe.current()
      }

      setCurrentUserId('')
      setASCChatClient(null)

      chatMessageReceivedUnsubscribe.current = null
      participantsRemovedUnsubscribe.current = null
      typingIndicatorReceivedUnsubscribe.current = null
    } catch (error) {
      console.log(error)
    }
  }

  return <ACSContext.Provider value={{ getChatClient, typingIndicators }}>{children}</ACSContext.Provider>
}

function useACS(): ACSContextData {
  const context = useContext(ACSContext)
  if (!context) {
    throw new Error('useACS must be used within an ACSProvider')
  }
  return context
}

export { ACSContext, ACSProvider, useACS }
