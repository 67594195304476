import { StyleSheet, Text, View } from 'react-native'

// Store
import { useSelector } from 'react-redux'
import { RootState } from '@/store/rootReducer'

// Themes
import theme from '@/config/themes'

// Types
import type { IMessage } from '../../types'
import type { SystemMessageProps } from 'react-native-gifted-chat'
type ChatSystemMessageContactCardProps = SystemMessageProps<IMessage> & {
  isAnonymous: boolean
}

function ChatSystemMessageContactCard(props: ChatSystemMessageContactCardProps) {
  const { currentMessage, isAnonymous } = props
  const { user } = useSelector((state: RootState) => state.user)

  if (!currentMessage || !currentMessage.text?.includes('||')) {
    return null
  }
  const data = currentMessage.text.split('||') // [0] = senderUserId, [1] = senderDisplayName, [2] = Reference message
  if (data[0] !== user?.userId) {
    // Show only target user
    return null
  }
  return (
    <View style={styles.mainContainer}>
      <View>
        <Text style={[styles.text, { color: !isAnonymous ? theme.colors.black : theme.colors.white }]}>
          <Text style={{ fontWeight: 'bold' }}>{data[1]}</Text>
          <Text> har delt deg.</Text>
        </Text>
        {data[2] && (
          <>
            <View style={styles.referenceBox}>
              <Text style={styles.text}>{data[2]}</Text>
            </View>
          </>
        )}
      </View>
    </View>
  )
}

export default ChatSystemMessageContactCard

const styles = StyleSheet.create({
  mainContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    marginTop: 5,
    marginBottom: 10,
  },
  text: {
    color: theme.colors.black,
    fontSize: 12,
    fontWeight: '300',
    textAlign: 'center',
  },
  referenceBox: {
    paddingHorizontal: 15,
    paddingVertical: 5,
    marginVertical: 10,
    borderRadius: 20,
    backgroundColor: theme.colors.light,
  },
})
